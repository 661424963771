<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv kv-top-contain">
        <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
        <div class="uk-container">
          <div class="kv_copy_jp_margin">
            <h1 class="kv_copy_jp">3DアバターGLB</h1>
          </div>
            <div id="pane-container"></div>
            <div class="uk-grid-small uk-flex uk-flex-middle uk-flex-center" uk-grid>
              <div id="canvas-parent">
                <div id="renderCanvas"></div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module'

export default {
  name: 'Glb',
  props: {
    host: String,
  },
  data () {
    return {
      height: 0,
      image_ext: '',
      export_format: '',
      uuid: '7c4a4ef245c44424abdb1a9f364920c7',
      s3_image_url: '',
      s3_avatar_url: '',
      s3_body_url: '',
      s3_hair_url: '',
      s3_outfit_url: '',
    }
  },
  created () {
    this.axios.get(this.host + 'api/data/' + this.uuid, {}, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      console.log(response.data);
      // レスポンスが200の時の処理
      this.height = parseInt(response.data.height);
      this.export_format = response.data.export_format;
      this.image_ext = response.data.image_ext;
      this.s3_image_url = response.data.image_url;
      this.s3_avatar_url = response.data.avatar_url;
      this.s3_body_url = response.data.avatar_body_url;
      this.s3_hair_url = response.data.avatar_hair_url;
      this.s3_outfit_url = response.data.avatar_outfit_url;
      this.renderModel();
    })
    .catch((err) => {
      if (typeof err.xhr !== 'undefined' && typeof err.xhr.response !== 'undefined' && err.xhr.response !== '') {
        const errJson = JSON.parse(err.xhr.response);
        console.log(errJson);
      }
      this.$router.push('error');
    });
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onWindowResize() {
    },
    calcCameraAspect(canvasAspect) {
      // console.log(canvasAspect);
      let setAspect = 1.05;
      if (canvasAspect < 0.7) {
        setAspect = 0.6;
      } else if (canvasAspect < 0.9) {
        setAspect = 0.7;
      } else if (canvasAspect < 1.1) {
        setAspect = 0.8;
      } else if (canvasAspect < 1.55) {
        setAspect = 0.9;
      }

      return setAspect;
    },
    calcCanvasSize() {
      let width;
      if (this.isSmartPhone) {
        width = Math.floor( window.innerWidth * 0.85 );
      } else {
        width = Math.floor( window.innerWidth * 0.7 );
      }
      let height = Math.floor( window.innerHeight * 0.7 );

      return [width, height];
    },
    getPresignedMaterialUrl(url) {
      let matUrl = '';
      console.log('prev:', url);
      if (url.match(/generated.png/)) {
        matUrl = this.s3_hair_url;
      } else if (url.match(/model.png/)) {
        matUrl = this.s3_body_url;
      } else {
        console.log('else:', this.s3_outfit_url);
        matUrl = this.s3_outfit_url;
      }
      console.log(matUrl);

      return matUrl;
    },
    renderModel() {
      console.log('start');
      const modelUrl = 'http://localhost:8000/avatars/' + this.uuid + '/avatar/model.glb'
      const hemiLightVal = 1.8;

      // 画面そのままのアスペクト比だと少し細長くなってしまうので調整
      const camera = new THREE.PerspectiveCamera( 55, window.innerWidth / window.innerHeight, 4, 1000 );
      // camera.position.set( 0, this.height + 30, 130 );
      let firstCameraPosY = 140;
      let firstCameraPosZ = 195;
      if (this.height > 160) {
        firstCameraPosY = parseInt(firstCameraPosY + this.height - 160)
        firstCameraPosZ = parseInt(firstCameraPosZ + (this.height - 160) * 1.5)
      }

      camera.position.set( 0, firstCameraPosY, firstCameraPosZ );

      const scene = new THREE.Scene();
      scene.background = new THREE.Color( 0xdcdcdc );
      scene.fog = new THREE.Fog( 0xdcdcdc, 200, 1000 );

      const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, hemiLightVal);
      hemiLight.position.set( 0, 210, 10 );
      scene.add( hemiLight );

      const container = document.getElementById("renderCanvas");
      container.innerHTML = '';
      const renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true, preserveDrawingBuffer: true } );
      let [width, height] = this.calcCanvasSize();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      camera.aspect = this.calcCameraAspect(width / height);
      camera.updateProjectionMatrix();
      renderer.shadowMap.enabled = true;
      container.appendChild( renderer.domElement );

      const controls = new OrbitControls(camera, renderer.domElement)
      // 滑らかにカメラコントローラーを制御する
      controls.enableDamping = true;
      controls.dampingFactor = 0.2;
      let firstTgtY = 100;
      if (this.height > 160) {
        firstTgtY = parseInt(firstTgtY + (this.height - 160) * 0.5);
      }
      controls.target.set(0, firstTgtY, 15)

      const manager = new THREE.LoadingManager();
      manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
        console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
      };
      manager.setURLModifier( ( url ) => {
        if (url.match(/model.glb/) || url.match(/model.fbx/)) {
          return url;
        }
        let modUrl = this.getPresignedMaterialUrl(url);
        console.log('mod:', modUrl);
        return modUrl;
      } );

      // model
      console.log(modelUrl);
      const fbxLoader = new GLTFLoader(manager)
      fbxLoader.load(
        modelUrl,
        (object) => {
          object.scene.traverse( function ( child ) {
            if ( child.isMesh ) {
              if (child.name === 'haircut_generated' || child.material.name === 'generated.png') {
                child.renderOrder = 2;
                child.material.opacity = 5.0;
                child.material.transparent = true;
              } else if (child.name === 'mesh' || child.material.name === 'model.png') {
                child.renderOrder = 1;
                child.material.opacity = 1;
                child.material.transparent = true;
              } else {
                child.material.transparent = false;
              }
              child.material.needsUpdate = true;
              child.needsUpdate = true;
              child.castShadow = true;
              child.receiveShadow = true;
              console.log(child);
            }
          } );
          object.scene.scale.set(100, 100, 100);
          console.log(object);
          scene.add(object.scene);
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
        },
        (error) => {
          console.log(error)
        }
      )

      this.onWindowResize = function() {
          // サイズを取得
          let [width, height] = this.calcCanvasSize();
          // レンダラーのサイズを調整する
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(width, height);
          // カメラのアスペクト比を正す
          camera.aspect = this.calcCameraAspect(width / height);
          // console.log(width, height, camera.aspect)
          camera.updateProjectionMatrix();
          animate()
      }
      window.addEventListener('resize', this.onWindowResize, false)

      function animate() {
          requestAnimationFrame(animate)
          controls.update()
          render()
      }
      function render() {
          renderer.render(scene, camera)
      }

      animate()
    },
    download() {
      this.axios.get(this.host + 'api/download_avatar', {
        params: {
          uniq_id: this.uuid,
        },
        responseType: 'blob'
      }, {
        headers: {
          Accept: 'application/zip'
        }
      })
      .then((response) => {
        // console.log(response);
        const blob = new Blob([response.data], { type: 'application/zip' })
        const fileName = 'avatar.zip'
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE, Edge
          window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else {
          // for Chrome , etc...
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(url)
          link.parentNode.removeChild(link)
        }
      })
      .catch((err) => {
        this.$router.push('error');
        console.log(err);
      });
    },
    routeAnimation() {
      this.$router.push({ name: 'animation', params: { host: this.host } });
    },
    routeView() {
      this.$router.push({
          name: 'view',
          params: {
            uuid: this.uuid,
          }
        });
    },
  },
  // updated() {
  //   this.renderModel();
  // },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize, false);
  }
}
</script>
<style scoped>
  #canvas-parent {
    margin: 0;
    padding: 0;
  }
  #renderCanvas {
    height: 70vh;
    touch-action: none;
  }
  #pane-container {
    position: absolute;
    top: 78px;
    right: 8px;
    width: 256px;
  }
</style>
