import { createStore } from 'vuex'

export default createStore({
  state: {
    cognite: null,
    filenameDownloadCount: {},
  },
  mutations: {
    setUser(state, cognite) {
      state.cognite = cognite;
    },
  },
  actions: {
    getRequest() {
      const payload = this.state.cognite.signInUserSession.getIdToken().payload
      let role = null
      if (payload != null && payload['cognito:groups'] != null) {
        role =  payload['cognito:groups'].sort()[0]
      }
      return {
        user_sub: payload.sub,
        email: payload.email,
        role: role || 'customer',  // customerはCognito上で明示的に付与していない
      }
    },
    isAdmin() {
      const payload = this.state.cognite.signInUserSession.getIdToken().payload
      let isAdmin = false
      if (payload != null && payload['cognito:groups'] != null) {
        isAdmin = payload['cognito:groups'].includes('admin')
      }
      return isAdmin
    }
  },
  modules: {
  }
})
