<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv">
        <div class="processing-wrapper">
          <div class="logo-plane"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
          <div id="loading-wrapper">
            <div>
              <div id="loading-text">{{ processingType }}<br>{{ currentPercent }}%</div>
              <div id="loading-content"></div>
              <p id="processing-caution">最大で{{ processingMinutes }}分程度の時間を要することがあります。<br>完了までしばらくお待ち下さい。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

export default {
  name: 'Processing',
  components:{
  },
  props: {
    processingType: {
      type: String,
      default: 'creating',
    },
  },
  data () {
    return {
      currentPercent: 0,
      processingMinutes: (this.processingType === 'editing') ? 1 : 2,
    }
  },
  mounted () {
    const $vueObj = this
    const delay = (this.processingType === 'editing') ? 2000 : 7000
    UIkit.util.ready(function() {
      const animate = setInterval(function() {
        let random = Math.floor(Math.random() * 5)
        if ($vueObj.currentPercent < 45) {
          $vueObj.currentPercent += (20 + random)
        } else if ($vueObj.currentPercent < 75) {
          $vueObj.currentPercent += (10 + random)
        } else {
          $vueObj.currentPercent += (2 + random)
        }
        if ($vueObj.currentPercent >= 99) {
          $vueObj.currentPercent = 99
          clearInterval(animate)
        }
      }, delay)
    })
  }
}
</script>

<style>
#loading-wrapper {
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
#loading-text {
  position: relative;
  display: block;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}
#loading-content {
  top: -125px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 170px;
  height: 170px;
  border: 3px solid #F00;
}
#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}
#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}
#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}
#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}
#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}
@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#processing-caution {
  top: -90px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}
@media only screen and (max-width: 770px) {
  #processing-caution {
    font-size: 14px;
  }
}
@media only screen and (max-width: 350px) {
  #processing-caution {
    font-size: 12px;
  }
}
.processing-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 75px;
}
</style>
