import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import 'uikit/dist/css/uikit.min.css'
import './assets/css/common.css'

import App from './App.vue'
import { router } from './routing.js'
import store from "@/store/index.js";

UIkit.use(Icons)
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || '';

axios.interceptors.request.use(function (config) {
    if(!store.state.cognite){
        return config
    }
    const info = store.state.cognite.signInUserSession.getIdToken()
    const token = info.jwtToken
    config.headers['X-Session-ID'] = token;
    config.headers['X-Auth-Time'] = info.payload.auth_time
    config.headers['X-Cognito-Client-ID'] = info.payload.aud
    const sp = info.payload.iss.split("/")
    config.headers['X-User-Pool-ID'] = sp[sp.length-1]
    return config;
});

const app = createApp(App)
app.use(VueAxios, axios)
app.use(store)
app.use(router)
app.mount('#app')
