<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv">
        <div class="uk-container">
          <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
          <div class="uk-grid-small uk-flex uk-flex-center" style="margin-bottom:30px;" uk-grid>
            <div class="uk-margin-top uk-width-1-4@m uk-width-1-2" style="margin-top:40px!important;">
              <p class="process-title" style="margin-top:30px;">ユーザー新規作成</p>
            </div>
          </div>
          <div class="error-wrapper" v-if="errMessage.length > 0">
            <div class="error" v-for="err in errMessage" :key="err">{{err}}</div>
          </div>
          <div class="input-wrapper">
            <span class="input-title">メールアドレス</span>
            <input type="text" class="form-control" placeholder="メールアドレス" v-model="email"/>
          </div>
          <div class="input-wrapper" style="position:relative;">
            <span class="input-title">パスワード</span>
            <input v-if="!isShowPassword" type="password" class="form-control" placeholder="パスワード" v-model="password"/>
            <input v-else-if="isShowPassword" type="text" class="form-control" placeholder="パスワード" v-model="password"/>
            <img v-if="isShowPassword" src="../assets/img/eye.png" class="eye" @click="isShowPassword = !isShowPassword">
            <img v-else src="../assets/img/eye_ban.png" class="eye" @click="isShowPassword = !isShowPassword">
          </div>
          <div class="input-wrapper">
            <span class="input-title">プロジェクト</span>
            <select class="form-control" v-model="projectName">
              <option value="" disabled>プロジェクトを選択する</option>
              <template v-for="project in sortedProjects">
                <option v-if="!project.disabled_at" :value="project.name">{{project.name}}</option>
              </template>
            </select>
          </div>
          <div :class="['project', projectName === 'admin' ? 'hide' : 'visible']">
            <div class="input-wrapper">
              <span class="input-title">契約形態</span>
              <select class="form-control" v-model="kind">
                <option value="all_span">個別契約（ダウンロード数・期間を任意指定できる）</option>
                <option value="subscription">サブスクリプション契約</option>
              </select>
            </div>
            <div class="input-wrapper" v-if="kind=='subscription'">
              <span class="input-title">プラン</span>
              <select class="form-control" v-model="plan">
                <option value="" disabled>プランを選択してください</option>
                <option value="plan_a">プランA（10体までアバターの作成が可能）</option>
                <option value="plan_b">プランB（30体までアバターの作成が可能）</option>
                <option value="plan_c">プランC（50体までアバターの作成が可能）</option>
              </select>
            </div>
            <div class="input-wrapper" v-if="kind=='all_span'">
              <span class="input-title">制作上限数</span>
              <input type="number" class="form-control" placeholder="制作上限数" max="100000" min="1" v-model="limit_num"/>
            </div>
            <div class="input-wrapper">
              <span class="input-title">利用開始日</span>
              <input type="date" class="form-control" placeholder="利用開始日" v-model="plan_start"/>
            </div>
            <div class="input-wrapper" v-if="kind=='subscription'">
              <span class="input-title">利用期間</span>
              <select class="form-control" v-model="period">
                <option value="" disabled>利用期間を選択してください</option>
                <option value="1">1ヶ月</option>
                <option value="3">3ヶ月</option>
                <option value="6">6ヶ月</option>
                <option value="12">12ヶ月</option>
              </select>
            </div>
            <div class="input-wrapper" v-if="kind=='all_span'">
              <span class="input-title">利用終了日</span>
              <input type="date" class="form-control" placeholder="利用終了日" v-model="plan_end"/>
            </div>
          </div>
          <div class="btn-wrapper mt-3" style="margin-bottom:30px;">
            <router-link to="/" class="btn no" style="width:215px;text-align:center;">トップへ戻る</router-link>
            <button class="btn yes" style="margin-left:15px;width:215px;text-align:center;" @click="createUser"><img src="@/assets/img/icon_create.svg">ユーザーを作成</button>
          </div>
        </div>  
      </div>
    </div>
    <modal v-show="isShowAlert" :isAlert="true" :modalColor="'rgb(204,204,204)'" :color="'black'" :header="'avatar.metaclone.jpの内容'" :canceltext="'OK'" :propWidth="'650px !important'" @modal-close="isShowAlert=false">
      <template v-slot:body>
        <div v-html="message" class="message-wrapper"></div>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/parts/Modal'

function dateFormat(date, after){
  date.setMonth(date.getMonth() + after)
  var yyyy = date.getFullYear()
  var mm = ('0' + (date.getMonth()+1)).slice(-2)
  var dd = ('0' + date.getDate()).slice(-2)
  return `${yyyy}-${mm}-${dd}`
}
export default {
  name: 'Create User',
  components: {
    Modal,
  },
  data () {
    return {
      email: '',
      password: '',
      plan: '',
      limit_num: 10,
      plan_start: dateFormat(new Date(), 0),
      plan_end: dateFormat(new Date(), 1),
      period: '',
      kind: 'all_span',  // subscription: サブスクリプション, all_span: 制作上限数設定
      projects: [],
      projectName: '',
      isShowAlert: false,
      isShowPassword: false,
      message: '',
      errMessage: [],
    }
  },
  computed: {
    sortedProjects() {
      return this.projects.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at)
      })
    },
  },
  methods:{
    generatePassword(){
      function random(c, num){
        let result = ''
        const cLength = c.length
        const unique = {}
        for (let i = 0; i < num; i++) {
            const char = c.charAt(Math.floor(Math.random() * cLength))
            if (unique[char]) {
              i--
              continue
            }
            result += char
            unique[char] = true
        }
        return result
      }
      const lower = random('abcdefghijklmnopqrstuvwxyz', 4)
      const upper = random('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 3)
      const digits = random('0123456789', 3)
      const kigou = random('!%&$#()', 2)
      return random(lower + upper + digits + kigou, 12)
    },
    async createUser(){
      if (!this.validate()) return
      let plan_end = new Date(this.plan_start)
      if (this.kind == 'subscription') {
        plan_end = new Date(plan_end.setMonth(plan_end.getMonth() + Number(this.period))).getTime()
      } else if (this.kind == 'all_span') {
        plan_end = new Date(this.plan_end).getTime() + 24*60*60*1000
      } else {
        this.message = '入力が不正です'
        this.isShowAlert = true
        return
      }

      const params = {
        email: this.email,
        password: this.password,
        plan: this.plan,
        limit_num: this.limit_num,
        plan_start: new Date(this.plan_start).getTime(),
        plan_end: plan_end,
        kind: this.kind,
        role: 'customer',
        project_name: this.projectName,
      }
      try {
        const res = await this.axios.post('/api/create_customer', params)
        console.log(res)
        this.message = `<p>ご登録いただいたメールアドレスに招待を送りました。</p>
            <p>メールアドレスの受領者に連絡をして、招待を送ったことをご連絡ください。</p>
            <p>メール文章内のログインに必要なメールアドレスとパスワードが記載されています。</p>`
        this.isShowAlert = true
      } catch(e) {
        console.error(e)
        this.errMessage = ['予期せぬエラーが発生した、あるいはこのメールアドレスは既に登録されています']
      }
    },
    validate(){
      this.errMessage = []
      if (!this.email) { this.errMessage.push('メールアドレスを入力してください') }
      if (!this.password) { this.errMessage.push('パスワードを入力してください') }
      if (!this.password.match(/^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[a-z])[!-~]{8,}$/g)) {
        this.errMessage.push('パスワードは少なくとも1つの数字と、1つの特殊記号を含む8文字以上の文字列としてください')
      }
      if (!this.password.match(/[a-z]/g)) { this.errMessage.push('パスワードには少なくとも一つ小文字を含めてください') }
      if (!this.password.match(/[A-Z]/g)) { this.errMessage.push('パスワードには少なくとも一つ大文字を含めてください') }
      if (this.password.match(/https?:\/\/[\w.-]+|[\w.-]+\.[a-z]{2,}/g)) {
        this.errMessage.push('無効なパスワードです')
      }
      if (!this.projectName) { this.errMessage.push('プロジェクト名を選択してください') }
      if (this.projectName !== 'admin') {
        if (!this.plan && this.kind == 'subscription') { this.errMessage.push('プランを選択してください') }
        if (!this.plan_start) { this.errMessage.push('利用開始日を入力してください') }
        if (!this.period && this.kind == 'subscription') { this.errMessage.push('利用期間を選択してください') }
        if (new Date(this.plan_start).getTime() < new Date().getTime()-24*60*60*1000) { 
          this.errMessage.push('利用開始日が過去の日付になっています')
        }
        if (new Date(this.plan_start).getTime() > new Date(this.plan_end).getTime()) { 
          this.errMessage.push('利用終了日は利用開始日より後になるように設定してください')
        }
        if (this.kind == 'all_span' && (this.limit_num < 1 || this.limit_num > 100000)) {
          this.errMessage.push('制作上限数が不正です')
        }
      }
      return this.errMessage.length == 0
    }
  },
  async mounted () {
    this.password = this.generatePassword()
    const resPjt = await this.axios.get('/api/get_project_info')
    this.projects = resPjt.data.item
  }
}

</script>
<style scoped>
.message-wrapper p {
  margin-bottom: 12px;
}
.input-wrapper {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-title {
  font-size: 1.3em;
  font-weight: bold;
}
.form-control {
  background-color: #333333;
  border-radius: 15px;
  min-height: 65px;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 0px 40px 0px 65px;
  width: 80%;
  font-size: 22px;
  box-sizing: border-box;
}
.error-wrapper {
  border: 3px solid white;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: rgba(0,0,0,0.6);
}
.error {
  color: white;
}
.create_btn button::before {
  content: "";
  background: none;
  width: 0 !important;
}
.btn-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.project.hide {
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.5s;
}
.project.visible {
  opacity: 1;
  transition: all ease 0.5s;
}
@media (max-width: 767px){
  .input-wrapper {
    display: block;
    width: 100%;
  }
  .form-control {
    display: block;
    width: 100%;
  }
  .uk-container {
    padding-bottom: 30px;
    overflow: hidden;
  }
  .btn.yes {
    font-size: 0.95em;
  }
}
</style>
