<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv kv-top-contain">
        <div class="uk-container">
          <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
          <div class="uk-grid-small uk-flex uk-flex-center" style="margin-bottom:30px;" uk-grid>
            <div class="uk-margin-top uk-width-1-4@m uk-width-1-2" style="margin-top: 40px !important;">
              <p class="process-title" style="margin-top: 30px;">プロジェクト作成</p>
            </div>
          </div>
          <div class="error-wrapper" v-if="errMessage.length > 0">
            <div class="error" v-for="err in errMessage" :key="err">{{ err }}</div>
          </div>
          <project @create-project="createProject"></project>
        </div>
      </div>
    </div>
    <modal v-show="isShowAlert" :isAlert="true" :modalColor="'rgb(204,204,204)'" :color="'black'" :header="'avatar.metaclone.jpの内容'" :canceltext="'OK'" :propWidth="'650px !important'" @modal-close="isShowAlert=false">
      <template v-slot:body>
        <div v-html="message" class="message-wrapper"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/parts/Modal.vue'
import Project from '@/components/parts/Project.vue'

export default {
  name: 'ProjectCreate',
  components: {
    Modal,
    Project,
  },
  data () {
    return {
      isShowAlert: false,
      message: '',
      errMessage: [],
    }
  },
  methods:{
    async createProject(params){
      if (!this.validate(params)) return
      const cognitoInfo = await this.getRequest()
      params.user_sub = cognitoInfo.user_sub
      try {
        const res = await this.axios.post('/api/create_project', params)
        console.log(res)
        this.message = `<p>プロジェクトを作成しました。</p>`
        this.isShowAlert = true
      } catch(e) {
        this.errMessage = ['予期せぬエラーが発生しました']
        if (e.response && e.response.data.message === 'Cognito Group Exists')  this.errMessage = ['このプロジェクト名は既に使用されているか、過去に使用されたことがあります']
      }
    },
    validate(p){
      this.errMessage = []
      if (!p.project_name) { this.errMessage.push('プロジェクト名を入力してください') }
      if (!p.project_name.match(/^[a-z0-9-]+$/)) { this.errMessage.push('プロジェクト名は小文字のアルファベット、数字、ハイフンのみで入力してください') }
      return this.errMessage.length == 0
    },
    ...mapActions(['getRequest']),
  },
  watch: {
    errMessage(errMessage) {
      if (errMessage.length > 0) window.scroll({ top: 0, behavior: 'instant' })
    },
  },
}
</script>

<style scoped>
.message-wrapper p {
  margin-bottom: 12px;
}
.error-wrapper {
  border: 3px solid white;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: rgba(0,0,0,0.6);
}
.error {
  color: white;
}
@media (max-width: 767px){
  .uk-container {
    padding-bottom: 30px;
    overflow: hidden;
  }
}
</style>
