// gender
const genderOptions = {
  meta: [
    { text: 'スタイル', value: 'disabled', disabled: true },
    { text: '男性（中肉中背）', value: 'toppan_male', src: '/img/thumbnails/toppan_male/casual01/toppan_male_casual01_005.webp', srcGrayout: '/img/thumbnails/toppan_male/casual01/grayout_toppan_male_casual01_005.webp' },
    { text: '男性（筋肉質）', value: 'male', src: '/img/thumbnails/male/casual01/male_casual01_005.webp', srcGrayout: '/img/thumbnails/male/casual01/grayout_male_casual01_005.webp' },
    { text: '女性（標準）', value: 'toppan_female', src: '/img/thumbnails/toppan_female/casual01/toppan_female_casual01_001.webp', srcGrayout: '/img/thumbnails/toppan_female/casual01/grayout_toppan_female_casual01_001.webp' },
    { text: '女性（メリハリ）', value: 'female', src: '/img/thumbnails/female/casual01/female_casual01_001.webp', srcGrayout: '/img/thumbnails/female/casual01/grayout_female_casual01_001.webp' },
    { text: 'こども', value: 'toppan_child', src: '/img/thumbnails/toppan_child/general/toppan_child_general_001.webp', srcGrayout: '/img/thumbnails/toppan_child/general/grayout_toppan_child_general_001.webp' },
    { text: '男女共用', value: 'unisex', src: '/img/thumbnails/unisex/armor/unisex_armor_001.webp', srcGrayout: '/img/thumbnails/unisex/armor/grayout_unisex_armor_001.webp' },
  ],
  fit: [
    { text: '男性', value: 'male' },
    { text: '女性', value: 'female' },
    { text: 'ノンバイナリー', value: 'non_binary' },
  ],
}
const genderOptionsMap = {
  meta: genderOptions.meta.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  fit: genderOptions.fit.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
}

// outfit
const outfitOptions = {
  male: [
    { text: '服装', value: 'disabled', disabled: true },
    // 並び替え（これらを先頭にする）
    { text: '紺色スーツ', value: 'male_formal_005', src: '/img/thumbnails/male/formal/c_male_formal_005.webp' },
    { text: '紺色ジャケット 1', value: 'male_formal_006', src: '/img/thumbnails/male/formal/c_male_formal_006.webp' },
    { text: '紺色ジャケット 2', value: 'male_formal_007', src: '/img/thumbnails/male/formal/c_male_formal_007.webp' },
    { text: 'グレースーツ 1', value: 'male_formal_001', src: '/img/thumbnails/male/formal/c_male_formal_001.webp' },
    { text: 'グレースーツ 2', value: 'male_formal_002', src: '/img/thumbnails/male/formal/c_male_formal_002.webp' },
    { text: 'ガンクラブチェックスーツ', value: 'male_formal_003', src: '/img/thumbnails/male/formal/c_male_formal_003.webp' },
    { text: 'タータンチェックスーツ', value: 'male_formal_004', src: '/img/thumbnails/male/formal/c_male_formal_004.webp' },
    { text: '赤ジャケット', value: 'male_formal_008', src: '/img/thumbnails/male/formal/c_male_formal_008.webp' },
    // フォーマル
    { text: '黒スーツ（ネクタイ：赤）', value: '000_male_formal_001', src: '/img/thumbnails/male/formal/c_000_male_formal_001.webp' },
    { text: '黒スーツ（ネクタイ：青）', value: '000_male_formal_002', src: '/img/thumbnails/male/formal/c_000_male_formal_002.webp' },
    { text: '黒スーツ（ネクタイ：緑）', value: '000_male_formal_003', src: '/img/thumbnails/male/formal/c_000_male_formal_003.webp' },
    { text: '黒スーツ（ネクタイ：白）', value: '000_male_formal_004', src: '/img/thumbnails/male/formal/c_000_male_formal_004.webp' },
    { text: '黒スーツ（ネクタイ：灰）', value: '000_male_formal_005', src: '/img/thumbnails/male/formal/c_000_male_formal_005.webp' },
    { text: '黒スーツ（ネクタイ：茶）', value: '000_male_formal_006', src: '/img/thumbnails/male/formal/c_000_male_formal_006.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 1）', value: '000_male_formal_007', src: '/img/thumbnails/male/formal/c_000_male_formal_007.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 2）', value: '000_male_formal_008', src: '/img/thumbnails/male/formal/c_000_male_formal_008.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 3）', value: '000_male_formal_009', src: '/img/thumbnails/male/formal/c_000_male_formal_009.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 4）', value: '000_male_formal_010', src: '/img/thumbnails/male/formal/c_000_male_formal_010.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 5）', value: '000_male_formal_011', src: '/img/thumbnails/male/formal/c_000_male_formal_011.webp' },
    { text: '黒スーツ（ネクタイ：スリートーン 1）', value: '000_male_formal_012', src: '/img/thumbnails/male/formal/c_000_male_formal_012.webp' },
    { text: '黒スーツ（ネクタイ：スリートーン 2）', value: '000_male_formal_013', src: '/img/thumbnails/male/formal/c_000_male_formal_013.webp' },
    { text: '黒スーツ（ネクタイ：ドット 1）', value: '000_male_formal_014', src: '/img/thumbnails/male/formal/c_000_male_formal_014.webp' },
    { text: '黒スーツ（ネクタイ：ドット 2）', value: '000_male_formal_015', src: '/img/thumbnails/male/formal/c_000_male_formal_015.webp' },
    { text: '黒スーツ（ネクタイ：ドット 3）', value: '000_male_formal_016', src: '/img/thumbnails/male/formal/c_000_male_formal_016.webp' },
    { text: '黒スーツ（ネクタイ：チェック）', value: '000_male_formal_017', src: '/img/thumbnails/male/formal/c_000_male_formal_017.webp' },
    { text: '黒スーツ（ネクタイ：ペイズリー）', value: '000_male_formal_018', src: '/img/thumbnails/male/formal/c_000_male_formal_018.webp' },
    { text: '黒スーツ（ネクタイ：格子）', value: '000_male_formal_019', src: '/img/thumbnails/male/formal/c_000_male_formal_019.webp' },
    // カジュアル01
    { text: '白 T デニム 1', value: 'male_casual01_001', src: '/img/thumbnails/male/casual01/c_male_casual01_001.webp' },
    { text: '白 T デニム 2', value: 'male_casual01_002', src: '/img/thumbnails/male/casual01/c_male_casual01_002.webp' },
    { text: '白 T デニム 3', value: 'male_casual01_003', src: '/img/thumbnails/male/casual01/c_male_casual01_003.webp' },
    { text: '白 T デニム 4', value: 'male_casual01_004', src: '/img/thumbnails/male/casual01/c_male_casual01_004.webp' },
    { text: '白 T デニム 5', value: 'male_casual01_005', src: '/img/thumbnails/male/casual01/c_male_casual01_005.webp' },
    { text: 'グレー T デニム', value: 'male_casual01_006', src: '/img/thumbnails/male/casual01/c_male_casual01_006.webp' },
    { text: 'グレー T 白チノ', value: 'male_casual01_007', src: '/img/thumbnails/male/casual01/c_male_casual01_007.webp' },
    { text: '黒 T デニム', value: 'male_casual01_008', src: '/img/thumbnails/male/casual01/c_male_casual01_008.webp' },
    { text: '柄 T デニム', value: 'male_casual01_009', src: '/img/thumbnails/male/casual01/c_male_casual01_009.webp' },
    { text: 'TOKYO', value: 'male_casual01_010', src: '/img/thumbnails/male/casual01/c_male_casual01_010.webp' },
    { text: 'TOPPA! TOPPAN 1（後ろ）', value: 'male_casual01_011', src: '/img/thumbnails/male/casual01/c_male_casual01_011.webp' },
    { text: 'TOPPAN 1（後ろ）', value: 'male_casual01_011_1', src: '/img/thumbnails/male/casual01/c_male_casual01_011_1.webp' },
    { text: 'TOPPA! TOPPAN 2（前）', value: 'male_casual01_012', src: '/img/thumbnails/male/casual01/c_male_casual01_012.webp' },
    { text: 'TOPPAN 2（前）', value: 'male_casual01_012_1', src: '/img/thumbnails/male/casual01/c_male_casual01_012_1.webp' },
    { text: '浦和レッズ', value: 'male_casual01_013', src: '/img/thumbnails/male/casual01/c_male_casual01_013.webp' },
    { text: 'セブンイレブン', value: 'male_casual01_014', src: '/img/thumbnails/male/casual01/c_male_casual01_014.webp' },
    { text: 'TOPPAN 黒 T（前のみ）', value: 'male_casual01_015', src: '/img/thumbnails/male/casual01/c_male_casual01_015.webp' },
    { text: 'TOPPAN 黒 T（前後）', value: 'male_casual01_016', src: '/img/thumbnails/male/casual01/c_male_casual01_016.webp' },
    // カジュアル02
    { text: '白シャツ', value: 'male_casual02_001', src: '/img/thumbnails/male/casual02/c_male_casual02_001.webp' },
    { text: 'グリーンシャツ', value: 'male_casual02_002', src: '/img/thumbnails/male/casual02/c_male_casual02_002.webp' },
    { text: 'グレーシャツ', value: 'male_casual02_003', src: '/img/thumbnails/male/casual02/c_male_casual02_003.webp' },
    { text: 'ギンガムチェックシャツ 1', value: 'male_casual02_004', src: '/img/thumbnails/male/casual02/c_male_casual02_004.webp' },
    { text: 'ギンガムチェックシャツ 2', value: 'male_casual02_005', src: '/img/thumbnails/male/casual02/c_male_casual02_005.webp' },
    { text: 'フランネルシャツ', value: 'male_casual02_006', src: '/img/thumbnails/male/casual02/c_male_casual02_006.webp' },
  ],
  female: [
    { text: '服装', value: 'disabled', disabled: true },
    // フォーマル
    { text: '黒スーツ', value: 'female_formal_001', src: '/img/thumbnails/female/formal/c_female_formal_001.webp' },
    { text: 'グレースーツ', value: 'female_formal_002', src: '/img/thumbnails/female/formal/c_female_formal_002.webp' },
    { text: 'デニムスーツ', value: 'female_formal_003', src: '/img/thumbnails/female/formal/c_female_formal_003.webp' },
    { text: 'フランネルスーツ', value: 'female_formal_004', src: '/img/thumbnails/female/formal/c_female_formal_004.webp' },
    { text: 'グレージャケット 1', value: 'female_formal_005', src: '/img/thumbnails/female/formal/c_female_formal_005.webp' },
    { text: 'グレージャケット 2', value: 'female_formal_006', src: '/img/thumbnails/female/formal/c_female_formal_006.webp' },
    { text: 'ベージュジャケット', value: 'female_formal_007', src: '/img/thumbnails/female/formal/c_female_formal_007.webp' },
    { text: 'ネイビージャケット', value: 'female_formal_008', src: '/img/thumbnails/female/formal/c_female_formal_008.webp' },
    { text: 'タータンチェックジャケット 1', value: 'female_formal_009', src: '/img/thumbnails/female/formal/c_female_formal_009.webp' },
    { text: 'タータンチェックジャケット 2', value: 'female_formal_010', src: '/img/thumbnails/female/formal/c_female_formal_010.webp' },
    { text: '赤ジャケット', value: 'female_formal_011', src: '/img/thumbnails/female/formal/c_female_formal_011.webp' },
    // カジュアル01
    { text: '白 T デニム 1', value: 'female_casual01_001', src: '/img/thumbnails/female/casual01/c_female_casual01_001.webp' },
    { text: '白 T デニム 2', value: 'female_casual01_002', src: '/img/thumbnails/female/casual01/c_female_casual01_002.webp' },
    { text: 'ストライプ T デニム 1', value: 'female_casual01_003', src: '/img/thumbnails/female/casual01/c_female_casual01_003.webp' },
    { text: 'ストライプ T デニム 2', value: 'female_casual01_004', src: '/img/thumbnails/female/casual01/c_female_casual01_004.webp' },
    { text: '柄 T デニム 1', value: 'female_casual01_005', src: '/img/thumbnails/female/casual01/c_female_casual01_005.webp' },
    { text: '柄 T デニム 2', value: 'female_casual01_006', src: '/img/thumbnails/female/casual01/c_female_casual01_006.webp' },
    { text: '柄 T デニム 3', value: 'female_casual01_007', src: '/img/thumbnails/female/casual01/c_female_casual01_007.webp' },
    { text: 'TOKYO', value: 'female_casual01_008', src: '/img/thumbnails/female/casual01/c_female_casual01_008.webp' },
    { text: 'TOPPAN', value: 'female_casual01_009', src: '/img/thumbnails/female/casual01/c_female_casual01_009.webp' },
    { text: '浦和レッズ', value: 'female_casual01_010', src: '/img/thumbnails/female/casual01/c_female_casual01_010.webp' },
    { text: 'セブンイレブン', value: 'female_casual01_011', src: '/img/thumbnails/female/casual01/c_female_casual01_011.webp' },
    // カジュアル02
    { text: '白シャツ', value: 'female_casual02_001', src: '/img/thumbnails/female/casual02/c_female_casual02_001.webp' },
    { text: 'ピンクシャツ', value: 'female_casual02_002', src: '/img/thumbnails/female/casual02/c_female_casual02_002.webp' },
    { text: 'ストライプシャツ 1', value: 'female_casual02_003', src: '/img/thumbnails/female/casual02/c_female_casual02_003.webp' },
    { text: 'ストライプシャツ 2', value: 'female_casual02_004', src: '/img/thumbnails/female/casual02/c_female_casual02_004.webp' },
    { text: 'ストライプシャツ 3', value: 'female_casual02_005', src: '/img/thumbnails/female/casual02/c_female_casual02_005.webp' },
    { text: 'グレーシャツ 1', value: 'female_casual02_006', src: '/img/thumbnails/female/casual02/c_female_casual02_006.webp' },
    { text: 'グレーシャツ 2', value: 'female_casual02_007', src: '/img/thumbnails/female/casual02/c_female_casual02_007.webp' },
    { text: '赤シャツ', value: 'female_casual02_008', src: '/img/thumbnails/female/casual02/c_female_casual02_008.webp' },
    { text: '黒シャツ 1', value: 'female_casual02_009', src: '/img/thumbnails/female/casual02/c_female_casual02_009.webp' },
    { text: '黒シャツ 2', value: 'female_casual02_010', src: '/img/thumbnails/female/casual02/c_female_casual02_010.webp' },
    { text: '黒シャツ 3', value: 'female_casual02_011', src: '/img/thumbnails/female/casual02/c_female_casual02_011.webp' },
    { text: 'ラグランスリーブ', value: 'female_casual02_012', src: '/img/thumbnails/female/casual02/c_female_casual02_012.webp' },
  ],
  toppan_male: [
    { text: '服装', value: 'disabled', disabled: true },
    // 並び替え（これらを先頭にする）
    { text: '紺色スーツ', value: 'toppan_male_formal_005', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_005.webp' },
    { text: '紺色ジャケット 1', value: 'toppan_male_formal_006', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_006.webp' },
    { text: '紺色ジャケット 2', value: 'toppan_male_formal_007', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_007.webp' },
    { text: 'グレースーツ 1', value: 'toppan_male_formal_001', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_001.webp' },
    { text: 'グレースーツ 2', value: 'toppan_male_formal_002', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_002.webp' },
    { text: 'ガンクラブチェックスーツ', value: 'toppan_male_formal_003', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_003.webp' },
    { text: 'タータンチェックスーツ', value: 'toppan_male_formal_004', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_004.webp' },
    { text: '赤ジャケット', value: 'toppan_male_formal_008', src: '/img/thumbnails/toppan_male/formal/c_toppan_male_formal_008.webp' },
    // フォーマル
    { text: '黒スーツ（ネクタイ：赤）', value: '000_toppan_male_formal_001', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_001.webp' },
    { text: '黒スーツ（ネクタイ：青）', value: '000_toppan_male_formal_002', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_002.webp' },
    { text: '黒スーツ（ネクタイ：緑）', value: '000_toppan_male_formal_003', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_003.webp' },
    { text: '黒スーツ（ネクタイ：白）', value: '000_toppan_male_formal_004', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_004.webp' },
    { text: '黒スーツ（ネクタイ：灰）', value: '000_toppan_male_formal_005', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_005.webp' },
    { text: '黒スーツ（ネクタイ：茶）', value: '000_toppan_male_formal_006', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_006.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 1）', value: '000_toppan_male_formal_007', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_007.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 2）', value: '000_toppan_male_formal_008', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_008.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 3）', value: '000_toppan_male_formal_009', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_009.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 4）', value: '000_toppan_male_formal_010', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_010.webp' },
    { text: '黒スーツ（ネクタイ：ストライプ 5）', value: '000_toppan_male_formal_011', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_011.webp' },
    { text: '黒スーツ（ネクタイ：スリートーン 1）', value: '000_toppan_male_formal_012', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_012.webp' },
    { text: '黒スーツ（ネクタイ：スリートーン 2）', value: '000_toppan_male_formal_013', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_013.webp' },
    { text: '黒スーツ（ネクタイ：ドット 1）', value: '000_toppan_male_formal_014', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_014.webp' },
    { text: '黒スーツ（ネクタイ：ドット 2）', value: '000_toppan_male_formal_015', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_015.webp' },
    { text: '黒スーツ（ネクタイ：ドット 3）', value: '000_toppan_male_formal_016', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_016.webp' },
    { text: '黒スーツ（ネクタイ：チェック）', value: '000_toppan_male_formal_017', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_017.webp' },
    { text: '黒スーツ（ネクタイ：ペイズリー）', value: '000_toppan_male_formal_018', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_018.webp' },
    { text: '黒スーツ（ネクタイ：格子）', value: '000_toppan_male_formal_019', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_019.webp' },
    // カジュアル01
    { text: '白 T デニム 1', value: 'toppan_male_casual01_001', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_001.webp' },
    { text: '白 T デニム 2', value: 'toppan_male_casual01_002', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_002.webp' },
    { text: '白 T デニム 3', value: 'toppan_male_casual01_003', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_003.webp' },
    { text: '白 T デニム 4', value: 'toppan_male_casual01_004', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_004.webp' },
    { text: '白 T デニム 5', value: 'toppan_male_casual01_005', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_005.webp' },
    { text: 'グレー T デニム', value: 'toppan_male_casual01_006', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_006.webp' },
    { text: 'グレー T 白チノ', value: 'toppan_male_casual01_007', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_007.webp' },
    { text: '黒 T デニム', value: 'toppan_male_casual01_008', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_008.webp' },
    { text: '柄 T デニム', value: 'toppan_male_casual01_009', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_009.webp' },
    { text: 'TOKYO', value: 'toppan_male_casual01_010', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_010.webp' },
    { text: 'TOPPA! TOPPAN 1（後ろ）', value: 'toppan_male_casual01_011', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_011.webp' },
    { text: 'TOPPAN 1（後ろ）', value: 'toppan_male_casual01_011_1', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_011_1.webp' },
    { text: 'TOPPA! TOPPAN 2（前）', value: 'toppan_male_casual01_012', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_012.webp' },
    { text: 'TOPPAN 2（前）', value: 'toppan_male_casual01_012_1', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_012_1.webp' },
    { text: '浦和レッズ', value: 'toppan_male_casual01_013', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_013.webp' },
    { text: 'セブンイレブン', value: 'toppan_male_casual01_014', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_014.webp' },
    { text: 'TOPPAN 黒 T（前のみ）', value: 'toppan_male_casual01_015', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_015.webp' },
    { text: 'TOPPAN 黒 T（前後）', value: 'toppan_male_casual01_016', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_016.webp' },
    // カジュアル02
    { text: '白シャツ', value: 'toppan_male_casual02_001', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_001.webp' },
    { text: 'グリーンシャツ', value: 'toppan_male_casual02_002', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_002.webp' },
    { text: 'グレーシャツ', value: 'toppan_male_casual02_003', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_003.webp' },
    { text: 'ギンガムチェックシャツ 1', value: 'toppan_male_casual02_004', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_004.webp' },
    { text: 'ギンガムチェックシャツ 2', value: 'toppan_male_casual02_005', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_005.webp' },
    { text: 'フランネルシャツ', value: 'toppan_male_casual02_006', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_006.webp' },
  ],
  toppan_female: [
    { text: '服装', value: 'disabled', disabled: true },
    // フォーマル
    { text: '黒スーツ', value: 'toppan_female_formal_001', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_001.webp' },
    { text: 'グレースーツ', value: 'toppan_female_formal_002', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_002.webp' },
    { text: 'デニムスーツ', value: 'toppan_female_formal_003', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_003.webp' },
    { text: 'フランネルスーツ', value: 'toppan_female_formal_004', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_004.webp' },
    { text: 'グレージャケット 1', value: 'toppan_female_formal_005', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_005.webp' },
    { text: 'グレージャケット 2', value: 'toppan_female_formal_006', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_006.webp' },
    { text: 'ベージュジャケット', value: 'toppan_female_formal_007', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_007.webp' },
    { text: 'ネイビージャケット', value: 'toppan_female_formal_008', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_008.webp' },
    { text: 'タータンチェックジャケット 1', value: 'toppan_female_formal_009', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_009.webp' },
    { text: 'タータンチェックジャケット 2', value: 'toppan_female_formal_010', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_010.webp' },
    { text: '赤ジャケット', value: 'toppan_female_formal_011', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_011.webp' },
    // カジュアル01
    { text: '白 T デニム 1', value: 'toppan_female_casual01_001', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_001.webp' },
    { text: '白 T デニム 2', value: 'toppan_female_casual01_002', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_002.webp' },
    { text: 'ストライプ T デニム 1', value: 'toppan_female_casual01_003', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_003.webp' },
    { text: 'ストライプ T デニム 2', value: 'toppan_female_casual01_004', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_004.webp' },
    { text: '柄 T デニム 1', value: 'toppan_female_casual01_005', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_005.webp' },
    { text: '柄 T デニム 2', value: 'toppan_female_casual01_006', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_006.webp' },
    { text: '柄 T デニム 3', value: 'toppan_female_casual01_007', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_007.webp' },
    { text: 'TOKYO', value: 'toppan_female_casual01_008', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_008.webp' },
    { text: 'TOPPAN', value: 'toppan_female_casual01_009', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_009.webp' },
    { text: '浦和レッズ', value: 'toppan_female_casual01_010', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_010.webp' },
    { text: 'セブンイレブン', value: 'toppan_female_casual01_011', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_011.webp' },
    // カジュアル02
    { text: '白シャツ', value: 'toppan_female_casual02_001', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_001.webp' },
    { text: 'ピンクシャツ', value: 'toppan_female_casual02_002', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_002.webp' },
    { text: 'ストライプシャツ 1', value: 'toppan_female_casual02_003', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_003.webp' },
    { text: 'ストライプシャツ 2', value: 'toppan_female_casual02_004', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_004.webp' },
    { text: 'ストライプシャツ 3', value: 'toppan_female_casual02_005', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_005.webp' },
    { text: 'グレーシャツ 1', value: 'toppan_female_casual02_006', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_006.webp' },
    { text: 'グレーシャツ 2', value: 'toppan_female_casual02_007', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_007.webp' },
    { text: '赤シャツ', value: 'toppan_female_casual02_008', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_008.webp' },
    { text: '黒シャツ 1', value: 'toppan_female_casual02_009', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_009.webp' },
    { text: '黒シャツ 2', value: 'toppan_female_casual02_010', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_010.webp' },
    { text: '黒シャツ 3', value: 'toppan_female_casual02_011', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_011.webp' },
    { text: 'ラグランスリーブ', value: 'toppan_female_casual02_012', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_012.webp' },
  ],
  toppan_child: [
    { text: '服装', value: 'disabled', disabled: true },
    // 一般
    { text: 'ロン T 短パン', value: 'toppan_child_general_001', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_001.webp' },
    { text: 'TOPPA! TOPPAN（茶ズボン）', value: 'toppan_child_general_002', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_002.webp' },
    { text: 'TOPPA! TOPPAN 2（青ズボン）', value: 'toppan_child_general_003', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_003.webp' },
    { text: '赤ボーダー', value: 'toppan_child_general_004', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_004.webp' },
    { text: 'ハンバーガー', value: 'toppan_child_general_005', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_005.webp' },
    { text: 'Me（パープル）', value: 'toppan_child_general_006', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_006.webp' },
    // 襟付きシャツ
    { text: '黒シャツチノパン', value: 'toppan_child_shirt_001', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_001.webp' },
    { text: 'ボーダーシャツチェックチノパン', value: 'toppan_child_shirt_002', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_002.webp' },
    { text: 'ボーダーシャツ黒チノパン', value: 'toppan_child_shirt_003', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_003.webp' },
    { text: 'ボーダーシャツベージュチノパン', value: 'toppan_child_shirt_004', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_004.webp' },
    { text: 'ワンポイント白シャツチェックチノパン', value: 'toppan_child_shirt_005', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_005.webp' },
    { text: 'ワンポイント白シャツ黒チノパン', value: 'toppan_child_shirt_006', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_006.webp' },
    { text: 'ワンポイント白シャツベージュチノパン', value: 'toppan_child_shirt_007', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_007.webp' },
    { text: 'ハートドットシャツチェックチノパン', value: 'toppan_child_shirt_008', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_008.webp' },
    { text: 'ハートドットシャツ黒チノパン', value: 'toppan_child_shirt_009', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_009.webp' },
    { text: 'ハートドットシャツベージュチノパン', value: 'toppan_child_shirt_010', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_010.webp' },
    // オーバーオール
    { text: 'オーバーオール（ベージュ）', value: 'toppan_child_overalls_001', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_001.webp' },
    { text: 'オーバーオール（ジーンズ）ボーダーロン T', value: 'toppan_child_overalls_002', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_002.webp' },
    { text: 'オーバーオール（ジーンズ）ピンクロン T', value: 'toppan_child_overalls_003', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_003.webp' },
    { text: 'オーバーオール（ジーンズ）白ロン T', value: 'toppan_child_overalls_004', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_004.webp' },
    { text: 'オーバーオール（白）ボーダーロン T', value: 'toppan_child_overalls_005', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_005.webp' },
    { text: 'オーバーオール（白）ピンクロン T', value: 'toppan_child_overalls_006', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_006.webp' },
    { text: 'オーバーオール（白）白ロン T', value: 'toppan_child_overalls_007', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_007.webp' },
    { text: 'オーバーオール（グリーン）ボーダーロン T', value: 'toppan_child_overalls_008', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_008.webp' },
    { text: 'オーバーオール（グリーン）ピンクロン T', value: 'toppan_child_overalls_009', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_009.webp' },
    { text: 'オーバーオール（グリーン）白ロン T', value: 'toppan_child_overalls_010', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_010.webp' },
    // 蝶ネクタイ
    { text: 'スーツ（蝶ネクタイ）', value: 'toppan_child_bowtie_001', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_001.webp' },
    { text: '黒チェック柄スーツ（蝶ネクタイ）', value: 'toppan_child_bowtie_002', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_002.webp' },
    { text: 'ネイビースーツ（蝶ネクタイ）', value: 'toppan_child_bowtie_003', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_003.webp' },
    { text: 'ネイビージャケットチノパンスーツ（蝶ネクタイ）', value: 'toppan_child_bowtie_004', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_004.webp' },
    { text: 'ベージュスーツ（蝶ネクタイ）', value: 'toppan_child_bowtie_005', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_005.webp' },
    { text: 'グレースーツ（蝶ネクタイ）', value: 'toppan_child_bowtie_006', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_006.webp' },
    // ネクタイ
    { text: 'スーツ（ネクタイ）', value: 'toppan_child_tie_001', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_001.webp' },
    { text: '黒チェック柄スーツ（ネクタイ）', value: 'toppan_child_tie_002', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_002.webp' },
    { text: 'ネイビースーツ（ネクタイ）', value: 'toppan_child_tie_003', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_003.webp' },
    { text: 'ネイビージャケットチノパンスーツ（ネクタイ）', value: 'toppan_child_tie_004', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_004.webp' },
    { text: 'ベージュスーツ（ネクタイ）', value: 'toppan_child_tie_005', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_005.webp' },
    { text: 'グレースーツ（ネクタイ）', value: 'toppan_child_tie_006', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_006.webp' },
  ],
  unisex: [
    // 甲冑
    { text: '甲冑', value: 'unisex_armor_001', src: '/img/thumbnails/unisex/armor/c_unisex_armor_001.webp' },
  ],
  mobile: [
    { text: 'フォーマル', value: 'formal' },
    { text: 'カジュアル 1', value: 'casual01' },
    { text: 'カジュアル 2', value: 'casual02' },
  ],
}
const outfitOptionsMap = {
  male: outfitOptions.male.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  female: outfitOptions.female.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  toppan_male: outfitOptions.toppan_male.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  toppan_female: outfitOptions.toppan_female.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  toppan_child: outfitOptions.toppan_child.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  unisex: outfitOptions.unisex.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  mobile: outfitOptions.mobile.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
}
const outfitTypeOptions = {
  sort: 'toppan_male:male:toppan_female:female:toppan_child:unisex',
  detail: {
    male: {
      name: '男性（筋肉質）',
      items: [
        { text: 'スーツ', value: 'formal', src: '/img/thumbnails/male/formal/c_000_male_formal_005.webp' },
        { text: 'T シャツ', value: 'casual01', src: '/img/thumbnails/male/casual01/c_male_casual01_005.webp' },
        { text: 'シャツ', value: 'casual02', src: '/img/thumbnails/male/casual02/c_male_casual02_002.webp' },
      ],
    },
    female: {
      name: '女性（メリハリ）',
      items: [
        { text: 'スーツ', value: 'formal', src: '/img/thumbnails/female/formal/c_female_formal_001.webp' },
        { text: 'T シャツ', value: 'casual01', src: '/img/thumbnails/female/casual01/c_female_casual01_001.webp' },
        { text: 'シャツ', value: 'casual02', src: '/img/thumbnails/female/casual02/c_female_casual02_002.webp' },
      ],
    },
    toppan_male: {
      name: '男性（中肉中背）',
      items: [
        { text: 'スーツ', value: 'formal', src: '/img/thumbnails/toppan_male/formal/c_000_toppan_male_formal_005.webp' },
        { text: 'T シャツ', value: 'casual01', src: '/img/thumbnails/toppan_male/casual01/c_toppan_male_casual01_005.webp' },
        { text: 'シャツ', value: 'casual02', src: '/img/thumbnails/toppan_male/casual02/c_toppan_male_casual02_002.webp' },
      ],
    },
    toppan_female: {
      name: '女性（標準）',
      items: [
        { text: 'スーツ', value: 'formal', src: '/img/thumbnails/toppan_female/formal/c_toppan_female_formal_001.webp' },
        { text: 'T シャツ', value: 'casual01', src: '/img/thumbnails/toppan_female/casual01/c_toppan_female_casual01_001.webp' },
        { text: 'シャツ', value: 'casual02', src: '/img/thumbnails/toppan_female/casual02/c_toppan_female_casual02_002.webp' },
      ],
    },
    toppan_child: {
      name: 'こども',
      items: [
        { text: 'ロン T', value: 'general', src: '/img/thumbnails/toppan_child/general/c_toppan_child_general_001.webp' },
        { text: 'シャツ', value: 'shirt', src: '/img/thumbnails/toppan_child/shirt/c_toppan_child_shirt_001.webp' },
        { text: 'オーバーオール', value: 'overalls', src: '/img/thumbnails/toppan_child/overalls/c_toppan_child_overalls_001.webp' },
        { text: '蝶ネクタイ', value: 'bowtie', src: '/img/thumbnails/toppan_child/bowtie/c_toppan_child_bowtie_001.webp' },
        { text: 'ネクタイ', value: 'tie', src: '/img/thumbnails/toppan_child/tie/c_toppan_child_tie_001.webp' },
      ],
    },
    unisex: {
      name: '男女共用',
      items: [
        { text: '甲冑', value: 'armor', src: '/img/thumbnails/unisex/armor/c_unisex_armor_001.webp' },
      ],
    },
  },
}
const adminOnlyOutfits = {
  male: ['male_casual01_013', 'male_casual01_014'],
  female: ['female_casual01_010', 'female_casual01_011'],
  toppan_male: ['toppan_male_casual01_013', 'toppan_male_casual01_014'],
  toppan_female: ['toppan_female_casual01_010', 'toppan_female_casual01_011'],
}
const outfitTypeToIndex = {
  male: {'casual01': 0, 'casual02': 1, 'formal': 2},
  female: {'casual01': 0, 'casual02': 1, 'formal': 2},
  toppan_male: {'casual01': 0, 'casual02': 1, 'formal': 2},
  toppan_female: {'casual01': 0, 'casual02': 1, 'formal': 2},
  toppan_child: {'bowtie': 0, 'general': 1, 'overalls': 2, 'shirt': 3, 'tie': 4},
  unisex: {'armor': 0},
}

// format
const formatOptions = [
  { text: 'ファイル形式', value: 'disabled', disabled: true },
  { text: 'VRM 形式', value: 'fbx_vrm' },
  { text: 'FBX 形式', value: 'fbx' },
  { text: 'GLB 形式', value: 'glb' },
]
const formatOptionsMap = formatOptions.reduce((a, x) => {a[x.value] = x.text; return a}, {})
const customerOnlyFormats = ['fbx_vrm']

// lod
const lodOptions = {
  customer: [
    { text: 'ポリゴン数', value: 'disabled', disabled: true },
    { text: 'ポリゴン数：50,000 程度', value: '0' },
    { text: 'ポリゴン数：22,000 程度（口内あり）', value: '5' },
    { text: 'ポリゴン数：16,000 程度（口内なし）', value: '7' },
  ],
  admin: [
    { text: 'LoD（詳細度）', value: 'disabled', disabled: true },
    { text: 'LoD：0（最高・最重量）', value: '0' },
    { text: 'LoD：1', value: '1' },
    { text: 'LoD：2', value: '2' },
    { text: 'LoD：3', value: '3' },
    { text: 'LoD：4', value: '4' },
    { text: 'LoD：5（口内有り）', value: '5' },
    { text: 'LoD：6（歯ぐき有り）', value: '6' },
    { text: 'LoD：7（最軽量・口内無し）', value: '7' },
  ],
}
const lodOptionsMap = {
  customer: lodOptions.customer.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
  admin: lodOptions.admin.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
}
const lodSimpleOptions = [
  { text: 'LoD0', value: '0' },
  { text: 'LoD1', value: '1' },
  { text: 'LoD2', value: '2' },
  { text: 'LoD3', value: '3' },
  { text: 'LoD4', value: '4' },
  { text: 'LoD5', value: '5' },
  { text: 'LoD6', value: '6' },
  { text: 'LoD7', value: '7' },
]
const lodSimpleOptionsMap = {
  customer: {
    0: '50,000 程度',
    5: '22,000 程度（口内あり）',
    7: '16,000 程度（口内なし）',
  },
  admin: lodOptions.admin.reduce((a, x) => {a[x.value] = x.text; return a}, {}),
}

// remove_smile
const removeSmileOptions = [
  { text: '表情', value: 'disabled', disabled: true },
  { text: '表情なし', value: '1' },
  { text: '写真の表情を反映', value: '0' },
]
const removeSmileOptionsMap = removeSmileOptions.reduce((a, x) => {a[x.value] = x.text; return a}, {})

// アニメーション
const tposeSettings = {
  male: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -5, 0], facePosOffset: [0, 10, -8], faceRot: [-0.1, 0, 0],
  },
  female: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -5, 20], facePosOffset: [0, 7, -8], faceRot: [-0.1, 0, 0],
  },
  toppan_male: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -5, 0], facePosOffset: [-1, 5, -5], faceRot: [-0.1, 0, 0],
  },
  toppan_female: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -10, 0], facePosOffset: [0, 10, -10], faceRot: [-0.1, 0, 0],
  },
  toppan_child: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -35, -35], facePosOffset: [0, 7, -10], faceRot: [-0.1, 0, 0],
  },
  mobile: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, 0, 0], facePosOffset: [0, 0, 0], faceRot: [0, 0, 0],
  },
  unisex: {
    modelPos: [0, 0, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -5, 0], facePosOffset: [-1, 5, -5], faceRot: [-0.1, 0, 0],
  },
}
const animationSettings = {
  male: [
    {
      text: 'ポーズ 1', value: '00_male_standing_pose', src: '/animated_avatars/result/00_male_standing_pose_looking_forward.fbx',
      modelPos: [-2, -2, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -10, 0], facePosOffset: [-4, 4, -30], faceRot: [-0.25, -0.3, 0],
    },
    {
      text: 'ポーズ 2', value: '01_male_standing_pose', src: '/animated_avatars/result/01_male_standing_pose_left_hand_on_hip.fbx',
      modelPos: [-1, -3, -4], modelRot: [0, -0.2, 0], firstPosOffset: [0, -10, 0], facePosOffset: [-3, 7, 3], faceRot: [-0.04, -0.1, 0.05],
    },
    {
      text: 'ポーズ 3', value: '02_male_locomotion_pose', src: '/animated_avatars/result/02_male_locomotion_pose_running_forward.fbx',
      modelPos: [5, -6, -22], modelRot: [0, -0.5, 0], firstPosOffset: [-10, -15, 0], facePosOffset: [2, 2, -35], faceRot: [-0.35, 0, 0],
    },
  ],
  female: [
    {
      text: 'ポーズ 1', value: '00_female_standing_pose', src: '/animated_avatars/result/00_female_standing_pose_arms_down.fbx',
      modelPos: [-12, 0, 0], modelRot: [0, -0.3, 0], firstPosOffset: [0, -10, 20], facePosOffset: [-3, 4, -25], faceRot: [-0.2, 0, 0],
    },
    {
      text: 'ポーズ 2', value: '01_female_locomotion_pose', src: '/animated_avatars/result/01_female_locomotion_pose_waling_right_arm_on_hip.fbx',
      modelPos: [6, -5, -5], modelRot: [0, -0.6, 0], firstPosOffset: [0, -15, 10], facePosOffset: [-4, 0, -25], faceRot: [-0.2, -0.04, 0.02],
    },
  ],
  toppan_male: [
    {
      text: 'ポーズ 1', value: '00_toppan_male_standing_pose', src: '/animated_avatars/result/00_male_standing_pose_looking_forward.fbx',
      modelPos: [-2, -2, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -5, 0], facePosOffset: [1, 1, -25], faceRot: [-0.25, -0.3, -0.03],
    },
    {
      text: 'ポーズ 2', value: '01_toppan_male_standing_pose', src: '/animated_avatars/result/01_male_standing_pose_left_hand_on_hip.fbx',
      modelPos: [-1, -3, -4], modelRot: [0, -0.2, 0], firstPosOffset: [0, -4, 0], facePosOffset: [-4, 3, 7], faceRot: [-0.04, -0.15, 0.05],
    },
    {
      text: 'ポーズ 3', value: '02_toppan_male_locomotion_pose', src: '/animated_avatars/result/02_male_locomotion_pose_running_forward.fbx',
      modelPos: [5, -6, -22], modelRot: [0, -0.5, 0], firstPosOffset: [-10, -12, 0], facePosOffset: [1, -1, -25], faceRot: [-0.3, 0, 0],
    },
  ],
  toppan_female: [
    {
      text: 'ポーズ 1', value: '00_toppan_female_standing_pose', src: '/animated_avatars/result/00_female_standing_pose_arms_down.fbx',
      modelPos: [-12, 0, 0], modelRot: [0, -0.3, 0], firstPosOffset: [0, -15, 0], facePosOffset: [-3, 7, -25], faceRot: [-0.2, 0, 0],
    },
    {
      text: 'ポーズ 2', value: '01_toppan_female_locomotion_pose', src: '/animated_avatars/result/01_female_locomotion_pose_waling_right_arm_on_hip.fbx',
      modelPos: [6, -5, -5], modelRot: [0, -0.6, 0], firstPosOffset: [0, -15, 0], facePosOffset: [-3, 3, -25], faceRot: [-0.2, -0.04, 0.02],
    },
  ],
  toppan_child: [
    {
      text: 'ポーズ 1', value: '00_toppan_child_standing_pose', src: '/animated_avatars/result/00_male_standing_pose_looking_forward.fbx',
      modelPos: [-2, -2, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -38, -35], facePosOffset: [-3, 3, -18], faceRot: [-0.2, -0.3, 0],
    },
  ],
  unisex: [
    {
      text: 'ポーズ 1', value: '00_unisex_standing_pose', src: '/animated_avatars/result/00_male_standing_pose_looking_forward.fbx',
      modelPos: [-2, -2, 0], modelRot: [0, 0, 0], firstPosOffset: [0, -5, 0], facePosOffset: [1, 1, -25], faceRot: [-0.25, -0.3, -0.03],
    },
    {
      text: 'ポーズ 2', value: '01_unisex_standing_pose', src: '/animated_avatars/result/01_male_standing_pose_left_hand_on_hip.fbx',
      modelPos: [-1, -3, -4], modelRot: [0, -0.2, 0], firstPosOffset: [0, -4, 0], facePosOffset: [-4, 3, 7], faceRot: [-0.04, -0.15, 0.05],
    },
    {
      text: 'ポーズ 3', value: '02_unisex_locomotion_pose', src: '/animated_avatars/result/02_male_locomotion_pose_running_forward.fbx',
      modelPos: [5, -6, -22], modelRot: [0, -0.5, 0], firstPosOffset: [-10, -12, 0], facePosOffset: [1, -1, -25], faceRot: [-0.3, 0, 0],
    },
  ],
}

// その他
const defaultSettings = {
  gender: { fit: 'non_binary' },
  outfit: { fit : 'formal' },
  format: 'fbx',
  lod: '5',
  removeSmile: '1',
}
const attrTitleSettings = {
  gender: 'スタイル',
  outfit: '服装',
  format: 'ファイル形式',
  lodCustomer: 'ポリゴン数',
  lodAdmin: 'LoD（詳細度）',
  removeSmile: '表情',
  outfitSize: '服装の解像度',
  modelSize: '素体の解像度',
  option: 'オプション',
}
const imageEditSettings = [
  { text: 'オリジナル', url: '', uploadUrl: '', status: false, dummy: true },
  { text: '美肌補正', url: '', status: false, dummy: true },
  { text: '美顔 AI', url: '', status: false, dummy: true },
  { text: '未来の自分 1', url: '', status: false, dummy: true },
  { text: '未来の自分 2', url: '', status: false, dummy: true },
  { text: '未来の自分 3', url: '', status: false, dummy: true },
  { text: '過去の自分 1', url: '', status: false, dummy: true },
  { text: '過去の自分 2', url: '', status: false, dummy: true },
  { text: '過去の自分 3', url: '', status: false, dummy: true },
  { text: '性別変換 AI 1', url: '', status: false, dummy: true },
  { text: '性別変換 AI 2', url: '', status: false, dummy: true },
  { text: '性別変換 AI 3', url: '', status: false, dummy: true },
  { text: '正面に向かせる', url: '', status: false, dummy: true },
]
const projectSettings = {
  genders: ['toppan_male', 'male', 'toppan_female', 'female', 'toppan_child', 'unisex'],
  outfits: {
    toppan_male: ['formal', 'casual01', 'casual02'],
    male: ['formal', 'casual01', 'casual02'],
    toppan_female: ['formal', 'casual01', 'casual02'],
    female: ['formal', 'casual01', 'casual02'],
    toppan_child: ['general', 'shirt', 'overalls', 'bowtie', 'tie'],
    unisex: ['armor'],
  },
  formats: ['fbx_vrm', 'fbx', 'glb'],
  lods: ['0', '5', '7'],
  removeSmiles: ['1'],
  option: {
    viewed: false,
    items: [],
  },
}
const additionalOptions = {
  viewed: [
    { text: '画面表示しない', value: false },
    { text: '画面表示する', value: true },
  ],
  items: [
    { text: 'オプション', value: 'disabled', disabled: true },
    { text: 'ボーン名変更', value: 'rename_bone' },
    { text: 'Blendshape 削減', value: 'reduce_blendshape' },
    { text: 'Blendshape 削減<br>（JOY のみ）', value: 'reduce_blendshape_joy' },
    { text: 'Blendshape 削除', value: 'remove_blendshape' },
    { text: '服装の解像度<br>（128 px）', value: 'outfit_size_128' },
    { text: '服装の解像度<br>（512 px）', value: 'outfit_size_512' },
    { text: '素体の解像度<br>（256 px）', value: 'model_size_256' },
    { text: 'AI 加工：<br>美顔 AI', value: 'beauty' },
    { text: 'AI 加工：<br>未来の自分', value: 'older' },
    { text: 'AI 加工：<br>過去の自分', value: 'younger' },
    { text: 'AI 加工：<br>性別変換 AI', value: 'sex_change' },
    { text: 'AI 加工：<br>正面に向かせる', value: 'front_face' },
    { text: 'AI 加工の<br>クオリティアップ<br>（目の高さ、帽子、顔の精度）', value: 'high_quality' },
  ],
}

export {
  genderOptions, genderOptionsMap,
  outfitOptions, outfitOptionsMap, outfitTypeOptions, adminOnlyOutfits, outfitTypeToIndex,
  formatOptions, formatOptionsMap, customerOnlyFormats,
  lodOptions, lodOptionsMap, lodSimpleOptions, lodSimpleOptionsMap,
  removeSmileOptions, removeSmileOptionsMap,
  tposeSettings, animationSettings,
  defaultSettings, attrTitleSettings, imageEditSettings,
  projectSettings, additionalOptions,
}
