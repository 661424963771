<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" :style="{'width':width.width, 'background-color':modalColor, 'border': border, 'max-height': maxHeight}">
        <div class="modal-container" :class="modalColor != null ? '' : 'white-dot-bg'" :style="{'color': color, 'background-color': whiteBackgroundColor}">
          <div class="modal-header large-font" v-if="header">{{header}}</div>
          <div class="modal-content">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer" v-if="isSmartPhone">
            <template v-if="footer">{{footer}}</template>
            <button v-if="deletetext" class="btn yes" @click="deleteUser" style="margin-left:15px;">
              <img src="@/assets/img/icon_create.svg">
              {{deletetext}}
            </button>
            <button v-if="donetext" class="btn yes" @click="done" style="margin-left:15px;">
              <img src="@/assets/img/icon_create.svg">
              {{donetext}}
            </button>
            <button v-if="canceltext" class="btn" :class="{'alert': isAlert, 'no': !isAlert}" @click="close">{{canceltext}}</button>
          </div>
          <div class="modal-footer" v-else>
            <template v-if="footer">{{footer}}</template>
            <button v-if="canceltext" class="btn mid" :class="{'alert': isAlert, 'no': !isAlert}" @click="close">{{canceltext}}</button>
            <button v-if="deletetext" class="btn yes mid" @click="deleteUser" style="margin-left:15px;">
              <img src="@/assets/img/icon_create.svg">
              {{deletetext}}
            </button>
            <button v-if="donetext" class="btn yes mid" @click="done" style="margin-left:15px;">
              <img src="@/assets/img/icon_create.svg">
              {{donetext}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props:{
    header:String,
    footer:String,
    donetext:String,
    deletetext:String,
    canceltext:String,
    propWidth:String,
    border: String,
    opacity: String,
    modalColor: String,
    color: String,
    isAlert: Boolean,
    maxHeight: String,
    whiteBackgroundColor: String,
    height: String,
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) {
        return true
      } else {
        return false
      }
    }
  },
  data(){
    return {
      width: {width: this.propWidth}
    }
  },
  methods: {
    done() {this.$emit('modal-done')},
    deleteUser() {this.$emit('modal-delete')},
    close() {this.$emit('modal-close')},
  },
}
</script>

<style scoped>
.alert{
  position: absolute !important;
  right: 0;
  background-color: rgb(0, 115, 227);
  color: white;
  border-radius: 5px !important;
  min-width: 75px !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-mask .modal-wrapper {
  max-height: 500px;
  max-width: 80%;
}

@media (max-width: 834px) {
  .modal-mask .modal-wrapper {
    max-width: 98%;
    width: 90%;
  }
  .modal-mask .modal-wrapper .modal-container {
    padding: 20px 30px 40px 30px !important;
  }
}

.modal-mask .modal-wrapper .modal-container {
  margin: 0px auto;
  padding: 20px 30px 0 30px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-mask .modal-wrapper .modal-container .modal-content {
  overflow-y: auto;
  margin-top: 15px;
  border: initial !important;
}

.modal-mask .modal-wrapper .modal-container .modal-content div {
  max-height: 375px;
}

.modal-mask .modal-wrapper .modal-container .modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top: initial;
  height: 75px;
  position: relative;
}

.modal-mask .modal-wrapper .modal-container .modal-footer .btn {
  color: white;
  border: initial;
  border-radius: 15px;
  padding: 5px 30px !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: bold;
}

@media (max-width: 834px) {
  .modal-mask .modal-wrapper .modal-container .modal-footer .btn {
    padding: 5px 10px;
    display: block;
    margin: 15px auto 0;
    width: 100%;
  }
  .btn.yes{
    margin-left: initial !important;
  }
  .modal-wrapper{
    max-height: 700px;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
