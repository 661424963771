<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv kv-top-contain">
        <div class="uk-container">
          <div class="uk-grid uk-margin-bottom uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-1-2">
              <div class="logo-plane">
                <img src="@/assets/img/logo.svg" alt="MEta Clone"/>
              </div>
            </div>
          </div>
          <div style="display: grid;">
            <div>
              <button type="button" class="btn menu" style="position: absolute;right: 15px;top: 40px;right: 30px;" @click="signOut">サインアウト</button>
            </div>
          </div>
          <div class="white-dot-bg" style="margin: 30px auto;padding-bottom: 30px;">
            <div>
              <div class="uk-card-header">
                <div class="process1">
                  <img src="@/assets/img/process1.png"/>
                  <p>顔のタイプを選択してください。</p>
                </div>
              </div>
              <div class="uk-card-body">
                <div id="pane-container" class="pane-container-no-image"></div>
                <div class="js-upload uk-grid-small uk-flex uk-flex-middle uk-flex-center" uk-grid>
                  <template v-for="(item, i) in imageEditSettings">
                    <template v-if="item.url">
                      <div class="uk-width-1-3@s uk-width-auto@m">
                        <span class="type-text">{{ item.text }}</span>
                        <div class="ai-dummy-container">
                          <img v-if="item.status" :src="item.url" class="target-image uk-align-center opacity-white-border" :class="{selected: targetType === i.toString()}" @click="targetType = i.toString()">
                          <img v-else :src="item.url" class="target-image uk-align-center opacity-white-border">
                          <p v-if="item.dummy">平日 9 時から 18 時の間で<br>お使いいただけます</p>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
                <span class="download-notice">※「次へ」でアバターを生成しても、<br/>「今月のダウンロード数」にカウントされません</span>
              </div>
            </div>
            <div class="dashed"></div>
            <div class="btn-wrapper" :class="isSmartPhone ? 'uk-margin-large-top uk-margin-medium-bottom' : 'uk-margin-medium-top'">
              <router-link to="/" class="btn no">トップに戻る</router-link>
              <button class="btn yes" :disabled="!createEnabled" style="margin-left: 15px;" @click="requestCreateAvatar()"><img src="@/assets/img/icon_create.svg">次へ</button>
            </div>
            <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'
import Modal from '@/components/parts/Modal'
import { imageEditSettings } from '../assets/js/constants'

export default {
  name: 'Preview',
  components:{
    Modal,
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      targetType: '0',
      imageEditSettings: this.deepCopy(imageEditSettings),
      isAIDummy: false,
      createEnabled: true,
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) return true
      return false
    },
  },
  methods:{
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    requestCreateAvatar() {
      this.createEnabled = false
      sessionStorage.setItem('image_edit_type', this.targetType)
      console.log(`selected: ${this.imageEditSettings[parseInt(this.targetType, 10)].text}`)
      setTimeout(() => {
        this.$router.push({
          name: 'processing',
          params: { processingType: 'creating' },
        })
      }, 1500)
      const self = this
      const bar = document.getElementById('js-progressbar')
      const params = {
        uuid: this.uuid,
        user_sub: sessionStorage.getItem('user_sub'),
        gender: sessionStorage.getItem('gender'),
        outfit: sessionStorage.getItem('outfit'),
        export_format: sessionStorage.getItem('export_format'),
        lod: sessionStorage.getItem('lod'),
        remove_smile: sessionStorage.getItem('remove_smile'),
        option: sessionStorage.getItem('option'),
        height: sessionStorage.getItem('height'),
        weight: sessionStorage.getItem('weight'),
        chest: sessionStorage.getItem('chest'),
        waist: sessionStorage.getItem('waist'),
        hips: sessionStorage.getItem('hips'),
        kind: sessionStorage.getItem('kind'),
        is_vrm: JSON.parse(sessionStorage.getItem('is_vrm')),
        view_limit: JSON.parse(sessionStorage.getItem('view_limit')),
        image_edit_type: parseInt(this.targetType, 10),
      }
      // バックエンドへリクエスト
      const uploadComponents = UIkit.upload('.js-upload', {
        url: self.axios.defaults.baseURL + '/api/upload_image',
        multiple: false,
        name: 'upload_image',
        params: params,
        method: 'POST',
        error: function (err) {
          try {
            bar.setAttribute('hidden', 'hidden');
            if (err.xhr.response !== '' && err.xhr.status >= 400 && err.xhr.status < 500) {
              self.$router.push({
                name: 'error',
                params: { errorType: err.xhr.status.toString(), errorSummary: JSON.parse(err.xhr.response).message },
              })
              return
            } else if (err.xhr.response !== '' && err.xhr.status >= 500) {
              UIkit.notification({
                message: JSON.parse(err.xhr.response).message,
                status: 'danger',
                pos: 'top-center',
                timeout: 4000,
              })
            }
            self.$router.push({ name: 'error' })
          } catch {
            self.$router.push({ name: 'error' })
          }
        },
        beforeSend: function (environment) {
          const headers = environment.headers
          const info = self.$store.state.cognite.signInUserSession.getIdToken()
          const sp = info.payload.iss.split('/')
          headers['X-Session-ID'] = info.jwtToken
          headers['X-Auth-Time'] = info.payload.auth_time
          headers['X-Cognito-Client-ID'] = info.payload.aud
          headers['X-User-Pool-ID'] = sp[sp.length-1]
        },
        beforeAll: function (settings) {
          settings.params = params
        },
        loadStart: function (e) {
          bar.max = e.total
          bar.value = e.loaded
        },
        progress: function (e) {
          bar.max = e.total
          bar.value = e.loaded
        },
        loadEnd: function (e) {
          bar.max = e.total
          bar.value = e.loaded
        },
        completeAll: function (response) {
          const resJson = JSON.parse(response.response)
          const uuid = resJson.uuid
          setTimeout(function () {
            bar.setAttribute('hidden', 'hidden')
          }, 1000)
          self.$router.push({
            name: 'result',
            params: { uuid: uuid },
          })
        },
      })
      setTimeout(async () => {
        const targetImageURLs = this.imageEditSettings.slice(0, 13).map(setting => setting.url)
        const response = await fetch(targetImageURLs[parseInt(this.targetType, 10)])
        const blob = await response.blob()
        const file = new File([blob], sessionStorage.getItem('filename'), { type: sessionStorage.getItem('mimeType') })
        uploadComponents.upload([file])
      }, 2000)
    },
    // dataURLtoFile(dataurl) {
    //   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    //   while(n--) {
    //       u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new File([u8arr], sessionStorage.getItem('filename'), { type: mime })
    // },
  },
  created () {
    if (this.uuid !== sessionStorage.getItem('uuid')) this.$router.push({ name: 'error' })
    if (!sessionStorage.getItem('orig_upload_image_url')) this.$router.push({ name: 'error' })
    if (!sessionStorage.getItem('type99_image_url')) this.$router.push({ name: 'error' })
    if (!sessionStorage.getItem('orig_image_url') && !sessionStorage.getItem('type1_image_url')) this.$router.push({ name: 'error' })
    const imageTypes = [
      'orig_image_url', 'type1_image_url', 'type2_image_url',
      'type31_image_url', 'type32_image_url', 'type33_image_url',
      'type41_image_url', 'type42_image_url', 'type43_image_url',
      'type51_image_url', 'type52_image_url', 'type53_image_url',
      'type6_image_url',
    ]
    imageTypes.forEach((type, index) => {
      const imageUrl = sessionStorage.getItem(type)
      if (imageUrl) {
        this.imageEditSettings[index].url = sessionStorage.getItem('type99_image_url')
        if (imageUrl !== 'server not running') {
          this.imageEditSettings[index].url = imageUrl
          this.imageEditSettings[index].status = true
          this.imageEditSettings[index].dummy = false
        }
      }
    })
    if (sessionStorage.getItem('image_edit_type')) this.targetType = sessionStorage.getItem('image_edit_type')
  },
}
</script>

<style scoped>
.target-image {
  width: 300px;
  height: auto;
  margin-top: 10px;
  border-width: 5px;
}
.target-image.selected {
  border-color: red;
}
.ai-dummy-container {
  position: relative;
}
.ai-dummy-container p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  color: rgb(32, 32, 32) !important;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
}
.ai-dummy-container img {
  margin-bottom: 0 !important;;
}
.type-text {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 0 6px black;
}
.download-notice {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
.process1 {
  font-size: 16px;
  text-align: center;
  text-shadow: 0 0 6px black, 0 0 6px black, 0 0 6px black;
}
.process1 img {
  width: 150px;
}
@media only screen and (max-width: 450px) {
  .process1 img {
    width: 100px;
  }
}
.process1 p {
  margin-top: 10px;
}
.dashed {
  width: 700px;
  border-bottom: 3px dashed white;
  margin: 0 auto;
}
.btn-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.btn.yes {
  width: 215px;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 31px;
}
.btn.no {
  width: 215px;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 15px !important;
  position: relative !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 31px;
}
@media (max-width: 767px) {
  .btn.yes {
    width: auto;
  }
  .btn.no {
    width: auto;
  }
  .uk-container {
    width: 100%;
    overflow: hidden;
  }
}
.pane-container {
  position: absolute;
  right: -85px;
  width: 200px;
}
.pane-container-no-image {
  position: absolute;
  right: -37px;
  width: 200px;
}
@media only screen and (max-width: 450px) {
  .pane-container {
    right: 5px;
    top: 85vh;
  }
  .pane-container-no-image {
    right: 5px;
    top: 57vh;
  }
}
.uk-card-header {
  border-bottom: 3px dashed #E5E5DC;
  width: 900px;
  margin: 0 auto;
}
@media only screen and (max-width: 770px) {
  .uk-card-header {
      width: 100% !important;
  }
}
</style>
