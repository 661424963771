<template>
  <div class="uk-card-header">
    <h3 class="uk-card-title">MetaCloneAvatar 作成結果</h3>
    <div class="process2">
      <img src="@/assets/img/process2.png"/>
      <p>生成が完了しました。<br>右のアイコンから服装を選択してください。<br>内容を確認し、問題なければダウンロードを押してください。</p>
    </div>
  </div>
  <div class="uk-card-body">
    <div id="pane-container" class="pane-container-no-image"></div>
    <div class="uk-flex uk-flex-middle uk-flex-center uk-grid-small" uk-grid>
      <div id="canvas-parent" class="uk-width-1-1">
        <div :style="{visibility: loadStatus === 2 ? '' : 'hidden'}">
          <!-- left -->
          <div class="canvas-child left">
            <div v-if="animations.length" class="uk-flex uk-flex-middle uk-flex-center uk-flex-column">
              <button v-for="(item, i) in animations" :key="item.value" :value="item.value" :class="`animation-${i} animation-button uk-button uk-button-default uk-margin-small-top uk-border-rounded uk-width-2-3@s`" :disabled="!item.isLoaded">{{ item.text }}</button>
              <button class="animation-99 animation-button uk-button uk-button-default uk-margin-small-top uk-border-rounded uk-width-2-3@s" :disabled="!animations.every(animation => animation.isLoaded === true)">T ポーズ</button>
            </div>
            <div class="uk-flex uk-flex-middle uk-flex-center uk-margin">
              <button class="face-zoom-in controller-button uk-button uk-button-default uk-margin-small-right uk-border-rounded uk-flex uk-flex-middle uk-flex-center">
                <img class="uk-width-1-1 uk-height-1-1" src="@/assets/img/face-zoom-in.png">
              </button>
              <button class="full-body-display controller-button uk-button uk-button-default uk-border-rounded uk-flex uk-flex-middle uk-flex-center">
                <img class="uk-width-1-1 uk-height-1-1" src="@/assets/img/full-body-display.png">
              </button>
            </div>
            <div class="display-pc uk-flex uk-flex-middle uk-flex-center uk-margin uk-margin-small-bottom">
              <input class="uk-checkbox uk-margin-small-right" type="checkbox" v-model="isNormalMap">
              <label class="normalmap-label">凹凸 表現を適用する<br>(Normal Map)</label>
            </div>
            <p v-if="isNormalMap" class="normalmap-notice display-pc">※ アップロードする環境によって<br>Normal Map が適用されない<br>場合があります。( Cluster 等 )</p>
            <div class="operation-manual uk-margin-top">※ モデルの操作方法については<br><a href="/生成した3Dモデルの操作方法.pdf" target="_blank">こちら</a> をご確認ください。</div>
          </div>
          <!-- center-right -->
          <div v-if="isTargetChangeTextures" class="canvas-child center-right">
            <button v-if="!atTop" class="scroll-button scroll-up" @mousedown="scrollUp()" @mouseup="stopScroll()"><span></span></button>
            <div class="outfit-container uk-panel uk-panel-scrollable" ref="scroll">
              <div class="uk-grid-small" uk-grid>
                <template v-for="(item, i) in outfits[gender]">
                  <div v-if="item.value.includes(`_${outfitType}_`)" class="uk-width-1-2">
                    <button @click="changeOutfit(item.value)"><img :class="{activate: isLoadDefaultOutfit && outfit_str === item.value}" :src="item.src" @load="checkDefaultOutfit(item.value)"></button>
                  </div>
                </template>
              </div>
            </div>
            <button v-if="!atBottom" class="scroll-button scroll-down" @mousedown="scrollDown()" @mouseup="stopScroll()"><span></span></button>
          </div>
          <!-- right -->
          <div v-if="isTargetChangeTextures" class="canvas-child right">
            <div class="first-item uk-flex uk-flex-middle uk-flex-center uk-flex-column">
              <hr>
              <template v-for="(type, index) in outfitTypes">
                <button :class="['outfittype-button', type.value, 'uk-button', 'uk-button-default', 'uk-border-rounded', 'uk-flex', 'uk-flex-middle', 'uk-flex-center', {'uk-margin-top': index > 0}, {'activate': outfitType === type.value}]" @click="changeOutfitType(type.value)" :disabled="disabledTypes.includes(type.value)">
                  <img v-if="outfitType === type.value" class="uk-width-1-1 uk-height-1-1" :src="require(`../assets/img/result-selected-${type.value}.png`)">
                  <img v-else class="uk-width-1-1 uk-height-1-1" :src="require(`../assets/img/result-${type.value}.png`)">
                </button>
              </template>
              <hr>
            </div>
          </div>
        </div>
        <div id="renderCanvas"></div>
        <!-- アバターローディング -->
        <div v-if="isRendering" class="rendering-loader">
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
          <div><span></span></div>
        </div>
      </div>
    </div>
    <div class="display-sp uk-margin">
      <div class="uk-flex uk-flex-middle uk-flex-center uk-margin-small-bottom">
        <input class="uk-checkbox uk-margin-small-right" type="checkbox" v-model="isNormalMap">
        <label class="normalmap-label">凹凸 表現を適用する (Normal Map)</label>
      </div>
      <p v-if="isNormalMap" class="normalmap-notice">※ アップロードする環境によって<br>Normal Map が適用されない場合があります。( Cluster 等 )</p>
    </div>
    <div class="attr-wrapper mt-5">
      <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.gender }} 】</span>: <span class="attr-value">{{ genderText }}</span></div>
      <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.outfit }} 】</span>: <span class="attr-value">{{ outfitText }}</span></div>
      <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.format }} 】</span>: <span class="attr-value">{{ formatText }}</span></div>
      <div v-if="admin" class="vertical-center"><span class="attr-title">【 {{ attrTitles.lodAdmin }} 】</span>: <span class="attr-value">{{ lodText }}</span></div>
      <div v-else class="vertical-center"><span class="attr-title">【 {{ attrTitles.lodCustomer }} 】</span>: <span class="attr-value">{{ lodText }}</span></div>
      <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.removeSmile }} 】</span>: <span class="attr-value">{{ removeSmileText }}</span></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import {
  genderOptionsMap,
  outfitOptions, outfitOptionsMap, outfitTypeOptions, adminOnlyOutfits, outfitTypeToIndex,
  formatOptionsMap,
  lodSimpleOptionsMap,
  removeSmileOptionsMap,
  tposeSettings, animationSettings,
  attrTitleSettings,
} from '../assets/js/constants'

export default {
  name: 'CreatedAvatar',
  props: {
    isViewPage: Boolean,
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      image_ext: '',
      gender: '',
      genderText: '',
      outfit_str: '',
      outfit_strs: [],
      outfitText: '',
      outfits: [],
      outfitTypes: [],
      outfitType: '',
      org_export_format: '',
      formatText: '',
      export_format: '',
      lod: '',
      lodText: '',
      removeSmile: '',
      removeSmileText: '',
      height: 0,
      kind: '',
      s3_avatar_url: '',
      s3_avatar_urls: [],
      s3_hair_url: '',
      s3_hair_urls: [],
      s3_body_url: '',
      s3_body_urls: [],
      s3_outfit_url: '',
      s3_outfit_urls: [],
      s3_outfit_normal_map_url: '',
      s3_outfit_normal_map_urls: [],
      account_manage_info: {status: '', item: {}},
      attrTitles: attrTitleSettings,
      projects: {},
      tposeSettings: [],
      animations: [],
      animationType: 99,
      admin: false,
      adminLod: false,
      isLoadAnimation: false,
      loadStatus: 0,
      isNormalMap: true,
      isLoadDefaultOutfit: false,
      scrollInterval: null,
      atTop: true,
      atBottom: false,
      isRendering: false,
      renderingCount: 0,
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) return true
      return false
    },
    isTargetChangeTextures() {
      if (this.kind !== 'meta') return false
      return true
    },
    disabledTypes() {
      const disabledTypes = []
      if (!this.projects.outfit_allow_list) return disabledTypes
      for (const type in outfitTypeToIndex[this.gender]) {
        if (this.projects.outfit_allow_list[this.gender].includes(type)) continue
        disabledTypes.push(type)
      }
      return disabledTypes
    },
  },
  methods: {
    changeOutfitType(outfitType) {
      if (this.isRendering) return
      if (outfitType === this.outfitType) return
      this.isLoadDefaultOutfit = false
      const targetIndex = outfitTypeToIndex[this.gender][outfitType]
      this.s3_avatar_url = this.s3_avatar_urls[targetIndex]
      this.s3_hair_url = this.s3_hair_urls[targetIndex]
      this.s3_body_url = this.s3_body_urls[targetIndex]
      this.s3_outfit_normal_map_url = this.s3_outfit_normal_map_urls[targetIndex]
      this.outfitType = outfitType
      this.changeOutfit(this.outfit_strs[targetIndex])
    },
    changeOutfit(outfit) {
      if (this.isRendering) return
      if (outfit === this.outfit_str) return
      this.s3_outfit_url = this.s3_outfit_url.replace(this.outfit_str.replace('toppan_', ''), outfit.replace('toppan_', ''))
      this.outfit_str = outfit
      this.outfitText = outfitOptionsMap[this.gender][outfit]
      this.renderModel()
    },
    checkDefaultOutfit(outfit) {
      if (this.outfit_strs.includes(outfit)) {
        this.isLoadDefaultOutfit = true
        this.handleScroll()
      }
    },
    handleScroll() {
      const offset = 10
      this.atTop = this.$refs.scroll.scrollTop <= offset
      this.atBottom = this.$refs.scroll.scrollTop + this.$refs.scroll.clientHeight + offset >= this.$refs.scroll.scrollHeight
    },
    scrollUp() {
      this.scrollInterval = setInterval(() => {
        this.$refs.scroll.scrollTop -= 100
        if (this.atTop) this.stopScroll()
      }, 100)
    },
    scrollDown() {
      this.scrollInterval = setInterval(() => {
        this.$refs.scroll.scrollTop += 100
        if (this.atBottom) this.stopScroll()
      }, 100)
    },
    stopScroll() {
      clearInterval(this.scrollInterval)
      this.scrollInterval = null
    },
    onWindowResize() {
    },
    changeAnimation() {
    },
    calcCanvasWidth() {
      let width
      if (this.isSmartPhone) {
        width = Math.floor( window.innerWidth * 0.8 )
      } else {
        const parent = document.getElementById('canvas-parent')
        width = Math.floor(parent.clientWidth)
      }
      return width
    },
    getPresignedMaterialUrl(url) {
      let matUrl = ''
      if (url == null || url == '') return matUrl
      if (url.match(/generated.png/)) matUrl = this.s3_hair_url
      else if (url.match(/model.png/)) matUrl = this.s3_body_url
      else if (url.endsWith('_lowpoly.png')) matUrl = this.s3_outfit_url
      else matUrl = url
      return matUrl
    },
    renderModel() {
      if (this.isRendering) return
      this.isRendering = true

      const modelUrl = this.s3_avatar_url
      const modelContainer = new THREE.Group()
      const hemiLightVal = 1.4
      const paneElem = document.getElementById('pane-container')
      if (!paneElem) return
      paneElem.innerHTML = ''
      let mixer

      // 画面そのままのアスペクト比だと少し細長くなってしまうので調整
      const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 4, 1000)
      let firstCameraPosX = 0
      let firstCameraPosY = 140
      let firstCameraPosZ = 270
      if (this.height > 160) {
        firstCameraPosY = parseInt(firstCameraPosY + this.height - 160)
        firstCameraPosZ = parseInt(firstCameraPosZ + (this.height - 160) * 1.5)
      }
      camera.position.set(firstCameraPosX, firstCameraPosY, firstCameraPosZ)

      const scene = new THREE.Scene()
      scene.background = new THREE.Color( 0xf6f6f6 )
      scene.fog = new THREE.Fog( 0xf6f6f6, 200, 1000 )

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, hemiLightVal)
      hemiLight.position.set(0, 210, 10)
      scene.add( hemiLight )

      const dirLight = new THREE.DirectionalLight(0xffffff, 0.3)
      dirLight.position.set( 0, 200, 50 )
      dirLight.castShadow = true
      dirLight.shadow.camera.top = 180
      dirLight.shadow.camera.bottom = -100
      dirLight.shadow.camera.left = -120
      dirLight.shadow.camera.right = 120
      dirLight.shadow.mapSize.width = 128
      dirLight.shadow.mapSize.height = 128
      scene.add(dirLight)

      // ground
      const groundMesh = new THREE.Mesh(new THREE.PlaneGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }))
      groundMesh.rotation.x = - Math.PI / 2
      groundMesh.receiveShadow = true
      scene.add(groundMesh)

      const container = document.getElementById('renderCanvas')
      if (!container) alert('描画領域が見つかりません')
      container.innerHTML = '';
      const renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true })
      let heightFix = Math.floor( window.innerHeight * 0.7 )
      let width = this.calcCanvasWidth()
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.setSize(width, heightFix)
      camera.aspect = width / heightFix
      camera.updateProjectionMatrix()
      renderer.shadowMap.enabled = true
      renderer.outputEncoding = THREE.sRGBEncoding
      container.appendChild(renderer.domElement)

      const controls = new OrbitControls(camera, renderer.domElement)
      // 滑らかにカメラコントローラーを制御する
      controls.enableDamping = true
      controls.dampingFactor = 0.2
      if (this.isViewPage) {
        controls.mouseButtons = {}
        controls.touches = {}
      }
      let firstTgtX = 0
      let firstTgtY = 100
      let firstTgtZ = 15
      if (this.height > 160) {
        firstTgtY = parseInt(firstTgtY + (this.height - 160) * 0.5)
      }
      controls.target.set(firstTgtX, firstTgtY, firstTgtZ)

      let facePosX = 0
      let faceTgtX = 0
      let facePosY = this.kind == 'fit' ? this.height - 6 : this.gender == 'male' ? this.height - 1 : this.height + 5
      let faceTgtY = this.kind == 'fit' ? this.height - 13 : this.gender == 'male' ? this.height - 10 : this.height - 2
      let facePosZ = this.kind == 'fit' ? 55 : this.gender == 'male' ? 55 : 50
      let faceTgtZ = this.kind == 'fit' ? 30 : 20

      // model
      const manager = new THREE.LoadingManager()
      manager.setURLModifier((url) => {
        if (url.match(/model.glb/) || url.match(/model.fbx/) || url.match(/model.gltf/)) {
          return url
        }
        let modUrl = ''
        try {
          modUrl = this.getPresignedMaterialUrl(url)
        } catch(err) {
          // 握り潰し
        }
        return modUrl
      })
      let loader
      const exportFormat = this.export_format
      if (exportFormat  === 'fbx') {
        loader = new FBXLoader(manager)
      } else {
        loader = new GLTFLoader(manager)
      }
      let tgtObj
      let loadGenerated = false
      let loadMesh = false
      let loadOutfit = false
      loader.load(
        modelUrl,
        (object) => {
          if (exportFormat === 'fbx') {
            tgtObj = object
          } else {
            tgtObj = object.scene
          }
          tgtObj.traverse( async ( child ) => {
            if (child.isMesh) {
              loadGenerated = true
              loadMesh = true
              loadOutfit = true
              child.material.alphaMap = null
              if (child.material.specular) child.material.specular.setRGB(0, 0, 0)
              if (child.name === 'haircut_generated' || child.material.name === 'generated.png') {
                child.renderOrder = 2
                child.material.opacity = 1.0
                child.material.transparent = true
              } else if (child.name === 'mesh' || child.material.name === 'model.png') {
                child.renderOrder = 1
                child.material.opacity = 1.0
                child.material.alphaTest = 0.01
                child.material.transparent = false
              } else if (child.name.endsWith('_lowpoly')) {
                child.material.transparent = false
                if (this.isNormalMap) {
                  const textureLoader = new THREE.TextureLoader()
                  child.material.normalMap = textureLoader.load(this.s3_outfit_normal_map_url)
                  if (exportFormat !== 'fbx') child.material.normalMap.flipY = false
                }
              } else {
                child.material.transparent = false
              }
              if (child.material) {
                child.material.needsUpdate = true
              }
              child.needsUpdate = true
              child.castShadow = true
              child.receiveShadow = true
            }
          })
          tgtObj.scale.set(100, 100, 100)
          let interval = setInterval(() => {
            if (!loadGenerated || !loadMesh || !loadOutfit || !this.animations.every(animation => animation.isLoaded === true)) {
              return
            }
            this.changeAnimation(this.animationType, true)
            modelContainer.add(tgtObj)
            scene.add(modelContainer)
            this.isRendering = false
            console.log('rendering done')
            clearInterval(interval)
          }, 500)

          // 切り替え用のアニメーションを読み込んで、イベントを設定する
          if (this.animations.length) {
            mixer = new THREE.AnimationMixer(tgtObj)
            if (!this.isLoadAnimation) {
              this.isLoadAnimation = true
              document.getElementsByClassName('animation-99')[0].addEventListener('click', () => this.changeAnimation(99), false)
              for (let i = 0; i < this.animations.length; i++) {
                let fbxLoader = new FBXLoader()
                fbxLoader.load(
                  this.animations[i].src,
                  (object) => {
                    object.rotation.set(0, 0, 0);
                    console.log('loaded:', this.animations[i].value)
                    this.animations[i].animationClip = object.animations[0]
                    let tgtElem = document.getElementsByClassName(`animation-${i}`)[0]
                    tgtElem.addEventListener('click', () => this.changeAnimation(i), false)
                    this.animations[i].isLoaded = true
                  },
                  () => {
                  },
                  (error) => {
                    console.log(error)
                  }
                )
              }
            }
          }
        },
        () => {
        },
        (err) => {
          this.isRendering = false
          if (this.renderingCount < 10) this.renderModel()
          else {
            if (typeof err.xhr !== 'undefined' && typeof err.xhr.response !== 'undefined' && err.xhr.response !== '') {
              const errJson = JSON.parse(err.xhr.response)
              console.log(errJson)
            }
            this.$router.push({ name: 'error' })
          }
          this.renderingCount++
        }
      )
      const canvasSizeFunc = this.calcCanvasWidth
      this.onWindowResize = function() {
        // サイズを取得
        let widthTmp = canvasSizeFunc()
        // レンダラーのサイズを調整する
        renderer.setPixelRatio(window.devicePixelRatio)
        renderer.setSize(widthTmp, heightFix)
        // カメラのアスペクト比を正す
        camera.aspect = widthTmp / heightFix
        camera.updateProjectionMatrix()
        animate()
      }
      window.addEventListener('resize', this.onWindowResize)

      this.changeAnimation = function(tgtAnimationType, force=false) {
        if (!force && this.animationType === tgtAnimationType) return
        if (!mixer) return
        // 位置を調整
        let modelPos = this.tposeSettings.modelPos
        let modelRot = this.tposeSettings.modelRot
        let firstPosOffset = this.tposeSettings.firstPosOffset
        if (tgtAnimationType !== 99) {
          const modelSettings = this.animations[tgtAnimationType]
          modelPos = modelSettings.modelPos
          modelRot = modelSettings.modelRot
          firstPosOffset = modelSettings.firstPosOffset
        }
        modelContainer.position.set(modelPos[0], modelPos[1], modelPos[2])
        modelContainer.rotation.set(modelRot[0], modelRot[1], modelRot[2])
        camera.position.set(firstCameraPosX + firstPosOffset[0], firstCameraPosY + firstPosOffset[1], firstCameraPosZ + firstPosOffset[2])
        controls.target.set(firstTgtX + firstPosOffset[0], firstTgtY + firstPosOffset[1], firstTgtZ + firstPosOffset[2])
        // ポーズを変更
        if (mixer._actions[0]) {
          const tgtClip = mixer._actions[0].getClip()
          mixer.clipAction(tgtClip).stop()
          mixer.uncacheClip(tgtClip)
        }
        if (tgtAnimationType !== 99) {
          mixer.clipAction(this.animations[tgtAnimationType].animationClip).play()
        }
        // どのポーズが選択中が分かるようにするためにclassを付与する
        const currentElem = document.getElementsByClassName(`animation-${this.animationType}`)[0]
        const tgtElem = document.getElementsByClassName(`animation-${tgtAnimationType}`)[0]
        currentElem.classList.remove('activate')
        tgtElem.classList.add('activate')
        if (!force) console.log('animation type:', this.animationType, '->', tgtAnimationType)
        this.animationType = tgtAnimationType
      }

      // ボタンイベントを設定 - 顔をアップにする
      const faceElem = document.getElementsByClassName('face-zoom-in')[0]
      faceElem.addEventListener('click', () => {
        let facePosOffset = this.tposeSettings.facePosOffset
        let faceRot = this.tposeSettings.faceRot
        if (this.animationType !== 99) {
          const modelSettings = this.animations[this.animationType]
          facePosOffset = modelSettings.facePosOffset
          faceRot = modelSettings.faceRot
        }
        camera.position.set(facePosX + facePosOffset[0], facePosY + facePosOffset[1], facePosZ + facePosOffset[2])
        controls.target.set(faceTgtX + facePosOffset[0], faceTgtY + facePosOffset[1], faceTgtZ + facePosOffset[2])
        modelContainer.rotation.set(faceRot[0], faceRot[1], faceRot[2])
      })
      // ボタンイベントを設定 - 全身を表示する
      const bodyElem = document.getElementsByClassName('full-body-display')[0]
      bodyElem.addEventListener('click', () => {
        let modelRot = this.tposeSettings.modelRot
        let firstPosOffset = this.tposeSettings.firstPosOffset
        if (this.animationType !== 99) {
          const modelSettings = this.animations[this.animationType]
          modelRot = modelSettings.modelRot
          firstPosOffset = modelSettings.firstPosOffset
        }
        modelContainer.rotation.set(modelRot[0], modelRot[1], modelRot[2])
        camera.position.set(firstCameraPosX + firstPosOffset[0], firstCameraPosY + firstPosOffset[1], firstCameraPosZ + firstPosOffset[2])
        controls.target.set(firstTgtX + firstPosOffset[0], firstTgtY + firstPosOffset[1], firstTgtZ + firstPosOffset[2])
      })

      // スクロールイベントを設定
      if (this.isTargetChangeTextures) {
        let interval = setInterval(() => {
          if (!this.$refs.scroll) return
          this.$refs.scroll.addEventListener('scroll', this.handleScroll)
          clearInterval(interval)
        }, 500)
      }

      const clock = new THREE.Clock()
      function animate() {
        requestAnimationFrame(animate)
        controls.update()
        if (mixer) mixer.update(clock.getDelta())
        render()
      }
      function render() {
        renderer.render(scene, camera)
      }

      animate()
    },
    ...mapActions(['getRequest', 'isAdmin']),
  },
  watch: {
    loadStatus(status) {
      if (status === 2) {
        let targetIndex = 0
        if (this.kind === 'meta') {
          this.outfitTypes = outfitTypeOptions.detail[this.gender].items
          for (const type of this.outfitTypes) {
            if (this.projects.outfit_allow_list[this.gender].includes(type.value)) {
              this.outfitType = type.value
              break
            }
          }
          targetIndex = outfitTypeToIndex[this.gender][this.outfitType]
        }
        this.outfit_str = this.outfit_strs[targetIndex]
        this.s3_avatar_url = this.s3_avatar_urls[targetIndex]
        this.s3_hair_url = this.s3_hair_urls[targetIndex]
        this.s3_body_url = this.s3_body_urls[targetIndex]
        this.s3_outfit_url = this.s3_outfit_urls[targetIndex]
        this.s3_outfit_normal_map_url = this.s3_outfit_normal_map_urls[targetIndex]
        this.genderText = genderOptionsMap[this.kind][this.gender]
        this.outfitText = outfitOptionsMap[(this.kind === 'meta') ? this.gender : 'mobile'][this.outfit_str]
        this.formatText = formatOptionsMap[this.org_export_format]
        this.lodText = lodSimpleOptionsMap[(this.adminLod) ? 'admin' : 'customer'][this.lod.toString()]
        this.removeSmileText = removeSmileOptionsMap[this.removeSmile.toString()]
        if (!this.admin) {
          this.outfits.male = this.outfits.male.filter(o => !adminOnlyOutfits.male.includes(o.value))
          this.outfits.female = this.outfits.female.filter(o => !adminOnlyOutfits.female.includes(o.value))
          this.outfits.toppan_male = this.outfits.toppan_male.filter(o => !adminOnlyOutfits.toppan_male.includes(o.value))
          this.outfits.toppan_female = this.outfits.toppan_female.filter(o => !adminOnlyOutfits.toppan_female.includes(o.value))
        }
        // アバターをレンダリング
        this.renderModel()
      }
    },
    isNormalMap() {
      this.renderModel()
    },
  },
  async created() {
    this.axios.get(`/api/data/${this.uuid}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (response.data.status === 'NG') {
        let message = '有効期限が切れています。再度アバターを作成してください'
        if (this.isViewPage) {
          message = '有効期限が切れています。担当者へご一報ください。'
        }
        this.$router.push({
          name: 'error',
          params: { message: message },
        })
        return
      }
      // レスポンスが200の時の処理
      this.image_ext = response.data.image_ext
      this.gender = response.data.gender
      this.outfit_strs = response.data.outfit_strs
      this.org_export_format = response.data.org_export_format
      this.export_format = response.data.export_format
      this.lod = response.data.lod
      this.removeSmile = response.data.remove_smile
      this.height = parseInt(response.data.height)
      this.kind = response.data.kind
      this.s3_avatar_urls = response.data.avatar_urls
      this.s3_hair_urls = response.data.avatar_hair_urls
      this.s3_body_urls = response.data.avatar_body_urls
      this.s3_outfit_urls = response.data.avatar_outfit_urls
      this.s3_outfit_normal_map_urls = response.data.avatar_outfit_normal_map_urls
      if (this.kind === 'meta') {
        this.tposeSettings = tposeSettings[this.gender]
        this.animations = animationSettings[this.gender]
        this.animationType = 0
      } else {
        this.tposeSettings = tposeSettings.mobile
      }
      this.outfits = outfitOptions
      this.loadStatus++
    })
    .catch((err) => {
      this.isRendering = false
      if (this.renderingCount < 10) this.renderModel()
      else {
        if (typeof err.xhr !== 'undefined' && typeof err.xhr.response !== 'undefined' && err.xhr.response !== '') {
          const errJson = JSON.parse(err.xhr.response)
          console.log(errJson)
        }
        this.$router.push({ name: 'error' })
      }
      this.renderingCount++
    })
  },
  async mounted() {
    this.admin = await this.isAdmin()
    const cognitoInfo = await this.getRequest()
    const resAc = await this.axios.post('/api/get_user_account_info', cognitoInfo)
    this.account_manage_info = resAc.data
    if (!this.account_manage_info.item) this.account_manage_info.item = {}
    const resPjt = await this.axios.get(`/api/get_project_info?project_name=${cognitoInfo.role.replace('customer:', '')}`)
    this.projects = resPjt.data.item
    this.adminLod = this.projects.lod_allow_list.some(l => !['0', '5', '7'].includes(l))
    this.loadStatus++
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize)
    if (this.$refs.scroll) this.$refs.scroll.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped>
.pane-container {
  position: absolute;
  right: -85px;
  width: 200px;
}
.pane-container-no-image {
  position: absolute;
  right: -37px;
  width: 200px;
}
.process2 {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  text-shadow: 0 0 6px black, 0 0 6px black, 0 0 6px black;
}
.process2 img {
  width: 150px;
}
.process2 p {
  margin-top: 10px;
}
.uk-card-header {
  border-bottom: 3px dashed #E5E5DC;
  width: 900px;
  margin: 0 auto;
}
.uk-card-title {
  font-size: 32px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-style: bold;
  text-shadow: 0 0 6px black;
}
.uk-checkbox {
  inline-size: 1.5em;
  block-size: 1.5em;
  border-color: #00C8C4;
  border-radius: 7px;
  box-shadow: -1px -1px 1px rgba(0, 200, 196, 0.7) inset;
  margin-top: 0;
}
.uk-checkbox:checked {
  background-color: #00C8C4;
  box-shadow: 1px 1px 1px rgba(123, 124, 146, 0.5) inset;
}
.uk-panel-scrollable {
  resize: none;
}
#renderCanvas {
  touch-action: none;
  min-height: 70vh;
}
#renderCanvas canvas {
  margin-left: auto;
  margin-right: auto;
}
#canvas-parent {
  position: relative;
  margin: 0;
  padding: 0;
}
.canvas-child {
  position: absolute;
}
/* left */
.canvas-child.left {
  width: 20%;
  top: 20px;
  left: 10px;
}
.animation-button {
  color: #7B7C92;
  font-size: 20px;
  font-weight: bold;
  border: 2px solid #7B7C92;
  box-shadow: -2px -2px 2px rgba(123, 124, 146, 0.5) inset;
  padding: 1px;
}
.animation-button.activate {
  color: #FFFFFF;
  background-color: #00C8C4;
  box-shadow: 2px 2px 2px rgba(123, 124, 146, 0.5) inset;
}
.controller-button {
  color: #7B7C92;
  border: 2px solid #7B7C92;
  padding: 5px;
  box-shadow: 0 2px 2px rgba(123, 124, 146, 0.5);
}
.controller-button:active {
  box-shadow: none;
  -webkit-transform: translateY(2px);
	-ms-transform: translateY(2px);
  transform: translateY(2px);
}
.controller-button img {
  object-fit: contain;
}
.normalmap-label {
  font-size: 14px;
  text-align: center;
}
.normalmap-notice {
  text-align: center;
}
.operation-manual {
  color: #7B7C92;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}
.operation-manual a {
  color: #7B7C92;
  text-decoration: underline;
}
/* center-right */
.canvas-child.center-right {
  width: 15%;
  height: 100%;
  right: 8%;
}
.canvas-child.center-right img {
  box-sizing: border-box;
  border: 3px solid transparent;
}
.canvas-child.center-right img.activate {
  border-image: repeating-linear-gradient(135deg, red 0px 9px, orange 20px 29px, yellow 40px 49px, green 60px 69px, lightblue 80px 89px, blue 100px 109px, purple 120px 129px, red 140px) 5;
}
.outfit-container {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #FFFFFF, rgba(0, 200, 196, 0.3), #FFFFFF);
  border: none;
}
.scroll-button {
  position: absolute;
  width: 100%;
  height: 8%;
  color: #7B7C92;
  background: rgba(246, 246, 246, 0.7);
  z-index: 1;
}
.scroll-button span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-top: 3px solid #7B7C92;
  border-right: 3px solid #7B7C92;
}
.scroll-up {
  top: 0;
}
.scroll-down {
  bottom: 0;
}
.scroll-up span::before {
  -webkit-transform: translate(-50%, -25%) rotate(-45deg);
  -ms-transform: translate(-50%, -25%) rotate(-45deg);
  transform: translate(-50%, -25%) rotate(-45deg);
}
.scroll-down span::before {
  -webkit-transform: translate(-50%, -75%) rotate(135deg);
  -ms-transform: translate(-50%, -75%) rotate(135deg);
  transform: translate(-50%, -75%) rotate(135deg);
}
/* right */
.canvas-child.right {
  width: 8%;
  height: 100%;
  right: 0;
  background-color: #F6F6F6;
}
.canvas-child.right .first-item {
  padding: 20px 10px;
}
.canvas-child.right img {
  object-fit: contain;
}
.canvas-child.right p {
  color: #7B7C92;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
.canvas-child.right hr {
  height: 2px;
  background-color: #7B7C92;
  border: 0;
  text-align: center;
}
.outfittype-button {
  color: #7B7C92;
  padding: 5px;
  border-color: transparent;
  box-shadow: 2.5px 2.5px 1px #DFE6E8, -2.5px -2.5px white;
}
.outfittype-button.activate {
  background-color: #00C8C4;
  box-shadow: 2.5px 2.5px white, -2.5px -2.5px 1px #ACDCDF;
}
.outfittype-button img {
  object-fit: contain;
}
.outfittype-button:disabled {
  cursor: default;
}
/* アバターローディング */
.rendering-loader {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rendering-loader div {
  width: 20px;
  height: 20px;
  float: left;
  display: flex;
  justify-content: center;
  align-content:  center;
  align-items: center;
  position: relative;
}
.rendering-loader span {
  position: absolute;
  display: block;
  border-radius: 3px;
  animation: anim 1.2s infinite;
  animation-timing-function: linear;
}
@keyframes anim {
  0% { width: 0px; height: 0px; background: blue; }
  40% { width: 15px; height: 15px; background: #00C8C4; }
  80% { width: 0px; height: 0px; background: blue; }
}
.rendering-loader div:nth-child(2) span, .rendering-loader div:nth-child(4) span {
  animation-delay: 0.15s;
}
.rendering-loader div:nth-child(3) span, .rendering-loader div:nth-child(5) span, .rendering-loader div:nth-child(7) span {
  animation-delay: 0.30s;
}
.rendering-loader div:nth-child(6) span, .rendering-loader div:nth-child(8) span {
  animation-delay: 0.45s;
}
.rendering-loader div:nth-child(9) span {
  animation-delay: 0.60s;
}
/* レスポンシブ */
@media only screen and (max-width: 1025px) {
  .uk-card-title {
    font-size: 26px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 770px) {
  .display-pc {
    display: none;
  }
  .uk-card-title {
    font-size: 20px;
  }
  .uk-card-header {
    width: 100% !important;
  }
  .normalmap-notice {
    font-size: 12px;
    text-align: center;
  }
  .outfittype-button {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (min-width: 770px) {
  .display-sp {
    display: none;
  }
  .normalmap-label {
    color: #00C8C4;
    font-weight: bold;
    line-height: 1.2;
  }
  .normalmap-notice {
    color: #7B7C92;
    font-size: 10px;
    font-weight: bold;
  }
  .outfittype-button {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 640px) {
  .process2 {
    font-size: 14px;
  }
  .process2 img {
    width: 100px;
  }
  .animation-button {
    font-size: 12px;
  }
  .controller-button {
    width: 40px;
    height: 40px;
  }
  .canvas-child.right hr {
    width: 100%;
  }
  .outfittype-button {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (min-width: 640px) {
  .controller-button {
    width: 50px;
    height: 50px;
  }
  .canvas-child.right hr {
    width: 60%;
  }
}
@media only screen and (max-width: 450px) {
  .pane-container {
    right: 5px;
    top: 85vh;
  }
  .pane-container-no-image {
    right: 5px;
    top: 57vh;
  }
}
@media only screen and (max-width: 350px) {
  .uk-card-title {
    font-size: 16px;
  }
}
</style>
