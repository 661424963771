<script setup>
    import {
      Authenticator,
      AuthenticatorForceNewPasswordFormFields,
    } from "@aws-amplify/ui-vue";
    import "@aws-amplify/ui-vue/styles.css";

    import awsconfig from '../aws-exports'
    import Amplify, { Auth } from 'aws-amplify';
    import * as AmplifyModules from 'aws-amplify'
    import { vocabularies } from '../dictionary.js'

    AmplifyModules.I18n.putVocabulariesForLanguage('ja', vocabularies)
    Amplify.configure(awsconfig);
    Auth.configure(awsconfig)

</script>

<template>
  <div class="auth">
    <div class="wrapper">
      <div class="auth-logo">
        <img alt="MeAvatar ロゴ" src="@/assets/img/logo.png"/>
      </div>
      <authenticator :hideSignUp="true">
        <template v-slot:confirm-reset-password-header>
          <p class="password-caution">
            <div>メールに送られているコードを入力し、新しいパスワードを登録ください。</div>
            <div>※ 大文字・小文字・数字・記号を含む 8 文字以上</div>
          </p>
        </template>
        <template v-slot:force-new-password-form-fields>
          <authenticator-force-new-password-form-fields />
          <p class="password-caution">※ 大文字・小文字・数字・記号を含む 8 文字以上</p>
        </template>
      </authenticator>
    </div>
  </div>
</template>

<style>
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
[data-amplify-authenticator] {
  transform: scale(1.5);
}
.auth-logo{
  background-color: #bbb;
  position: relative;
  top: -50px;
  width: 450px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px){
  .auth-logo{
    top: 0px;
    width: 100% !important;
    height: 80px;
  }
  [data-amplify-authenticator] {
    transform: scale(1.0);
    width: 100% !important;
  }
  [data-amplify-container]{
    width: 100% !important;
  }
  .wrapper{
    max-width: 305px !important;
    overflow:hidden;
  }
}
.amplify-alert{
  font-size: 0.5em;
}
.password-caution{
  color: var(--amplify-colors-font-secondary);
  font-size: var(--amplify-font-sizes-small);
  margin-bottom: 6px;
  padding: 0 2px;
}
</style>
