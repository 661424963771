<template>
  <div class="dot bg-extend">
    <div class="black"></div>
    <div class="bg-shade bg-extend">
      <div class="kv" style="height:635px!important;">
        <div class="uk-container" style="padding-bottom:30px;">
          <div class="table-responsive" style="margin-top:30px;">
            <table class="table">
              <thead>
                <tr>
                  <th>プロジェクト名</th>
                  <th>作成日</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(project, index) in sortedProjects">
                  <tr :class="['pointer', index % 2 == 0 ? 'even' : 'odd',]" @click="routeProjectEdit(project.id)">
                    <td>{{ project.name }}</td>
                    <td>{{ project.created_at }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="btn-wrapper mt-3">
            <router-link to="/" class="btn no" style="width: 215px;text-align: center;">トップへ戻る</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectList',
  components: {
  },
  data () {
    return {
      projects: [],
    }
  },
  computed: {
    sortedProjects() {
      return this.projects.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at)
      })
    },
  },
  methods: {
    routeProjectEdit(projectID) {
      this.$router.push({
        name: 'projectEdit',
        params: { projectID: projectID },
      })
    },
    async refresh() {
      try {
        const resPjt = await this.axios.get('/api/get_project_info')
        this.projects = resPjt.data.item
        this.projects = this.projects.filter(p => !p.disabled_at)
      } catch (error) {
        console.error(error)
      }
    },
  },
  mounted () {
    this.refresh()
  },
}
</script>

<style scoped>
.btn.selected {
  background-color: rgb(204,204,204);
  border: 1px solid white;
  color: rgb(32,32,32);
}
.message-wrapper p {
  margin-bottom: 12px;
}
.input-wrapper {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-title {
  font-size:1.3em;
  font-weight: bold;
}
.form-wrapper {
  width: 80%;
}
.form-control {
  background-color: #333333;
  border-radius: 15px;
  min-height: 65px;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 0px 40px 0px 65px;
  width: 80%;
  font-size: 22px;
  box-sizing: border-box;
}
.error-wrapper {
  border: 3px solid white;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: rgba(0,0,0,0.6);
}
.error {
  color: white;
}
.create_btn button::before {
  content: "";
  background: none;
  width: 0 !important;
}
.btn-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width:100%;
}
@media (max-width: 767px){
  .input-wrapper {
    display: block;
    width: 100%;
  }
  .form-wrapper {
    width: 100%;
  }
  .form-control {
    display: block;
    width: 100%;
  }
  .uk-container {
    padding-bottom: 30px;
    overflow: hidden;
  }
  .btn.yes {
    font-size: 0.95em;
  }
}
tr.active {
  display: table-row !important;
}
.tab-wrapper {
  display: inline-flex;
  justify-content: left;
  align-items: center;
  margin: 30px 0 15px 0;
}
.tab-wrapper .tab {
    margin-right: 15px;
}
.table-responsive {
  max-width: 100%;
  overflow-x: auto;
}
.table {
  color: white;
  width: 99%;
  text-align: center;
  overflow-y: auto;
  max-height: 100%;
  min-width: 1200px;
}
.table th {
  background-color: #333;
}
.table tr.even {
  background-color: #666;
}
.table tr.odd {
  background-color: #7a7a7a;
}
.table tr {
  border-top: 1px solid #666;
}
.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.table tr th, .table tr td {
  padding: 10px 0;
}
.table tr.pointer {
  cursor: pointer;
}
</style>
