<template>
  <div id="dot-bg" class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div id="kv-bg" class="kv kv-top-contain">
        <div class="uk-container">
          <div class="logo-plane uk-margin-bottom"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
          <p class="anime-title uk-margin-top">3Dアバターアニメーションデモ</p>

          <div class="uk-grid-small uk-flex uk-flex-middle uk-flex-center" uk-grid>
            <div id="canvas-parent" class="uk-width-4-5 uk-margin-small-top">
              <div id="renderCanvas"></div>
            </div>
          </div>

          <div v-if="isSmartPhone">
            <div class="uk-margin uk-margin-top uk-text-center" uk-grid>
              <div class="uk-width-1-2">
                <button class="uk-button uk-button-default flat-button" @click="reRenderModel(0)">デモ1</button>
                <button class="uk-button uk-button-default uk-margin-top flat-button" @click="reRenderModel(2)">デモ3</button>
              </div>
              <div class="uk-width-1-2">
                <button class="uk-button uk-button-default flat-button" @click="reRenderModel(1)">デモ2</button>
                <button class="uk-button uk-button-default uk-margin-top flat-button" @click="reRenderModel(3)">デモ4</button>
              </div>
              <div class="uk-width-1-2">
                <button class="uk-button uk-button-default flat-button" @click="reRenderModel(4)">デモ5</button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="uk-margin uk-margin-top uk-flex uk-flex-middle uk-flex-center">
              <button class="uk-button uk-button-default uk-margin-right flat-button" @click="reRenderModel(0)">デモ1</button>
              <button class="uk-button uk-button-default uk-margin-right flat-button" @click="reRenderModel(1)">デモ2</button>
              <button class="uk-button uk-button-default uk-margin-right flat-button" @click="reRenderModel(2)">デモ3</button>
              <button class="uk-button uk-button-default uk-margin-right flat-button" @click="reRenderModel(3)">デモ4</button>
              <button class="uk-button uk-button-default flat-button" @click="reRenderModel(4)">デモ5</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
// import Stats from 'three/examples/jsm/libs/stats.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { Sky } from 'three/examples/jsm/objects/Sky.js';

// import {Pane} from 'tweakpane';

export default {
  name: 'Animation',
  data () {
    return {
      targetIndex: 0,
      modelPaths: [
        '/animated_avatars/demo/walking',
        '/animated_avatars/demo/golf',
        '/animated_avatars/demo/dance',
        '/animated_avatars/demo/fight',
        '/animated_avatars/demo/fight2',
      ],
      hairOpacitys: [12.0, 10.0, 8, 50.0, 10.0],
      // bodyOpacitys: [1.0, 1.0, 1.0, 1.0,  1.0],
      // hemiLights: [1.5, 1.5, 2.5, 2.0, 3.0],
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onWindowResize() {
    },
    calcCanvasWidth() {
      const parent = document.getElementById("renderCanvas");
      let width = parent.clientWidth;
      return width;
    },
    renderModel() {
      const modelBasePath = this.modelPaths[this.targetIndex]
      let modelUrl = modelBasePath + '/model.fbx';
      let hairOpacity = this.hairOpacitys[this.targetIndex];
      // let bodyOpacity = this.bodyOpacitys[this.targetIndex];
      // let hemiLightVal = this.hemiLights[this.targetIndex];
      let hemiLightVal = 1.5
      // let camera, scene, renderer, stats;
      let camera, scene;
      const clock = new THREE.Clock();
      let mixer;
      let renderer = new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
      let widthFix = Math.floor( window.innerWidth * 0.9 );
      let heightFix = Math.floor( window.innerHeight * 0.7 );
      const canvasSizeFunc = this.calcCanvasWidth;
      let sky, sun;

      init();
      animate();

      function initSky() {
        // Add Sky
        sky = new Sky();
        sky.scale.setScalar( 450000 );
        scene.add( sky );

        sun = new THREE.Vector3();

        /// GUI

        const effectController = {
          turbidity: 8,
          rayleigh: 0.4,
          mieCoefficient: 0.003,
          mieDirectionalG: 0.5,
          elevation: 1,
          azimuth: 180,
          exposure: renderer.toneMappingExposure
        };

        function guiChanged() {

          const uniforms = sky.material.uniforms;
          uniforms[ 'turbidity' ].value = effectController.turbidity;
          uniforms[ 'rayleigh' ].value = effectController.rayleigh;
          uniforms[ 'mieCoefficient' ].value = effectController.mieCoefficient;
          uniforms[ 'mieDirectionalG' ].value = effectController.mieDirectionalG;

          const phi = THREE.MathUtils.degToRad( 90 - effectController.elevation );
          const theta = THREE.MathUtils.degToRad( effectController.azimuth );

          sun.setFromSphericalCoords( 1, phi, theta );

          uniforms[ 'sunPosition' ].value.copy( sun );

          renderer.toneMappingExposure = effectController.exposure;
          renderer.render( scene, camera );

        }

        // const gui = new GUI();
        //
        // gui.add( effectController, 'turbidity', 0.0, 20.0, 0.1 ).onChange( guiChanged );
        // gui.add( effectController, 'rayleigh', 0.0, 4, 0.001 ).onChange( guiChanged );
        // gui.add( effectController, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( guiChanged );
        // gui.add( effectController, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( guiChanged );
        // gui.add( effectController, 'elevation', 0, 90, 0.1 ).onChange( guiChanged );
        // gui.add( effectController, 'azimuth', - 180, 180, 0.1 ).onChange( guiChanged );
        // gui.add( effectController, 'exposure', 0, 1, 0.0001 ).onChange( guiChanged );
        //
        guiChanged();
      }

      function init() {
        const container = document.getElementById("renderCanvas");
        if (!container) { return }

        container.innerHTML = '';
        camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );
        camera.position.set( -50, 150, 320 );

        scene = new THREE.Scene();
        const helper = new THREE.GridHelper( 10000, 2, 0xffffff, 0xffffff );
        scene.add( helper );
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 0.5;

        // scene.background = new THREE.Color( 0xa0a0a0 );
        // scene.fog = new THREE.Fog( 0xa0a0a0, 200, 1000 );

        const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, hemiLightVal);
        hemiLight.position.set( 0, 200, 0 );
        scene.add( hemiLight );

        const dirLight = new THREE.DirectionalLight( 0xffffff );
        dirLight.position.set( 0, 200, 100 );
        dirLight.castShadow = true;
        dirLight.shadow.camera.top = 180;
        dirLight.shadow.camera.bottom = - 100;
        dirLight.shadow.camera.left = - 120;
        dirLight.shadow.camera.right = 120;
        scene.add( dirLight );

        // ground
        // const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 2000, 2000 ), new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
        // mesh.rotation.x = - Math.PI / 2;
        // mesh.receiveShadow = true;
        // scene.add( mesh );

        // const grid = new THREE.GridHelper( 2000, 20, 0x000000, 0x000000 );
        // grid.material.opacity = 0.2;
        // grid.material.transparent = true;
        // scene.add( grid );

        const controls = new OrbitControls( camera, renderer.domElement );
        controls.enableDamping = true;
        controls.dampingFactor = 0.2;
        controls.target.set( 0, 90, 0 );
        controls.update();

        // mixamoにfbxのみをアップロードした場合の処理
        // 外部テクスチャを参照する場合、URLを書き換える
        const manager = new THREE.LoadingManager();
        manager.setURLModifier( ( url ) => {
          if (url.match(/Users/)) {
            let urlList = url.split('/');
            return modelBasePath + '/'  + urlList[urlList.length - 1];
          }
          return url;
        } );

        const loader = new FBXLoader(manager);
        loader.load( modelUrl, function ( object ) {
          // // 髪透過
          mixer = new THREE.AnimationMixer( object );
          const action = mixer.clipAction( object.animations[ 0 ] );
          action.play();
          object.traverse( function ( child ) {
            if ( child.isMesh ) {
              if (child.name === 'haircut_generated' || child.material.name === 'generated.png') {
                child.renderOrder = 2;
                child.material.opacity = hairOpacity;
                child.material.transparent = true;
              } else if (child.name === 'mesh' || child.material.name === 'model.jpg' || child.material.name === 'model.png') {
                child.renderOrder = 1;
                child.material.opacity = 1;
                child.material.transparent = false;
              } else {
                child.material.transparent = false;
                child.renderOrder = 3;
              }
              child.material.needsUpdate = true;
              child.needsUpdate = true;
              child.castShadow = true;
              child.receiveShadow = true;
            }
          } );
          scene.add( object );
          // サイズを再セット
          let widthTmp = canvasSizeFunc();
          renderer.setSize(widthTmp, heightFix);
          camera.aspect = widthTmp / heightFix;
          camera.updateProjectionMatrix();
        } );

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(widthFix, heightFix);
        camera.aspect = widthFix / heightFix;
        camera.updateProjectionMatrix();
        renderer.shadowMap.enabled = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        container.appendChild( renderer.domElement );
        initSky();
      }

      this.onWindowResize = function() {
        // サイズを取得
        let widthTmp = canvasSizeFunc();
        // レンダラーのサイズを調整する
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(widthTmp, heightFix);
        // カメラのアスペクト比を正す
        camera.aspect = widthTmp / heightFix;
        camera.updateProjectionMatrix();
        animate()
      }
      window.addEventListener('resize', this.onWindowResize, false)

      function animate() {
        requestAnimationFrame( animate );
        const delta = clock.getDelta();
        if ( mixer ) mixer.update( delta );
        renderer.render( scene, camera );
        // stats.update();
      }
    },
    reRenderModel(index) {
      this.targetIndex = index;
      this.renderModel();
    }
  },
  mounted() {
    this.renderModel();
  },
  updated() {
    this.renderModel();
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize, false);
  }
}
</script>
<style scoped>
  .anime-title {
    font-size: 28px;
    color: #fff;
    text-align: center;
  }
  @media only screen and (max-width: 1025px) {
    .anime-title {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: 770px) {
    .anime-title {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 350px) {
    .anime-title {
      font-size: 14px;
    }
  }
  #canvas-parent {
    overflow: hidden;
    margin:0;
    padding: 0;
  }
  #renderCanvas {
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    touch-action: none;
    border: 36px solid rgba(255, 255, 255, .3);
    border-top: none;
    border-bottom: none;
  }
  @media only screen and (max-width: 770px) {
    #renderCanvas {
      border-width: 20px;
    }
  }
  @media only screen and (max-width: 450px) {
    #renderCanvas {
      border-width: 12px;
    }
  }
</style>
