<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv kv-top-contain">
        <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
        <div class="uk-container">
          <!-- 顔が検出できない場合 -->
          <template v-if="errorType == '400' && errorSummary == 'Face Not Detected'">
            <div class="image-error-container">
              <div class="error-background">
                <p v-html="errorMessage" class="image-error-message"></p>
                <div class="image-wrapper">
                  <div class="ok-example">
                    <div class="ok"><img src="@/assets/img/good1.png"/></div>
                  </div>
                  <div class="bad-example">
                    <div class="bad first"><img src="@/assets/img/bad1.png"/></div>
                    <div class="bad"><img src="@/assets/img/bad2.png"/></div>
                    <div class="bad"><img src="@/assets/img/bad3.png"/></div>
                    <div class="bad first"><img src="@/assets/img/bad4.png"/></div>
                    <div class="bad"><img src="@/assets/img/bad5.png"/></div>
                    <div class="bad"><img src="@/assets/img/bad6.png"/></div>
                  </div>
                </div>
                <div class="message-wrapper">
                  <div class="message-title">
                    ◆必須事項...<span class="sub-message-title">「出力エラーを防ぐために必要です」</span>
                    <ul>
                      <ol>正面を向く</ol>
                      <ol>目をつむらない</ol>
                      <ol>激しく動かない</ol>
                      <ol>マスク、帽子、サングラスなどの首より上の着衣・装飾品は外す<span class="sub-message-title">※イヤリングなどの小物はOK</span></ol>
                    </ul>
                  </div>
                  <div class="message-title">
                    ◆ワンポイント...<span class="sub-message-title">「品質の低下を防ぐためのポイントです」</span>
                    <ul>
                      <ol>カメラ目線</ol>
                      <ol>不自然な表情は作らない</ol>
                      <ol>フレームの太いメガネの着用は避ける</ol>
                      <ol>長髪は肩にかけずに、後ろでまとめるか後ろに流す</ol>
                      <ol>顔全体に均一に光を当てる<br/><span class="sub-message-title">※顔に影や強い光が当たることを避けてください</span></ol>
                    </ul>
                  </div>
                </div>
                <div style="text-align: center;"><router-link to="/" class="uk-button uk-button-secondary flat-button" :class="isSmartPhone ? 'uk-width-1-1' : 'uk-button-large'">トップへ戻る</router-link></div>
              </div>
            </div>
          </template>
          <!-- その他 -->
          <template v-else>
            <h2 v-html="errorMessage" class="uk-margin-bottom" :class="isSmartPhone ? 'error-message-sp' : 'error-message'"></h2>
            <router-link to="/" class="uk-button uk-button-secondary flat-button" :class="isSmartPhone ? 'uk-width-1-1' : 'uk-button-large'">トップへ戻る</router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function summaryToMessage(summary) {
  if (summary === 'Face Not Detected') {
    return 'エラーが発生しました。アップロードした写真に問題がある可能性があります。<br>以下を確認して再度写真をアップロードしてください。<br>解決しない場合は、tp-metaclone@toppan.co.jp までお問合せください。'
  } else if (summary === 'Invalid Extension') {
    return '対応していないファイルです。png、jpg、jpeg、jpe をアップロードしてください。'
  } else if (summary === 'Unauthorized Request') {
    return 'タイムアウトしました。再度ログインしてください。'
  } else {
    return '予期せぬエラーが発生しました。再度お試しください。<br>それでも解決しない場合は、tp-metaclone@toppan.co.jp までお問合せください。'
  }
}

export default {
  name: 'Error',
  props: {
    errorType: String,
    errorSummary: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      errorMessage: summaryToMessage(this.errorSummary)
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) {
        return true;
      } else {
        return false;
      }
    },
  },
}
</script>
<style scoped>
  .error-message {
    margin-top: 110px;
    color: #fff;
  }
  .error-message-sp {
    color: #fff;
  }
  .image-error-container{
    display: flex;
    justify-content: center;
    margin: 30px auto 60px;
  }
  .error-background{
    margin-top: 100px;
    width: 80%;
    height: 80%;
    padding: 30px;
    background-color: rgba(255,255,255,0.8);
    border-radius: 7px;
  }
  .image-error-message{
    font-size: 1.2em;
    font-weight: bold;
    color: black;
  }
  .image-wrapper{
    width: 460px;
    margin: 15px auto;
    height: 100%;
    background-color: white;
  }
  .ok-example{
    width: 100%;
  }
  .ok{
    width: 175px;
    height: auto;
    margin: 0 auto;
  }
  .bad-example{
    width: 100%;
    height: 250px;
  }
  .bad{
    width: 135px;
    height: 98px;
    float: left;
    margin-top: 15px;
  }
  .bad{
    margin-left: 13px;
  }
  .bad.first{
    margin-left: 10px;
  }
  .bad img{
    width: 100%;
    height: 100%;
  }
  .message-wrapper{
    width: 460px;
    margin: 15px auto;
    height: 100%;
    color: rgba(0,0,0,0.7);
    padding: 15px;
  }
  .message-title{
    font-weight: bold;
  }
  .sub-message-title{
    font-size: 0.75em;
  }
  .message-wrapper ol{
    margin: 0 0 7px 25px !important;
    list-style-type: decimal !important;
    display: list-item;
  }
  @media (max-width: 767px){
    .error-background{
      width: 99%;
      padding: 15px;
    }
    .image-error-message{
      font-size: 0.9em;
    }
    .image-wrapper{
      background-color: white;
      width: 315px;
      margin: 7px auto;
    }
    .ok-example{
      width: 100%;
    }
    .ok{
      width: 175px;
      height: auto;
      margin: 0 auto;
    }
    .bad-example{
      width: 100%;
      background-color: white;
      height: 375px;
    }
    .bad{
      width: 135px;
      height: 98px;
      display: inline-block;
    }
    .bad.pc{
      margin-left: 0px;
    }
    .bad{
      margin-left: 15px !important;
    }
    .message-wrapper{
      width: 315px;
    }
    .message-title{
      font-weight: bold;
      font-size: 0.9em;
    }
    .sub-message-title{
      font-size: 0.75em;
    }
    .message-wrapper ol{
      margin: 0 0 7px 0px !important;
      list-style-type: decimal !important;
      display: list-item;
    }
  }
</style>
