<template>
  <div style="width:100%;height:100%;">
    <div class="all-wrapper">
      <div class="dropdown-wrapper" @click="isActive = !isActive">
        <div class="dropdown-text" :class="dropdownClassName">
          {{ label }}
        </div>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <transition>
        <div>
          <div class="tooltip" :class="tooltipClassName" v-if="imageSrc" @click="selectImage">
            <img :src="imageSrc" />
          </div>
          <div class="list-items" v-if="isActive">
            <div v-if="multiple" v-for="(item) in listItems">
              <div class="list-item"
                :class="[activeItemKeys.includes(item.value) ? 'active' : '', item.disabled ? 'disabled' : '']"
                @click="handleClickItem(item)" @mouseover="showImage(item)">
                {{ item.text }}
              </div>
            </div>
            <div v-else v-for="(item) in listItems">
              <div class="list-item"
                :class="[item.value == activeItemKey ? 'active' : '', item.disabled ? 'disabled' : '']"
                @click="handleClickItem(item)" @mouseover="showImage(item)">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="dropdown-bg" @click="close" v-if="isActive"></div>
  </div>
</template>
<script>
import { attrTitleSettings } from '@/assets/js/constants'

export default {
  props: {
    initialLabel: { type: String, required: true },
    listItems: { type: Object, required: true, },
    activeItemKey: { type: [String, Number], required: false },
    activeItemKeys: { type: Array, required: false },
    action: { type: Function, required: true, },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      label: this.initialLabel,
      imageSrc: null,
      item: null,
    };
  },
  computed: {
    dropdownClassName() {
      if (this.initialLabel === attrTitleSettings.gender) return 'gender'
      if (this.initialLabel === attrTitleSettings.format) return 'format'
      if (this.initialLabel === attrTitleSettings.lodAdmin || this.initialLabel === attrTitleSettings.lodCustomer) return 'lod'
      if (this.initialLabel === attrTitleSettings.removeSmile) return 'remove-smile'
      if (this.initialLabel === attrTitleSettings.option) return 'option'
    },
    tooltipClassName() {
      if (this.dropdownClassName === 'gender') return 'posting-right'
      return ''
    },
  },
  methods: {
    close() {
      this.isActive = false
      this.imageSrc = null
    },
    showImage(item) {
      if (!item.src) return
      this.imageSrc = item.src
      this.item = item
    },
    selectImage() {
      if (this.item) this.handleClickItem(this.item)
    },
    handleClickItem(item) {
      if (this.multiple) {
        if (item.disabled) return
        const index = this.activeItemKeys.findIndex((v) => v === item.value)
        if (index > -1) {
          this.activeItemKeys.splice(index, 1)
        } else {
          this.activeItemKeys.push(item.value)
        }
        this.action(this.activeItemKeys)
      } else {
        if (item.value == this.activeItemKey || item.value == 'disabled') return
        this.isActive = false
        this.label = item.text
        this.action(item.value)
        this.imageSrc = null
      }
    },
  },
}
</script>
<style>
.tooltip {
  z-index: 999;
  position: absolute;
  top: 15px;
  width: 300px;
  height: auto;
  padding: 30px;
  background-color: white;
  border: 1px solid #333;
  cursor: pointer;
}
.tooltip.posting-left {
  left: -300px;
}
.tooltip.posting-right {
  right: -300px;
}

.disabled {
  color: #777 !important;
  background-color: #333 !important;
}

.dropdown-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.all-wrapper {
  position: relative;
  height: 100%;
}

.all-wrapper .dropdown-wrapper {
  color: #666666;
  height: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.all-wrapper .dropdown-wrapper:hover {
  cursor: pointer;
}

.all-wrapper .dropdown-wrapper .dropdown-text {
  font-size: 18px;
  padding-left: 70px;
  color: white;
}

.all-wrapper .dropdown-wrapper i {
  font-size: 10px;
  margin-left: 6px;
}

.all-wrapper .list-items {
  top: 15px;
  width: 100%;
  max-height: 300px;
  background-color: #333;
  border-radius: 2px;
  border: 1px solid #000;
  position: absolute;
  right: 0;
  overflow-y: scroll;
  z-index: 3;
}

.all-wrapper .list-items .list-item {
  color: white;
  font-size: 14px;
  line-height: 16px;
  padding: 0.75rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.all-wrapper .list-items .list-item:not(.active):hover {
  background-color: #555;
  cursor: pointer;
}

.all-wrapper .list-items .list-item.active {
  color: #fff;
  background-color: #182A4B;
}

@media (max-width: 767px) {
  .tooltip {
    display: none;
  }

  .all-wrapper .dropdown-wrapper {
    min-height: 60px;
  }

  .all-wrapper .dropdown-wrapper .dropdown-text {
    padding-left: 50px;
  }
}
</style>
