<template>
  <div id="dot-bg" class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div id="kv-bg" class="kv kv-top-contain">
        <div class="uk-container">
          <div class="logo-plane uk-margin-bottom"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
          <p class="anime-title uk-margin-top">3Dアバター作成結果</p>

          <div class="uk-card uk-card-small uk-card-secondary uk-card-body">
              <h3 class="uk-card-title">本ページはお客様への共有目的のため認証を設けておりません。そのため、本ページURLを<span class="uk-text-bold">SNS等に投稿しないようお願い申し上げます</span>。</h3>
          </div>

          <div class="uk-grid-small uk-flex uk-flex-middle uk-flex-center" uk-grid>
            <div id="canvas-parent" class="uk-width-4-5 uk-margin-small-top">
              <div id="renderCanvas"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
// import Stats from 'three/examples/jsm/libs/stats.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

// import {Pane} from 'tweakpane';

export default {
  name: 'Animationview',
  data () {
    return {
      targetIndex: 0,
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onWindowResize() {
    },
    calcCanvasWidth() {
      const parent = document.getElementById("renderCanvas");
      let width = parent.clientWidth;
      return width;
    },
    renderModel() {
      let modelUrl = '/animated_avatars/view/' + this.$route.params.uuid + '/model.fbx'
      let hairOpacity = 10.0
      if (this.$route.params.uuid === '499b52ade076499dab45019f97ed0aa3') {
        hairOpacity = 45.0;
      }
      let hemiLightVal = 1.2
      // let camera, scene, renderer, stats;
      let camera, scene;
      const clock = new THREE.Clock();
      let mixer;
      let renderer = new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
      let heightFix = Math.floor( window.innerHeight * 0.7 );
      const canvasSizeFunc = this.calcCanvasWidth;

      init();
      animate();

      function init() {
        const container = document.getElementById("renderCanvas");
        container.innerHTML = '';
        camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );
        camera.position.set( -50, 150, 320 );

        let width = canvasSizeFunc();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, heightFix);
        camera.aspect = width / heightFix;
        camera.updateProjectionMatrix();
        renderer.shadowMap.enabled = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        container.appendChild( renderer.domElement );

        scene = new THREE.Scene();
        scene.background = new THREE.Color( 0xa0a0a0 );
        scene.fog = new THREE.Fog( 0xa0a0a0, 200, 1000 );

        const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, hemiLightVal);
        hemiLight.position.set( 0, 200, 0 );
        scene.add( hemiLight );

        const dirLight = new THREE.DirectionalLight( 0xffffff );
        dirLight.position.set( 0, 200, 100 );
        dirLight.castShadow = true;
        dirLight.shadow.camera.top = 180;
        dirLight.shadow.camera.bottom = - 100;
        dirLight.shadow.camera.left = - 120;
        dirLight.shadow.camera.right = 120;
        scene.add( dirLight );

        // ground
        const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 2000, 2000 ), new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
        mesh.rotation.x = - Math.PI / 2;
        mesh.receiveShadow = true;
        scene.add( mesh );

        const grid = new THREE.GridHelper( 2000, 20, 0x000000, 0x000000 );
        grid.material.opacity = 0.2;
        grid.material.transparent = true;
        scene.add( grid );

        const controls = new OrbitControls( camera, renderer.domElement );
        controls.target.set( 0, 90, 0 );
        controls.update();

        // model
        const loader = new FBXLoader();
        loader.load( modelUrl, function ( object ) {
          // // 髪透過
          mixer = new THREE.AnimationMixer( object );
          const action = mixer.clipAction( object.animations[ 0 ] );
          action.play();
          object.traverse( function ( child ) {
            if ( child.isMesh ) {
              if (child.name === 'haircut_generated' || child.material.name === 'generated.png') {
                child.renderOrder = 2;
                child.material.opacity = hairOpacity;
                child.material.transparent = true;
              } else if (child.name === 'mesh' || child.material.name === 'model.png') {
                child.renderOrder = 1;
                child.material.opacity = 1;
                child.material.transparent = false;
              } else {
                child.material.transparent = false;
                child.renderOrder = 3;
              }
              child.material.needsUpdate = true;
              child.needsUpdate = true;
              child.castShadow = true;
              child.receiveShadow = true;
            }
          } );
          scene.add( object );
        } );
        // // stats
        // stats = new Stats();
        // container.appendChild( stats.dom );
      }

      this.onWindowResize = function() {
        // サイズを取得
        let widthTmp = canvasSizeFunc();
        // レンダラーのサイズを調整する
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(widthTmp, heightFix);
        // カメラのアスペクト比を正す
        camera.aspect = widthTmp / heightFix;
        camera.updateProjectionMatrix();
        animate()
      }
      window.addEventListener('resize', this.onWindowResize, false)

      function animate() {
        requestAnimationFrame( animate );
        const delta = clock.getDelta();
        if ( mixer ) mixer.update( delta );
        renderer.render( scene, camera );
        // stats.update();
      }

      // const scene = new THREE.Scene();
      // const pane = new Pane();
      // const tab = pane.addTab({
      //   pages: [
      //     {title: 'ズーム'},
      //     {title: '移動'},
      //     {title: '回転'},
      //   ],
      // });
    },
  },
  mounted() {
    this.renderModel();
  },
  unmounted() {
    window.removeEventListener('resize', this.onWindowResize, false);
  }
}
</script>
<style scoped>
  .anime-title {
    font-size: 28px;
    color: #fff;
    text-align: center;
  }
  @media only screen and (max-width: 1025px) {
    .anime-title {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: 770px) {
    .anime-title {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 350px) {
    .anime-title {
      font-size: 14px;
    }
  }
  #canvas-parent {
    overflow: hidden;
    margin:0;
    padding: 0;
  }
  #renderCanvas {
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    touch-action: none;
    border: 36px solid rgba(255, 255, 255, .3);
    border-top: none;
    border-bottom: none;
  }
  @media only screen and (max-width: 770px) {
    #renderCanvas {
      border-width: 20px;
    }
  }
  @media only screen and (max-width: 450px) {
    #renderCanvas {
      border-width: 12px;
    }
  }
</style>
