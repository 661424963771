<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv kv-top-contain">
        <div class="uk-container">
          <div v-if="isViewPage()" class="uk-margin uk-card uk-card-small uk-card-secondary uk-card-body" style="margin-top:45px;">
            <h3 class="view-caution">本ページはお客様への共有目的のため認証を設けておりません。そのため、本ページURLを<span class="uk-text-bold">SNS等に投稿しないようお願い申し上げます</span>。</h3>
          </div>
          <div class="white-dot-bg" style="padding: 15px 0;margin: 15px 0;">
              <created-avatar :isViewPage="isViewPage()"></created-avatar>
              <div>
                <a @click="createQrCode" href="javascript:void(0);" class="uk-button uk-button-default qr-button btn yes other">
                  QRコードを表示
                </a>
                <modal v-show="isShowAlert" :isAlert="true" :modalColor="'rgb(204,204,204)'" :color="'black'" :header="'このページのQRコード'" :canceltext="'OK'" :propWidth="'315px !important'" @modal-close="isShowAlert=false">
                    <template v-slot:body>
                        <canvas id="qr-canvas"></canvas>
                    </template>
                </modal>
                <button type="button" @click="routeAnimation()" class="btn yes other mt-3">
                  アニメーションデモを確認する
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreatedAvatar from '@/components/CreatedAvatar.vue'
import Modal from '@/components/parts/Modal.vue'
import QRCode from 'qrcode'

export default {
  name: 'View',
  components: {
    CreatedAvatar,
    Modal,
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      isShowAlert: false,
    }
  },
  computed: {
    isSmartPhone() {
      if (window.matchMedia && window.matchMedia('(max-device-width: 960px)').matches) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    isViewPage() {
      if ( this.$route.path.match(/\/view/)) {
        return true;
      }
      return false;
    },
    createQrCode() {
      let w = 256;
      if (this.isSmartPhone) {
        w = 200;
      }
      QRCode.toCanvas(document.getElementById('qr-canvas'), location.href, { width: w },  (error) => {
        if (error) console.error(error);
        console.log('qr created');
        this.isShowAlert = true
      })
    },
    routeAnimation() {
      this.$router.push({ name: 'animation' });
    },
  },
}
</script>
<style scoped>
  .btn.yes.other{
      width: 450px;
      font-size: 14px;
      display: block;
      line-height: 2.5;
      margin: 0 auto;
  }
  @media (max-width: 767px){
      .btn.yes.other{
          width: 350px;
      }
  }
</style>
