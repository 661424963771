<template>
  <div class="dot bg-extend">
    <div class="black"></div>
    <div class="bg-shade bg-extend">
      <div class="kv" style="height:635px !important;">
        <div class="uk-container uk-container-large" style="padding-bottom: 30px;">
          <div class="tab-wrapper">
            <div class="tab"><button type="button" class="btn ghost" :class="{'selected': span_kind == 'future'}" @click="toggleSpanKind('future')">利用開始前</button></div>
            <div class="tab"><button type="button" class="btn ghost" :class="{'selected': span_kind == 'in'}" @click="toggleSpanKind('in')">利用中</button></div>
            <div class="tab"><button type="button" class="btn ghost" :class="{'selected': span_kind == 'out'}" @click="toggleSpanKind('out')">利用終了</button></div>
            <div class="tab"><button type="button" class="btn ghost" :class="{'selected': span_kind == 'admin'}" @click="toggleSpanKind('admin')">管理者</button></div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>メールアドレス</th>
                  <th>プロジェクト</th>
                  <th>契約形態</th>
                  <th>DL数</th>
                  <th>上限数</th>
                  <th>期間</th>
                  <th>契約期間</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(infos, index) in items">
                  <template v-for="(info, kind) in infos">
                    <template v-if="kind != 'subscription'">
                      <tr :class="{even: index % 2 == 0, odd: index % 2 == 1}">
                        <td></td>
                        <td>{{info.email}}</td>
                        <td>{{info.project}}</td>
                        <td>
                          <template v-if="info.kind == 'subscription'">サブスク</template>
                          <template v-else-if="info.kind == 'all_span'">個別契約</template>
                          <template v-else-if="info.kind == 'admin'"> 管理者 </template>
                        </td>
                        <td>{{(info.download_count || 0) + (info.meta_download_count || 0)}}</td>
                        <td v-if="info.role == 'admin'">上限なし</td>
                        <td v-else>{{info.limit}}</td>
                        <td v-if="kind == 'admin'"> - </td>
                        <td v-else-if="span_kind == 'future'"> - </td>
                        <td v-else-if="kind == 'all_span'">
                          {{ dateFormat4(new Date(info.plan_start), 0, -1) }}
                          〜
                          {{ dateFormat4(new Date(info.plan_end), -1, -1) }}
                        </td>
                        <td v-if="kind == 'admin'"> - </td>
                        <td v-else-if="kind == 'subscription'">
                          {{ dateFormat4(new Date(info.start), 0, 0) }}
                            〜
                          {{ dateFormat4(new Date(info.end), 0, 0) }}
                        </td>
                        <td v-else-if="span_kind == 'future'">
                          {{ dateFormat4(new Date(info.plan_start), 0, -1) }}
                          〜
                          {{ dateFormat4(new Date(info.plan_end), 0, -1) }}
                        </td>
                        <td v-else></td>
                        <td>
                          <button v-if="kind === 'admin'" type="button" class="btn ghost" @click.prevent.stop="setDeleteInfo(info)">削除</button>
                          <button v-else type="button" class="btn ghost" @click.prevent.stop="showEditDialog(info)">編集</button>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr v-for="(v, i) in info" :key="i" style="display: none;" :class="{active: v.visible || i==0, even: index % 2 == 0, odd: index % 2 == 1}" @click="toggle(info)">
                        <td><span v-if="info.length > 1 && i==0" style="font-size: 1.3em;font-weight: bold;margin: 0 7px;cursor: pointer;"> ▽ </span></td>
                        <td>{{v.email}}</td>
                        <td>{{v.project}}</td>
                        <td>
                          <template v-if="v.kind == 'subscription'">サブスク</template>
                        </td>
                        <td>{{(v.download_count || 0) + (v.meta_download_count || 0)}}</td>
                        <td>{{v.limit}}</td>
                        <td>
                          <div v-if="span_kind == 'future'"> - </div>
                          <div v-else>
                            {{ dateFormat4(new Date(v.start), 0, 0) }}
                            〜
                            {{ dateFormat4(new Date(v.end), 0, 0) }}
                          </div>
                        </td>
                        <!-- <td>{{v.month_str}}</td> -->
                        <td>
                          {{ dateFormat4(new Date(v.userinfo.plan_start), 0, -1) }}
                            〜
                          {{ dateFormat4(new Date(v.userinfo.plan_end_display), 0, -1) }}
                        </td>
                        <td>
                          <button v-if="i == 0" type="button" class="btn ghost" @click.prevent.stop="showEditDialog(info)">編集</button>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
          <div class="btn-wrapper mt-3">
            <router-link to="/" class="btn no" style="width: 215px;text-align: center;">トップへ戻る</router-link>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="isShowEditDialog" :donetext="'変更する'" :deletetext="'削除する'" :canceltext="'閉じる'" :propWidth="'95% !important'" @modal-done="updateUser" @modal-delete="isShowConfirm = true" @modal-close="isShowEditDialog = false">
      <template v-slot:body>
        <div class="error-wrapper" v-if="err_msg.length > 0">
          <div class="error" v-for="err in err_msg" :key="err">{{err}}</div>
        </div>
        <div class="input-wrapper">
          <span class="input-title">メールアドレス</span>
          <input type="text" class="form-control" placeholder="メールアドレス" v-model="email" disabled/>
        </div>
        <div class="input-wrapper">
          <span class="input-title">パスワード</span>
          <input type="password" class="form-control" placeholder="パスワード" value="**********" disabled/>
        </div>
        <div class="input-wrapper">
          <span class="input-title">契約形態</span>
          <template v-if="all_download_count == 0 || is_limit_over">
            <select class="form-control" v-model="kind">
              <option value="all_span">個別契約（ダウンロード数・期間を任意指定できる）</option>
              <option value="subscription">サブスクリプション契約</option>
            </select>
          </template>
          <div v-else class="form-wrapper">
            <div v-if="kind == 'all_span'" class="form-control" style="width: 100%;line-height:3;">個別契約（ダウンロード数・期間を任意指定できる）</div>
            <div v-else-if="kind == 'subscription'" class="form-control" style="width: 100%;line-height:3;">サブスクリプション契約</div>
            <div style="color:white;font-weight: bold;">ダウンロードがされている場合、契約形態は変更できません</div>
          </div>
        </div>
        <div class="input-wrapper" v-if="kind=='subscription'">
          <span class="input-title">プラン</span>
          <select class="form-control" v-model="plan">
            <option value="" disabled>プランを選択してください</option>
            <option value="plan_a">プランA（10体までアバターの作成が可能）</option>
            <option value="plan_b">プランB（30体までアバターの作成が可能）</option>
            <option value="plan_c">プランC（50体までアバターの作成が可能）</option>
          </select>
        </div>
        <div class="input-wrapper" v-if="kind=='all_span'">
          <span class="input-title">制作上限数</span>
          <input type="number" class="form-control" placeholder="制作上限数" max="100000" min="1" v-model="limit_num"/>
        </div>
        <div class="input-wrapper">
          <span class="input-title">利用開始日</span>
          <template v-if="all_download_count == 0 || is_limit_over">
            <input type="date" class="form-control" placeholder="利用開始日" v-model="plan_start"/>
          </template>
          <div v-else class="form-wrapper">
              <input type="date" class="form-control" placeholder="利用開始日" v-model="plan_start" disabled style="width: 100%;line-height:3;"/>
              <div style="color:white;font-weight: bold;">ダウンロードがされている場合、利用開始日は変更できません</div>
          </div>
        </div>
        <div class="input-wrapper" v-if="kind=='subscription'">
          <span class="input-title">利用期間</span>
          <select class="form-control" v-model="period">
            <option value="" disabled>利用期間を選択してください</option>
            <option value="1">1ヶ月</option>
            <option value="3">3ヶ月</option>
            <option value="6">6ヶ月</option>
            <option value="12">12ヶ月</option>
          </select>
        </div>
        <div class="input-wrapper" v-if="kind=='all_span'">
          <span class="input-title">利用終了日</span>
          <input type="date" class="form-control" placeholder="利用終了日" v-model="plan_end"/>
        </div>
      </template>
    </modal>
    <modal v-if="isShowConfirm" :whiteBackgroundColor="'rgba(255,255,255,0.75)'" :maxHeight="maxHeight+'px'" :opacity="'1'" :donetext="'はい'" :canceltext="'いいえ'" :propWidth="'600px !important'" @modal-done="deleteUser" @modal-close="isShowConfirm = false">
      <template v-slot:body>
        <div style="color:white;text-align:center;">
          <div style="color:rgb(61,61,61);">
            <div class="large-font">本当に削除しますか？</div>
          </div>
        </div>
      </template>
    </modal>
    <modal v-show="isShowAlert" :modalColor="'rgb(204,204,204)'" :color="'black'" :header="'アラート'" :canceltext="'OK'" :propWidth="'650px !important'" @modal-close="isShowAlert=false">
      <template v-slot:body>
        <div v-html="message" class="message-wrapper"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Auth } from 'aws-amplify'

import Modal from '@/components/parts/Modal'

function dateFormat(date, after){
  date.setMonth(date.getMonth() + after)
  var yyyy = date.getFullYear()
  var mm = ('0' + (date.getMonth()+1)).slice(-2)
  var dd = ('0' + date.getDate()).slice(-2)
  return `${yyyy}-${mm}-${dd}`
}
function dateFormat3(date, after){
  date.setDate(date.getDate() + after)
  var yyyy = date.getFullYear();
  var mm = ('0' + (date.getMonth()+1)).slice(-2)
  var dd = ('0' + date.getDate()).slice(-2)
  return `${yyyy}-${mm}-${dd}`
}
export default {
  name: 'Create User',
  components: {
    Modal,
  },
  data () {
    return {
      loginUser: '',
      user_sub: '',
      email: '',
      password: '',
      plan: '',
      limit_num: 10,
      plan_start: dateFormat(new Date(), 0),
      plan_end: dateFormat(new Date(), 1),
      period: '',
      err_msg: [],
      kind: 'all_span',  // subscription: サブスクリプション, all_span: 制作上限数設定
      isShowEditDialog: false,
      message: '',
      items: [],
      spans: {'future': {}, 'in': {}, 'out': {}, 'admin': {}},
      span_kind: 'in',  // future, in, out, admin
      isShowConfirm: false,
      isShowAlert: false,
      download_count: 0,
      all_download_count: 0,
      is_limit_over: false,
    }
  },
  methods: {
    toggleSpanKind(spanKind){
      this.span_kind = spanKind
      if (this.span_kind == 'out') {
        this.is_limit_over = true
      } else {
        this.is_limit_over = false
      }
      this.delete_trash_data()
    },
    toggle(info) {
      if (info.length == 1) return
      if (info[1].visible == null) info[1].visible = false
      const nextVisible = !info[1].visible
      for (let i=0; i<info.length; i++) {
        if (i == 0) {
          info[i].visible = true
          continue
        }
        info[i].visible = nextVisible
      }
    },
    dateFormat4(date, diff, diff_month) {
      date.setMonth(date.getMonth() + 1 + diff_month)
      date.setDate(date.getDate() + diff)
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth()+1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}/${month}/${day}`
    },
    showEditDialog(allInfo) {
      let info = null
      if (Array.isArray(allInfo)) {
        info = allInfo[allInfo.length - 1].userinfo
      } else {
        info = allInfo.userinfo
      }
      this.kind = info.kind
      if (this.kind == 'subscription') {
        const plan = info.plan
        if (plan == 10) this.plan = 'plan_a'
        else if (plan == 30) this.plan = 'plan_b'
        else if (plan == 50) this.plan = 'plan_c'
        else this.plan = 'plan_a'
        this.period = '1'
      }
      this.user_sub = info.user_sub
      this.email = info.email
      this.limit_num = info.plan
      this.plan_start = dateFormat3(new Date(info.plan_start), 0)
      this.plan_end = dateFormat3(new Date(info.plan_end), -1)

      if (allInfo.meta_download_count != undefined) {
        this.download_count = allInfo.meta_download_count + allInfo.download_count
      } else if (Array.isArray(allInfo)) {
        this.download_count = allInfo.reduce((pre,cur) => {
          pre += (cur['meta_download_count'] || 0) + (cur['download_count'] || 0)
          return pre
        }, 0)
      } else {
        this.download_count = 0
      }
      this.all_download_count = allInfo.all_download_count
      if (this.all_download_count == undefined) {
        this.all_download_count = this.download_count
      }
      this.err_msg = []
      this.isShowEditDialog = true
    },
    async updateUser(){
      let plan_end = new Date(this.plan_start)
      if (this.kind == 'subscription')  {
        plan_end = new Date(plan_end.setMonth(plan_end.getMonth() + Number(this.period))).getTime()
      } else if (this.kind == 'all_span') {
        plan_end = new Date(this.plan_end).getTime() + 24*60*60*1000
      } else {
        this.message = '入力が不正です'
        this.isShowAlert = true
        return
      }

      if (!this.validate()) return

      const params = {
        user_sub: this.user_sub,
        email: this.email,
        password: this.password,
        plan: this.plan,
        limit_num: this.limit_num,
        plan_start: new Date(this.plan_start).getTime(),
        plan_end: plan_end,
        kind: this.kind,
        role: 'customer'
      }
      try {
        await this.axios.post('/api/update_customer', params)
        this.isShowEditDialog = false
        this.message = 'ユーザー設定情報を変更しました。'
        this.isShowAlert = true
        await this.refresh()
      } catch(e) {
        console.error(e)
        this.err_msg = ["このメールアドレスは既に登録されています"]
      }
    },
    async deleteUser(){
      this.isShowConfirm = false
      const params = {
        user_sub: this.user_sub,
        email: this.email,
      }
      try {
        await this.axios.post('/api/delete_customer', params)
        this.isShowEditDialog = false
        this.message = 'ユーザー設定情報を削除しました。'
        this.isShowAlert = true
        await this.refresh()
      } catch(e) {
        console.error(e)
        this.err_msg = ["予期せぬエラーが発生しました"]
      }
      if (this.loginUser === this.user_sub) await Auth.signOut()
    },
    setDeleteInfo(info) {
      this.user_sub = info.user_sub
      this.email = info.email
      this.isShowConfirm = true
    },
    validate(){
      this.err_msg = []
      if (!this.email) { this.err_msg.push('メールアドレスを入力してください') }
      if (!this.plan && this.kind == 'subscription') { this.err_msg.push('プランを選択してください') }
      if (!this.plan_start) { this.err_msg.push('利用開始日を入力してください') }
      if (!this.period && this.kind == 'subscription') { this.err_msg.push('利用期間を選択してください') }
      if (!this.is_limit_over && this.download_count == 0 && new Date(this.plan_start).getTime() < new Date().getTime()-24*60*60*1000) { 
        this.err_msg.push('利用開始日が過去の日付になっています')
      }
      if (this.is_limit_over && new Date(this.plan_start).getTime() < new Date().getTime()-24*60*60*1000) { 
        this.err_msg.push('利用開始日が過去の日付になっています')
      }
      if (this.kind == 'all_span' && new Date(this.plan_start).getTime() > new Date(this.plan_end).getTime()) {
        this.err_msg.push('利用終了日は利用開始日より後になるように設定してください')
      }
      if (this.kind == 'all_span' && (this.limit_num < 1 || this.limit_num > 100000)) {
        this.err_msg.push('制作上限数が不正です')
      }
      this.err_msg = this.err_msg.filter((v, i, self)=>self.indexOf(v) === i)
      return this.err_msg.length == 0
    },
    async refresh(){
      try {
        const axios = this.axios
        const date = new Date()
        var yyyy = date.getFullYear()
        var mm = ('0' + (date.getMonth()+1)).slice(-2)
        this.month_str = `${yyyy}-${mm}`
        const response = Object(
          await axios.get(
            `/api/get_all_avatar_download_count?month_str=${this.month_str}`
          )
        )
        if (response.data && response.data.items) {
          this.spans = response.data.items
          this.delete_trash_data()
        }
      } catch(error) {
        console.error(error)
      }
    },
    delete_trash_data() {
      this.items = Object.keys(this.spans[this.span_kind]).filter(sub => {
        return this.spans[this.span_kind][sub]
      }).map(sub=>this.spans[this.span_kind][sub])
      this.items = this.sorted(this.items)
      this.maxHeight = window.innerHeight
    },
    sorted(info) {
      const ret = info.sort((A,B)=>{
        const a = Object.keys(A)[0]
        const b = Object.keys(B)[0]
        if (!a || !b) return 0
        if (a < b) return 1
        if (a > b) return -1
        const emailA = a == 'subscription' ? A[a][0].email : A[a].email
        const emailB = b == 'subscription' ? B[b][0].email : B[b].email
        if (emailA < emailB) return -1
        if (emailA > emailB) return 1
        return 0
      })
      for (const trg of info) {
        let all_download_count = 0
        for (const kind in trg) {
          if(kind == 'subscription'){
            all_download_count += trg[kind].reduce((pre, cur) => {
              pre += cur.meta_download_count + cur.download_count
              return pre
            }, 0)
            trg[kind] = trg[kind].reverse()
            trg[kind][0].all_download_count = all_download_count
          }
        }
      }
      return ret
    },
    ...mapActions(['getRequest']),
  },
  async mounted () {
    this.refresh()
    const cognitoInfo = await this.getRequest()
    this.loginUser = cognitoInfo.user_sub
  }
}
</script>

<style scoped>
.btn.selected {
  background-color: rgb(204,204,204);
  border: 1px solid white;
  color: rgb(32,32,32);
}
.message-wrapper p{
  margin-bottom: 12px;
}
.input-wrapper{
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-title{
  font-size:1.3em;
  font-weight: bold;
}
.form-wrapper{
  width: 80%;
}
.form-control{
  background-color: #333333;
  border-radius: 15px;
  min-height: 65px;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 0px 40px 0px 65px;
  width: 80%;
  font-size: 22px;
  box-sizing: border-box;
}
.error-wrapper{
  border: 3px solid white;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: rgba(0,0,0,0.6);
}
.error{
  color: white;
}
.create_btn button::before{
  content: "";
  background: none;
  width: 0 !important;
}
.btn-wrapper{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width:100%;
}
@media (max-width: 767px){
  .input-wrapper{
    display: block;
    width: 100%;
  }
  .form-wrapper{
    width: 100%;
  }
  .form-control{
    display: block;
    width: 100%;
  }
  .uk-container{
    padding-bottom: 30px;
    overflow: hidden;
  }
  .btn.yes{
    font-size: 0.95em;
  }
}
tr.active{
  display: table-row !important;
}
.tab-wrapper{
  display: inline-flex;
  justify-content: left;
  align-items: center;
  margin: 30px 0 15px 0;
}
.tab-wrapper .tab{
  margin-right: 15px;
}
.table-responsive{
  max-width: 100%;
  overflow-x: auto;
}
.table{
  color: white;
  width: 99%;
  text-align: center;
  overflow-y: auto;
  max-height: 100%;
  min-width: 1200px;
}
.table th{
  background-color: #333;
}
.table tr.even{
  background-color: #666;
}
.table tr.odd{
  background-color: #7a7a7a;
}
.table tr{
  border-top: 1px solid #666;
}
.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.table tr th, .table tr td{
  padding: 10px 0;
}
</style>
