<template>
  <div class="dot">
    <div class="bg-shade">
      <div class="kv">
        <div class="kv_grid">
          <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
          <div v-if="!admin" style="display: grid;">
            <div>
              <button type="button" class="btn menu" style="position: absolute;right: 15px;top: 30px;" @click="signOut">サインアウト</button>
            </div>
          </div>
          <div v-else class="menu-wrapper" :class="{'active': menuActive}">
            <div class="menu-top" @click="toggleMenu" style="color:#222;">メニュー</div>
            <div class="menu-item">
              <button v-if="isMetaPerson" type="button" @click="changeSubtype(false)" style="color:#222;">MeAvatar αを生成</button>
              <button v-else type="button" @click="changeSubtype(true)" style="color:#222;">MeAvatar βを生成する</button>
            </div>
            <div class="menu-item">
              <router-link to="/signup" style="color:#222;">ユーザー作成</router-link>
            </div>
            <div class="menu-item">
              <router-link to="/userlist" style="color:#222;">ユーザー管理</router-link>
            </div>
            <div class="menu-item">
              <router-link to="/project/create" style="color:#222;">プロジェクト作成</router-link>
            </div>
            <div class="menu-item">
              <router-link to="/project/list" style="color:#222;">プロジェクト管理</router-link>
            </div>
            <div class="menu-item">
              <button type="button" @click="signOut" style="color:#222;">サインアウト</button>
            </div>
          </div>
          <div class="back-wrapper" :class="{'active': menuActive}" @click="menuActive = false"></div>
        </div>
        <div class="kv_copy">
          <h1 class="kv_copy_en"><img src="@/assets/img/kv_ttl.png" alt="CREATE YOUR OWN METACLONE AVATAR"/></h1>
          <h1 class="kv_copy_jp">MetaClone アバターを作ろう</h1>
          <p class="kv_detail">
            写真たった１枚で、あなたそっくりなアバターを作成！<br class="pc">
            正面向き、無背景、明るい写真をアップロードしてください。
          </p>
        </div>
      </div>
      <div class="input_area">
        <div class="limit-wrapper radius0">
          <div v-if="!admin && (account_manage_info.item.kind == 'subscription' || !account_manage_info.item.kind)">
            <div class="limit-title">今月のダウンロード数 : {{ download_count["subscription"] }}/ {{account_manage_info.item.plan || download_count_info.limit}}</div>
            <div class="limit-title sm mt-2">ダウンロード数の更新は毎月{{ new Date(account_manage_info.item.plan_start).getDate() }}日です</div>
          </div>
          <div v-else-if="!admin && account_manage_info.item.kind == 'all_span'">
            <div class="limit-title">ダウンロード数: {{ download_count["all_span"] }}/{{ account_manage_info.item.plan }}</div>
            <div class="limit-title mt-2">利用期限: {{ dateFormat4(new Date(account_manage_info.item.plan_end),-1,-1) }}</div>
          </div>
          <div v-else>
            <div class="limit-title">ダウンロード数 : {{ download_count["admin"] }}</div>
          </div>
        </div>
        <form>
          <ul class="select_list">
            <!-- MetaPerson -->
            <template v-if="isMetaPerson">
              <li class="select_item gender active">
                <dropdown :initialLabel="attrTitles.gender" :list-items="(genders || [])" :active-item-key="gender" :action="select_gender"></dropdown>
              </li>
              <li class="select_item file hide" :class="{'active':gender!=''}">
                <dropdown :initialLabel="attrTitles.format" :list-items="(formats || [])" :active-item-key="format" :action="select_format" ></dropdown>
              </li>
              <li class="select_item file hide" :class="{'active':gender!=''}">
                <dropdown v-if="adminLod" :initialLabel="attrTitles.lodAdmin" :list-items="(lods || [])" :active-item-key="lod" :action="select_lod"></dropdown>
                <dropdown v-else :initialLabel="attrTitles.lodCustomer" :list-items="(lods || [])" :active-item-key="lod" :action="select_lod"></dropdown>
              </li>
              <li class="select_item remove-smile hide" :class="{'active':gender!=''}">
                <dropdown :initialLabel="attrTitles.removeSmile" :list-items="(removeSmiles || [])" :active-item-key="removeSmile" :action="select_removeSmile"></dropdown>
              </li>
              <li v-if="!isEmptyObject(projects) && projects.option.viewed" class="select_item option hide" :class="{'active':gender!=''}">
                <dropdown :initialLabel="attrTitles.option" :list-items="(options.items || [])" :active-item-keys="option" :action="select_option" :multiple="true"></dropdown>
              </li>
            </template>
            <!-- FitPerson -->
            <template v-else>
              <li class="select_item gender">
                <select name="gender" class="common_select" v-model="gender">
                  <option disabled value="">{{ attrTitles.gender }}</option>
                  <option v-for="item in genders" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item outfit">
                <select name="outfit" class="common_select" v-model="outfit">
                  <option disabled value="">{{ attrTitles.outfit }}</option>
                  <option v-for="item in outfits" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item height">
                <select name="height" class="common_select" v-model="height">
                  <option disabled value="">身長</option>
                  <option v-for="item in heightOptions" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item weight">
                <select name="weight" class="common_select" v-model="weight">
                  <option disabled value="">体重</option>
                  <option v-for="item in weightOptions" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item chest">
                <select name="chest" class="common_select" v-model="chest">
                  <option disabled value="">胸囲</option>
                  <option v-for="item in chestOptions" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item waist">
                <select name="waist" class="common_select" v-model="waist">
                  <option disabled value="">胴囲</option>
                  <option v-for="item in waistOptions" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item hip">
                <select name="hip" class="common_select" v-model="hips">
                  <option disabled value="">ヒップ</option>
                  <option v-for="item in hipsOptions" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item file">
                <select name="file" class="common_select" v-model="format">
                  <option disabled value="">{{ attrTitles.format }}</option>
                  <option v-for="item in formats.filter(v => !v.disabled)" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item file">
                <select name="lod" class="common_select" v-model="lod">
                  <option disabled value="">{{ attrTitles.lodAdmin }}</option>
                  <option v-for="item in lods.filter(v => !v.disabled)" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
              <li class="select_item remove-smile">
                <select name="removeSmile" class="common_select" v-model="removeSmile">
                  <option disabled value="">{{ attrTitles.removeSmile }}</option>
                  <option v-for="item in removeSmiles.filter(v => !v.disabled)" :key="item.value" :value="item.value">{{ item.text }}</option>
                </select>
              </li>
            </template>
          </ul>
          <div v-if="admin" class="uk-margin uk-margin-left uk-margin-large-bottom">
            <label><input class="uk-checkbox uk-margin-small-right" type="checkbox" v-model="viewLimit">アバター閲覧に期限を設けない</label>
          </div>
          <div class="upload js-upload uk-placeholder uk-margin-top white-dot-bg upload-area-padding" @dragover.prevent @drop.prevent="onDropFile">
            <div class="upload_txt">
              <p class="upload_ttl mt-3">顔写真を<span>ドラッグして</span>アップロード</p>
              <p class="mt-3" style="font-size:30px;">または</p>
            </div>
            <div class="upload_file mt-3" uk-form-custom>
              <div class="file_button_area">
                <input id="upload-image" type="file" @change="onSelectFile">
                <div class="uk-link file_button select-box-button-wrapper">
                  <span class="uk-text-middle select-box-button">ファイルを選択</span>
                </div>
              </div>
            </div>
            <div style="margin-top: 40px;">
              <a href="/メタクローン用顔写真の注意点.pdf" class="caution" target="_blank">※写真をアップロードする際の注意点</a>
            </div>
          </div>
          <div class="logo-wrapper">
            <div class="privacy">
              <a href="/メタクローンアバター利用規約_TOPPAN_ver2.0.pdf" target="_blank">利用規約</a>
              <a href="/メタクローンアバタープライバシーポリシー_TOPPAN_ver2.0.pdf" target="_blank">プライバシーポリシー</a>
            </div>
            <div class="logo">
              <img src="@/assets/img/001_Radius5_yoko_white.png" alt="MEta Clone" style="height: 30px;"/>
              <img src="@/assets/img/002_VIRTUALHUMANLAB.png" alt="MEta Clone" style="height: 32px;"/>
              <img src="@/assets/img/003_AVATARSDK.png" alt="MEta Clone" style="height: 35px;"/>
            </div>
            <div style="width:250px;"></div>
          </div>
          <progress id="js-progressbar" class="uk-progress" value="0" max="100" hidden></progress>
        </form>
      </div>
    </div>
    <all-download-list></all-download-list>
    <modal v-if="isShowConfirm" :whiteBackgroundColor="'rgba(255,255,255,0.75)'" :maxHeight="maxHeight+'px'" :opacity="'1'" :donetext="'はい'" :canceltext="'いいえ'" :propWidth="'600px !important'" @modal-done="preparePreviewImage(true)" @modal-close="preparePreviewImage(false)">
      <template v-slot:body>
        <div style="color:white;text-align: center;">
          <div class="attr-wrapper" v-if="isMetaPerson">
            <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.gender }} 】</span>: <span class="attr-value">{{ genderText }}</span></div>
            <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.format }} 】</span>: <span class="attr-value">{{ formatText }}</span></div>
            <div v-if="adminLod" class="vertical-center"><span class="attr-title">【 {{ attrTitles.lodAdmin }} 】</span>: <span class="attr-value">{{ lodText }}</span></div>
            <div v-else class="vertical-center"><span class="attr-title">【 {{ attrTitles.lodCustomer }} 】</span>: <span class="attr-value">{{ lodText }}</span></div>
            <div class="vertical-center"><span class="attr-title">【 {{ attrTitles.removeSmile }} 】</span>: <span class="attr-value">{{ removeSmileText }}</span></div>
          </div>
          <div>
            <img v-if="origImageURL" id="target-image" class="uk-align-center opacity-white-border" :src="origImageURL">
          </div>
          <div style="color: rgb(61,61,61);">
            <div v-if="isMetaPerson" class="large-font">上記内容でアバターを生成しますか？<br>まずは 3D 変換用に顔写真を加工します</div>
            <div v-else class="large-font">上記画像でアバターを生成しますか？</div>
            <div>※「はい」でアバターを生成しても、<br/>「今月のダウンロード数」にカウントされません</div>
          </div>
        </div>
      </template>
    </modal>
    <modal v-show="isShowAlert" :modalColor="'rgb(204,204,204)'" :color="'black'" :header="'アラート'" :canceltext="'OK'" :propWidth="'650px !important'" @modal-close="isShowAlert=false">
      <template v-slot:body>
        <div v-html="message" class="message-wrapper"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UIkit from 'uikit'
import { Auth } from 'aws-amplify'
import heic2any from 'heic2any'
import Modal from '@/components/parts/Modal.vue'
import Dropdown from '@/components/parts/Dropdown.vue'
import AllDownloadList from '@/components/parts/AllDownloadList.vue'
import {
  genderOptions, genderOptionsMap,
  outfitOptions,
  formatOptions, formatOptionsMap, customerOnlyFormats,
  lodOptions, lodOptionsMap, lodSimpleOptionsMap,
  removeSmileOptions, removeSmileOptionsMap,
  defaultSettings, attrTitleSettings, additionalOptions,
} from '../assets/js/constants'

export default {
  name: 'ImageUpload',
  components: {
    Modal,
    Dropdown,
    AllDownloadList,
  },
  data () {
    return {
      // main parameter
      genders: [],
      gender: '',
      genderText: '',
      outfits: [],
      outfit: '',
      formats: [],
      format: '',
      formatText: '',
      lods: [],
      lod: '',
      lodText: '',
      removeSmiles: [],
      removeSmile: '',
      removeSmileText: '',
      options: [],
      option: [],
      // sub parameter（FitPerson Only）
      heightMaxMin: { max: 190, min: 140 },
      heightOptions: [],
      height: '',
      weightMaxMin: { max: 100, min: 40 },
      weightOptions: [],
      weight: '',
      chestMaxMin: { max: 110, min: 70 },
      chestOptions: [],
      chest: '',
      waistMaxMin: { max: 100, min: 50 },
      waistOptions: [],
      waist: '',
      hipsMaxMin: { max: 110, min: 70 },
      hipsOptions: [],
      hips: '',
      // その他
      viewLimit: false,
      origUploadImageURL: '',
      origImageURL: '',
      type1ImageURL: '',
      type2ImageURL: '',
      type31ImageURL: '',
      type32ImageURL: '',
      type33ImageURL: '',
      type41ImageURL: '',
      type42ImageURL: '',
      type43ImageURL: '',
      type51ImageURL: '',
      type52ImageURL: '',
      type53ImageURL: '',
      type6ImageURL: '',
      type99ImageURL: '',
      filename: '',
      mimeType: '',
      resJson: {},
      user_sub: '',
      download_count: {subscription:0, admin:0, all_span:0},
      download_count_info: {status:'', limit:0, item:{}},
      account_manage_info: {status:'', item:{}},
      attrTitles: attrTitleSettings,
      projects: {},
      beautyExec: false,
      olderExec: false,
      youngerExec: false,
      sexChangeExec: false,
      frontFaceExec: false,
      admin: false,
      adminLod: false,
      isMetaPerson: true,
      menuActive: false,
      maxHeight: window.innerHeight,
      month_str: `${new Date().getFullYear()}-${new Date().getMonth()+1}`,
      isShowConfirm: false,
      isShowAlert: false,
      message: '',
    }
  },
  computed: {
    isEmptyObject() {
      return (obj) => {
        if (!obj) return true
        return Object.keys(obj).length === 0
      }
    },
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    initialize() {
      this.genders = (this.isMetaPerson) ? this.deepCopy(genderOptions.meta) : genderOptions.fit
      this.outfits = outfitOptions.mobile
      this.formats = this.deepCopy(formatOptions)
      if (this.admin) this.formats = this.formats.filter(f => !customerOnlyFormats.includes(f.value))
      this.lods = (this.adminLod) ? this.deepCopy(lodOptions.admin) : this.deepCopy(lodOptions.customer)
      this.removeSmiles = this.deepCopy(removeSmileOptions)
      this.options = this.deepCopy(additionalOptions)
      this.options.items = this.options.items.map((i) => ({...i, text: i.text.replace(/<br>/g, '')}))
      // 画面制御
      if (this.projects.gender_allow_list.length === 1) {
        this.gender = this.projects.gender_allow_list[0]
        document.querySelector('.dropdown-text.gender').innerHTML = genderOptionsMap.meta[this.gender]
      }
      for (const gender of this.genders) {
        if (this.projects.gender_allow_list.includes(gender.value)) continue
        gender.value = 'disabled'
        gender.disabled = true
        gender.src = gender.srcGrayout
      }
      if (this.projects.format_allow_list.length === 1) {
        this.format = this.projects.format_allow_list[0]
        document.querySelector('.dropdown-text.format').innerHTML = formatOptionsMap[this.format]
      }
      for (const format of this.formats) {
        if (this.projects.format_allow_list.includes(format.value)) continue
        format.value = 'disabled'
        format.disabled = true
      }
      if (this.projects.lod_allow_list.length === 1) {
        this.lod = this.projects.lod_allow_list[0]
        document.querySelector('.dropdown-text.lod').innerHTML = lodSimpleOptionsMap[(this.adminLod) ? 'admin' : 'customer'][this.lod]
      }
      for (const lod of this.lods) {
        if (this.projects.lod_allow_list.includes(lod.value)) continue
        lod.value = 'disabled'
        lod.disabled = true
      }
      if (this.projects.remove_smile_allow_list.length === 1) {
        this.removeSmile = this.projects.remove_smile_allow_list[0]
        document.querySelector('.dropdown-text.remove-smile').innerHTML = removeSmileOptionsMap[this.removeSmile]
      }
      for (const removeSmile of this.removeSmiles) {
        if (this.projects.remove_smile_allow_list.includes(removeSmile.value)) continue
        removeSmile.value = 'disabled'
        removeSmile.disabled = true
      }
      this.disableOption()
    },
    changeSubtype(toFlag) {
      this.isMetaPerson = toFlag
      this.gender = ''
      this.outfit = ''
      this.format = ''
      this.lod = ''
      this.removeSmile = ''
      this.height = ''
      this.weight = ''
      this.chest = ''
      this.waist = ''
      this.hips = ''
      this.initialize()
    },
    select_gender(gender) {
      this.gender = gender
    },
    select_format(format) {
      this.format = format
      if (this.option.viewed) this.disableOption()
    },
    select_lod(lod) {
      this.lod = lod
    },
    select_removeSmile(removeSmile) {
      this.removeSmile = removeSmile
    },
    select_option(option) {
      this.option = option
      this.disableOption()
    },
    disableOption() {
      this.options = this.deepCopy(additionalOptions)
      this.options.items = this.options.items.map((i) => ({...i, text: i.text.replace(/<br>/g, '')}))
      for (const optionItem of this.options.items) {
        let disabled = false
        if (['reduce_blendshape', 'reduce_blendshape_joy'].includes(optionItem.value) && this.option.includes('remove_blendshape')) disabled = true
        if (optionItem.value == 'reduce_blendshape' && this.option.includes('reduce_blendshape_joy')) disabled = true
        if (optionItem.value == 'reduce_blendshape_joy' && this.option.includes('reduce_blendshape')) disabled = true
        if (!this.admin && this.format && this.format !== 'fbx_vrm' && ['rename_bone', 'reduce_blendshape', 'reduce_blendshape_joy'].includes(optionItem.value)) disabled = true
        if (this.admin && this.format && this.format === 'glb' && ['rename_bone', 'reduce_blendshape', 'reduce_blendshape_joy'].includes(optionItem.value)) disabled = true
        if (optionItem.value == 'outfit_size_128' && this.option.includes('outfit_size_512')) disabled = true
        if (optionItem.value == 'outfit_size_512' && this.option.includes('outfit_size_128')) disabled = true
        if (optionItem.value == 'high_quality' && !(this.option.includes('older')) && !(this.option.includes('younger'))) disabled = true
        if (disabled) {
          const index = this.option.findIndex((v) => v === optionItem.value)
          if (index !== -1) this.option.splice(index, 1)
          optionItem.value = 'disabled'
          optionItem.disabled = true
        }
      }
      document.querySelector('.dropdown-text.option').innerHTML = (this.option.length) ? `${this.option.length} 個選択中` : `${this.attrTitles.option}`
    },
    async admin_sub(callback) {
      this.getRequest().then((req)=>{
        callback(req.user_sub)
      })
    },
    download_impl(sub, kind) {
      if (sub == undefined) return
      const info = this.download_count_info.item
      const isTarget = (info && info[sub] && info[sub][kind]) ? true : false
      if (!isTarget) return
      let item
      if (kind == 'subscription') {
        let now = new Date()
        if (now.getDate() < new Date(this.account_manage_info.item.plan_start).getDate()) {
          now.setMonth(now.getMonth() - 1)
          this.month_str = `${now.getFullYear()}-${now.getMonth()+1}`
        }
        item = info[sub][kind].filter(v => v.month_str.replace(/-0/, '-') == this.month_str)[0]
      } else {
        item = info[sub][kind]
      }
      const cnt = item ? item['download_count'] : 0
      const meta_cnt = item ? item['meta_download_count'] : 0
      this.download_count[kind] = cnt + meta_cnt
      // プラン上限を超過していたら、その旨を表示するページに遷移してログアウトする
      if (this.download_count[kind] >= this.account_manage_info.item.plan) {
        this.$router.push({ name: 'overlimit' })
      }
    },
    download_num(kind='pre') {
      if (this.admin) {
        if (kind != 'admin') return
        this.admin_sub((user_sub) => {
          this.download_impl(user_sub, 'admin')
        })
      } else {
        const sub = this.account_manage_info.item.user_sub
        this.download_impl(sub, kind)
      }
    },
    dateFormat(month_str){
      const split = month_str.split('-')
      return `${split[0]}年${split[1]}月`
    },
    dateFormat4(date, diff, diff_month){
      date.setMonth(date.getMonth() + 1 + diff_month)
      date.setDate(date.getDate() + diff)
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth()+1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}/${month}/${day}`
    },
    async signOut() {
      try {
        await Auth.signOut()
      } catch (error) {
        console.log('error signing out: ', error)
      }
    },
    onDropFile(e) {
      // event(=e)から画像データを取得する
      const image = e.dataTransfer.files[0]
      this.uploadImage(image)
    },
    onSelectFile(e) {
      // event(=e)から画像データを取得する
      const image = e.target.files[0]
      e.target.value = ''
      this.uploadImage(image)
    },
    async uploadImage(image) {
      if (this.isMetaPerson) {
        if (!this.gender) {
          this.message = `${this.attrTitles.gender}を選択してください。`
          this.isShowAlert = true
          return
        }
        if (!this.format && !this.projects.format_allow_list.includes(defaultSettings.format)) {
          this.message = `${this.attrTitles.format}を選択してください。`
          this.isShowAlert = true
          return
        }
        if (!this.lod && !this.projects.lod_allow_list.includes(defaultSettings.lod)) {
          this.message = (this.adminLod) ? `${this.attrTitles.lodAdmin}を選択してください。` : `${this.attrTitles.lodCustomer}を選択してください。`
          this.isShowAlert = true
          return
        }
      }
      // upload用の生ファイル
      this.origUploadImageURL = URL.createObjectURL(image, { type: image.type })
      this.filename = image.name
      this.mimeType = image.type
      // 2021年2月現在はブラウザでの表示に対応していないためheic2anyを挟む
      const heifMimetypes = ['image/heic', 'image/heif', 'image/heic-sequence', 'image/heif-sequence']
      if (heifMimetypes.includes(image.type.toLowerCase())) {
        // Blobで返ってくるので、file name等がとれるようにFile型にしてやる必要がある
        const conversionResult = await heic2any({ blob: image })
        image = new File([conversionResult], image.name)
        this.mimeType = 'image/png'
      }
      // 5MBを超える画像はsessionStorageの容量をオーバーしてしまうのでjpeg化で圧縮する
      // NOTE: 余裕を持たせるために1MBを超える画像を対象とする
      // const storageLimit = 1000000
      // if (image.size > storageLimit) {
      //   const beforeImageSize = image.size
      //   const mimeType = 'image/jpeg'
      //   const compressedResult = await this.compressImage(image, mimeType)
      //   const baseName = image.name.replace(/\.[^.]+$/, '')
      //   image = new File([compressedResult], `${baseName}.jpg`, { type: mimeType })
      //   console.log('compressed:', beforeImageSize, '->', image.size)
      // }
      this.origImageURL = URL.createObjectURL(image, { type: this.mimeType })
      this.setAvatarDataForConfirmModal()
      this.maxHeight = window.innerHeight
      this.isShowConfirm = true
    },
    // async compressImage(file, mimeType, quality=0.7) {
    //   return new Promise((resolve, reject) => {
    //     const img = new Image()
    //     img.src = URL.createObjectURL(file)
    //     img.onload = () => {
    //       const canvas = document.createElement('canvas')
    //       canvas.width = img.naturalWidth
    //       canvas.height = img.naturalHeight
    //       canvas.getContext('2d').drawImage(img, 0, 0)
    //       canvas.toBlob(resolve, mimeType, quality)
    //     }
    //     img.onerror = reject
    //   })
    // },
    setAvatarDataForConfirmModal() {
      if (!this.format) this.select_format(defaultSettings.format)
      if (!this.lod) this.select_lod(defaultSettings.lod)
      if (!this.removeSmile) this.select_removeSmile(defaultSettings.removeSmile)
      if (this.isMetaPerson) {
        this.genderText = genderOptionsMap.meta[this.gender]
        this.formatText = formatOptionsMap[this.format]
        this.lodText = lodSimpleOptionsMap[(this.adminLod) ? 'admin' : 'customer'][this.lod]
        this.removeSmileText = removeSmileOptionsMap[this.removeSmile]
        document.querySelector('.dropdown-text.format').innerHTML = this.formatText
        document.querySelector('.dropdown-text.lod').innerHTML = lodOptionsMap[(this.adminLod) ? 'admin' : 'customer'][this.lod]
        document.querySelector('.dropdown-text.remove-smile').innerHTML = this.removeSmileText
      } else {
        if (!this.gender) this.gender = defaultSettings.gender.fit
        if (!this.outfit) this.outfit = defaultSettings.outfit.fit
      }
    },
    async preparePreviewImage(doFlag) {
      this.isShowConfirm = false
      if (!doFlag) {
        document.getElementById('js-progressbar').setAttribute('hidden', 'hidden')
        return
      }
      const res = await this.axios.get('/api/generate_uuid')
      const uuid =  res.data.uuid
      this.editImage(uuid, 99)
      let processing = false
      let interval = setInterval(async () => {
        if (!this.type99ImageURL) return
        if (processing) return
        processing = true
        const res99 = await this.axios.get('/api/stream_image', {
          params: {
            path: this.type99ImageURL,
            mime_type: this.mimeType,
          },
          responseType: 'blob',
        })
        this.type99ImageURL = URL.createObjectURL(new Blob([res99.data], { type: res99.type }))
        this.requestPreviewImage(uuid)
        clearInterval(interval)
      }, 500)
    },
    requestPreviewImage(uuid) {
      this.$router.push({
        name: 'processing',
        params: { processingType: 'editing' },
      })
      this.editImage(uuid, 1)
      if (this.projects.option.viewed && this.option.includes('beauty')) this.editImage(uuid, 2)
      if (!this.projects.option.viewed && this.projects.option.items.includes('beauty')) this.editImage(uuid, 2)
      if (this.projects.option.viewed && this.option.includes('older')) this.editImage(uuid, 3)
      if (!this.projects.option.viewed && this.projects.option.items.includes('older')) this.editImage(uuid, 3)
      if (this.projects.option.viewed && this.option.includes('younger')) this.editImage(uuid, 4)
      if (!this.projects.option.viewed && this.projects.option.items.includes('younger')) this.editImage(uuid, 4)
      if (this.projects.option.viewed && this.option.includes('sex_change')) this.editImage(uuid, 5)
      if (!this.projects.option.viewed && this.projects.option.items.includes('sex_change')) this.editImage(uuid, 5)
      if (this.projects.option.viewed && this.option.includes('front_face')) this.editImage(uuid, 6)
      if (!this.projects.option.viewed && this.projects.option.items.includes('front_face')) this.editImage(uuid, 6)
      let processing = false
      let interval = setInterval(async () => {
        if (!this.type1ImageURL) return
        if (this.beautyExec && !this.type2ImageURL) return
        if (this.olderExec && (!this.type31ImageURL || !this.type32ImageURL || !this.type33ImageURL)) return
        if (this.youngerExec && (!this.type41ImageURL || !this.type42ImageURL || !this.type43ImageURL)) return
        if (this.sexChangeExec && (!this.type51ImageURL || !this.type52ImageURL || !this.type53ImageURL)) return
        if (this.frontFaceExec && !this.type6ImageURL) return
        if (this.type1ImageURL.startsWith('blob:')) return
        if (processing) return
        processing = true
        this.type1ImageURL = await this.loadImageFromServer(this.type1ImageURL, this.mimeType)
        if (this.beautyExec) this.type2ImageURL = await this.loadImageFromServer(this.type2ImageURL, this.mimeType)
        if (this.olderExec) {
          this.type31ImageURL = await this.loadImageFromServer(this.type31ImageURL, this.mimeType)
          this.type32ImageURL = await this.loadImageFromServer(this.type32ImageURL, this.mimeType)
          this.type33ImageURL = await this.loadImageFromServer(this.type33ImageURL, this.mimeType)
        }
        if (this.youngerExec) {
          this.type41ImageURL = await this.loadImageFromServer(this.type41ImageURL, this.mimeType)
          this.type42ImageURL = await this.loadImageFromServer(this.type42ImageURL, this.mimeType)
          this.type43ImageURL = await this.loadImageFromServer(this.type43ImageURL, this.mimeType)
        }
        if (this.sexChangeExec) {
          this.type51ImageURL = await this.loadImageFromServer(this.type51ImageURL, this.mimeType)
          this.type52ImageURL = await this.loadImageFromServer(this.type52ImageURL, this.mimeType)
          this.type53ImageURL = await this.loadImageFromServer(this.type53ImageURL, this.mimeType)
        }
        if (this.frontFaceExec) this.type6ImageURL = await this.loadImageFromServer(this.type6ImageURL, this.mimeType)
        this.setItemsInSessionStorage(uuid)
        this.$router.push({
          name: 'preview',
          params: { uuid: uuid },
        })
        clearInterval(interval)
      }, 500)
    },
    async loadImageFromServer(imageURL, mimeType) {
      if (imageURL !== 'server not running') {
        const response = await this.axios.get('/api/stream_image', {
          params: {
            path: imageURL,
            mime_type: mimeType,
          },
          responseType: 'blob',
        })
        return URL.createObjectURL(new Blob([response.data], { type: response.type }))
      }
      return imageURL
    },
    editImage(uuid, imageEditType) {
      // type1: 美肌補正
      // type2: 美顔AI
      // type3: 未来の自分
      // type4: 過去の自分
      // type5: 性別変換AI
      // type6: 正面に向かせる
      // type99: SDが使えない時間帯のダミー画像
      let requestImageEditType = imageEditType
      if (imageEditType === 2) this.beautyExec = true
      if (imageEditType === 3) {
        this.olderExec = true
        if ((this.projects.option.viewed && this.option.includes('high_quality')) || (!this.projects.option.viewed && this.projects.option.items.includes('high_quality'))) requestImageEditType = 30
      }
      if (imageEditType === 4) {
        this.youngerExec = true
        if ((this.projects.option.viewed && this.option.includes('high_quality')) || (!this.projects.option.viewed && this.projects.option.items.includes('high_quality'))) requestImageEditType = 40
      }
      if (imageEditType === 5) this.sexChangeExec = true
      if (imageEditType === 6) this.frontFaceExec = true
      const self = this
      const bar = document.getElementById('js-progressbar')
      const params = { uuid: uuid, user_sub: self.user_sub, image_edit_type: requestImageEditType }
      // バックエンドへリクエスト
      const uploadComponents = UIkit.upload('.js-upload', {
        url: self.axios.defaults.baseURL + '/api/edit_image',
        multiple: false,
        name: 'upload_image',
        params: params,
        type: 'blob',
        method: 'POST',
        error: function (err) {
          try {
            bar.setAttribute('hidden', 'hidden')
            const errMessage = new File([err.xhr.response], { type: err.xhr.response.type })
            const reader = new FileReader()
            reader.readAsText(errMessage)
            reader.onload = () => {
              const errJson = JSON.parse(reader.result)
              if (err.xhr.response !== '' && err.xhr.status >= 400 && err.xhr.status < 500) {
                self.$router.push({
                  name: 'error',
                  params: { errorType: err.xhr.status.toString(), errorSummary: errJson.message },
                })
                return
              } else if (err.xhr.response !== '' && err.xhr.status >= 500) {
                UIkit.notification({
                  message: errJson.message,
                  status: 'danger',
                  pos: 'top-center',
                  timeout: 4000,
                })
              }
              self.$router.push({ name: 'error' })
            }
          } catch {
            self.$router.push({ name: 'error' })
          }
        },
        beforeSend: function (environment) {
          const headers = environment.headers
          const info = self.$store.state.cognite.signInUserSession.getIdToken()
          const sp = info.payload.iss.split('/')
          headers['X-Session-ID'] = info.jwtToken
          headers['X-Auth-Time'] = info.payload.auth_time
          headers['X-Cognito-Client-ID'] = info.payload.aud
          headers['X-User-Pool-ID'] = sp[sp.length-1]
        },
        beforeAll: function (settings) {
          settings.params = params
        },
        loadStart: function (e) {
          if (!self.gender && self.isMetaPerson) {
            e.target.abort()
            return
          }
          bar.max = e.total
          bar.value = e.loaded
        },
        progress: function (e) {
          bar.max = e.total
          bar.value = e.loaded
        },
        loadEnd: function (e) {
          bar.max = e.total
          bar.value = e.loaded
        },
        completeAll: function (response) {
          const res = response.response
          setTimeout(function () {
            bar.setAttribute('hidden', 'hidden')
          }, 1000)
          const file = new File([res], self.filename, { type: res.type })
          const reader = new FileReader()
          reader.readAsText(file)
          reader.onload = () => {
            const result = reader.result
            if (imageEditType === 1) self.type1ImageURL = result
            if (imageEditType === 2) self.type2ImageURL = result
            if (imageEditType === 3 || imageEditType === 4 || imageEditType === 5) {
              const baseType = `type${imageEditType}1ImageURL`
              if (result === 'server not running') {
                self[`${baseType}`] = self[`${baseType.replace('1', '2')}`] = self[`${baseType.replace('1', '3')}`] = result
              } else {
                const paths = result.split(',')
                self[`${baseType}`] = paths[0]
                self[`${baseType.replace('1', '2')}`] = paths[1]
                self[`${baseType.replace('1', '3')}`] = paths[2]
              }
            }
            if (imageEditType === 6) self.type6ImageURL = result
            if (imageEditType === 99) self.type99ImageURL = result
          }
        },
      })
      setTimeout(async () => {
        const response = await fetch(this.origUploadImageURL)
        const blob = await response.blob()
        const file = new File([blob], this.filename, { type: this.mimeType })
        uploadComponents.upload([file])
      }, 2000)
    },
    // dataURLtoFile(dataurl) {
    //   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    //   while(n--) {
    //     u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new File([u8arr], this.filename, { type: mime })
    // },
    setItemsInSessionStorage(uuid) {
      sessionStorage.clear()
      const avatarParams = {
        uuid: uuid,
        user_sub: this.user_sub,
        gender: this.gender,
        outfit: this.outfit,
        export_format: this.format,
        lod: this.lod,
        remove_smile: this.removeSmile,
        option: this.projects.option.viewed ? this.option.join(',') : this.projects.option.items.join(','),
        height: this.height,
        weight: this.weight,
        chest: this.chest,
        waist: this.waist,
        hips: this.hips,
        kind: this.isMetaPerson ? 'meta' : 'fit',
        is_vrm: this.format.includes('fbx_vrm') ? '1' : '0',
        view_limit: this.viewLimit ? '1' : '0',
        orig_upload_image_url: this.origUploadImageURL,
        orig_image_url: this.origImageURL,
        type1_image_url: this.type1ImageURL,
        type2_image_url: this.type2ImageURL,
        type31_image_url: this.type31ImageURL,
        type32_image_url: this.type32ImageURL,
        type33_image_url: this.type33ImageURL,
        type41_image_url: this.type41ImageURL,
        type42_image_url: this.type42ImageURL,
        type43_image_url: this.type43ImageURL,
        type51_image_url: this.type51ImageURL,
        type52_image_url: this.type52ImageURL,
        type53_image_url: this.type53ImageURL,
        type6_image_url: this.type6ImageURL,
        type99_image_url: this.type99ImageURL,
        mime_type: this.mimeType,
        filename: this.filename,
      }
      for (const key in avatarParams) {
        try {
          sessionStorage.setItem(key, avatarParams[key])
        } catch (error) {
          if (JSON.stringify(error).includes('exceeded the quota')) console.log('failed to save:', key)
        }
      }
    },
    ...mapActions(['getRequest', 'isAdmin']),
  },
  created () {
    for (let v = this.heightMaxMin.min, max = this.heightMaxMin.max; v <= max; v += 10) {
      this.heightOptions.push({ text: v.toString() + 'cm', value: v })
    }
    for (let v = this.weightMaxMin.min, max = this.weightMaxMin.max; v <= max; v += 10) {
      this.weightOptions.push({ text: v.toString() + 'kg', value: v })
    }
    for (let v = this.chestMaxMin.min, max = this.chestMaxMin.max; v <= max; v += 10) {
      this.chestOptions.push({ text: v.toString() + 'cm', value: v })
    }
    for (let v = this.waistMaxMin.min, max = this.waistMaxMin.max; v <= max; v += 10) {
      this.waistOptions.push({ text: v.toString() + 'cm', value: v })
    }
    for (let v = this.hipsMaxMin.min, max = this.hipsMaxMin.max; v <= max; v += 10) {
      this.hipsOptions.push({ text: v.toString() + 'cm', value: v })
    }
  },
  async mounted () {
    this.admin = await this.isAdmin()
    const cognitoInfo = await this.getRequest()
    this.user_sub = cognitoInfo.user_sub
    const resAc = await this.axios.post('/api/get_user_account_info', cognitoInfo)
    this.account_manage_info = resAc.data
    if (!this.account_manage_info.item) this.account_manage_info.item = {}
    const resDl = await this.axios.post('/api/get_avatar_download_count', { kind: this.account_manage_info.item.kind, ...cognitoInfo })
    this.download_count_info = resDl.data
    this.download_num('subscription')
    this.download_num('all_span')
    this.download_num('admin')
    const resPjt = await this.axios.get(`/api/get_project_info?project_name=${cognitoInfo.role.replace('customer:', '')}`)
    this.projects = resPjt.data.item
    this.projects.lod_allow_list = this.projects.lod_allow_list.map(s => s.toString())
    this.adminLod = this.projects.lod_allow_list.some(l => !['0', '5', '7'].includes(l))
    this.initialize()
    const imageKeys = [
      'orig_image_url', 'type1_image_url', 'type2_image_url',
      'type31_image_url', 'type32_image_url', 'type33_image_url',
      'type41_image_url', 'type42_image_url', 'type43_image_url',
      'type51_image_url', 'type52_image_url', 'type53_image_url',
      'type6_image_url',
      'type99_image_url',
    ]
    if (sessionStorage['orig_image_url']) {
      imageKeys.forEach(key => {
        const url = sessionStorage[key]
        if (url.startsWith('blob:')) URL.revokeObjectURL(url)
      })
      sessionStorage.clear()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#target-image {
  max-height: 300px;
  margin-top: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
.signout-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.back-arrow {
  font-size: 20px;
  margin-left: 2px;
  margin-bottom: 5px;
  margin-right: 15px;
}
@keyframes appear {
  from {
    left: -30%;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
.select_item.hide {
  pointer-events: none;
  display: none;
  animation: appear 0.5s ease;
}
.select_item.active {
  pointer-events: all;
  background-color: #333333;
  border-radius: 15px;
  display: block;
}
.select-box-button-wrapper {
  padding-top: 0px !important;
  height: initial !important;
  width: initial !important;
  border: 2px solid rgb(189,189,189) !important;
  color: rgb(189,189,189) !important;
  padding: 5px 10px !important;
}
.select-box-button {
  font-size: 23px;
  position: relative;
  font-weight: 600;
}
.select-box-button:hover {
  opacity: 0.8;
}
.upload-area-padding {
  padding: 60px;
}
@media (max-width: 767px) {
  .upload-area-padding {
    padding: 15px;
  }
}
.caution {
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.2em;
}
</style>
