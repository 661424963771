<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv">
        <div class="uk-container">
          <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
        </div>
        <div style="text-align: center;margin-top:200px;">
            <div class="process-title">
              <div>ご利用ありがとうございました。</div>
              <div>今月の制作上限数に達しました。追加で制作をご希望の場合は担当営業までご連絡ください。</div>
            </div>
            <p style="margin-top:30px;"><button type="button" class="btn no" @click="signOut">ログイン画面へ戻る</button></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  name: 'Create User',
  data () {
    return {
    }
  },
  methods:{
      async signOut(){
        await Auth.signOut();
      }
  },
  mounted () {
  }
}

</script>
<style>
.btn{
  border-radius: 7px;
  padding: 15px 30px;
  transition: all ease 0.5s;
}
.btn.ghost{
  border: 1px solid #ccc;
  color: #ccc;
  background-color: black;
}
.btn.btn.ghost:hover{
  border: 1px solid #333;
  color: #333;
  background-color: #ccc;
}
</style>
