import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import store from '@/store/index.js'
import { Auth, Hub } from 'aws-amplify'
import awsconfig from './aws-exports'

import Login from './components/Login.vue'
import ImageUpload from './components/ImageUpload.vue'
import SignUp from './components/signup.vue'
import Invalid from './components/invalid.vue'
import Processing from './components/Processing.vue'
import Preview from './components/Preview.vue'
import Result from './components/Result.vue'
import View from './components/View.vue'
import Avatar from './components/Avatar.vue'
import Glb from './components/Glb.vue'
import Animation from './components/Animation.vue'
import Animationview from './components/Animationview.vue'
import downloadLimitOver from './components/downloadLimitOver'
import Error from './components/Error.vue'
import userList from './components/userList'
import ProjectCreate from './components/ProjectCreate'
import ProjectList from './components/ProjectList'
import ProjectEdit from './components/ProjectEdit'

Auth.configure(awsconfig)

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || '';

const listener = (data)=>{
  switch(data.payload.event){
    case 'signIn':
      router.push({name: "home"})
      break
    case 'signOut':
      router.push({name: "login"})
      break
  }
}
Hub.listen('auth', listener);

onAuthUIStateChange((authState, authData) => {
  if (authState === AuthState.SignedIn && authData) {
    router.push({ name: "home" });
  }
  if (authState === AuthState.SignedOut) {
    router.push({ name: "login" });
   }
});

function getAuthenticatedUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit("setUser", data);
        return data;
      }
    })
    .catch((e) => {
      console.error(e);
      store.commit("setUser", null);
      return null;
    });
}

export const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/home',
      name: 'home',
      component: ImageUpload,
      meta: { requireAuth: true },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
      meta: { requireAuth: true, requireAdmin: true },
    },
    {
      path: '/userlist',
      name: 'userlist',
      component: userList,
      meta: { requireAuth: true, requireAdmin: true },
    },
    {
      path: '/project/create',
      name: 'projectCreate',
      component: ProjectCreate,
      meta: { requireAuth: true, requireAdmin: true },
    },
    {
      path: '/project/list',
      name: 'projectList',
      component: ProjectList,
      meta: { requireAuth: true, requireAdmin: true },
    },
    {
      path: '/project/edit/:projectID',
      name: 'projectEdit',
      component: ProjectEdit,
      meta: { requireAuth: true, requireAdmin: true },
    },
    {
      path: '/invalid',
      name: 'invalid',
      component: Invalid,
    },
      {
      path: '/overlimit',
      name: 'overlimit',
      component: downloadLimitOver,
    },
    {
      path: '/processing',
      name: 'processing',
      component: Processing,
      props: true,
      meta: { requireAuth: true },
    },
    {
      path: '/preview/:uuid',
      name: 'preview',
      component: Preview,
      props: true,
      meta: { requireAuth: true },
    },
    {
      path: '/result/:uuid',
      name: 'result',
      component: Result,
      props: true,
      meta: { requireAuth: true },
    },
    {
      path: '/view/:uuid',
      name: 'view',
      component: View,
    },
    {
      path: '/avatar',
      name: 'avatar',
      component: Avatar,
      meta: { requireAuth: true },
    },
    {
      path: '/glb',
      name: 'glb',
      component: Glb,
      meta: { requireAuth: true },
    },
    {
      path: '/animation',
      name: 'animation',
      component: Animation,
    },
    {
      path: '/animationview/:uuid',
      name: 'animationview',
      component: Animationview,
    },
    {
      path: '/error',
      name: 'error',
      component: Error,
      props: true,
    },
  ]
})

let user
router.beforeResolve(async (to, from, next) => {
  user = await getAuthenticatedUser()
  if (user != null && to.name !== 'invalid') {
    const resAc = await axios.post('/api/get_user_account_info', {user_sub:user.username})
    const account_manage_info = resAc.data
    if (!account_manage_info.item) account_manage_info.item = {}
    const now = new Date().getTime()
    const plan_start = account_manage_info.item.plan_start
    const plan_end = account_manage_info.item.plan_end
    if (now < plan_start || plan_end <= now) {
      return next({ name: 'invalid' })
    }
  }
  if (to.name === 'login' && user) {
    return next({ name: 'home' })
  }
  if (to.matched.some((record) => record.meta.requireAuth) && !user) {
    return next({ name: 'login' })
  }
  if (to.matched.some((record) => record.meta.requireAdmin) && user) {
    const admin = await store.dispatch('isAdmin')
    if (!admin) return next({ name: 'home' })
  }
  if (to.name === 'projectEdit') {
    const resPjt = await axios.get(`/api/get_project_info?project_id=${to.params.projectID}`)
    if (!resPjt.data.item) return next({ name: 'home' })
  }
  if (to.name === 'processing' && !to.params.processingType) {
    return next({ name: 'home' })
  }
  return next()
})
