<template>
  <div class="uk-container">
    <div>
      <h2>アバターテストページ</h2>
    </div>
    <div id="pane-container"></div>
    <div id="canvas-parent">
      <div id="renderCanvas"></div>
    </div>

    <router-link to="/" class="uk-button uk-button-default">トップへ戻る</router-link>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
// import { GUI } from 'three/examples/jsm/libs/dat.gui.module'
// import {Pane} from 'tweakpane';

// const humanoidToAvatar = {
//   Chest: 'Spine2',
//   Head: 'Head',
//   Hips: 'Pelvis',
//   LeftIndexDistal: 'lindex2',
//   LeftIndexIntermediate: 'lindex1',
//   LeftIndexProximal: 'lindex0',
//   LeftLittleDistal: 'lpinky2',
//   LeftLittleIntermediate: 'lpinky1',
//   LeftLittleProximal: 'lpinky0',
//   LeftMiddleDistal: 'lmiddle2',
//   LeftMiddleIntermediate: 'lmiddle1',
//   LeftMiddleProximal: 'lmiddle0',
//   LeftRingDistal: 'lring2',
//   LeftRingIntermediate: 'lring1',
//   LeftRingProximal: 'lring0',
//   LeftThumbDistal: 'lthumb2',
//   LeftThumbIntermediate: 'lthumb1',
//   LeftThumbProximal: 'lthumb0',
//   LeftFoot: 'L_Ankle',
//   LeftHand: 'L_Wrist',
//   LeftLowerArm: 'L_Elbow',
//   LeftLowerLeg: 'L_Knee',
//   LeftShoulder: 'L_Collar',
//   LeftToes: 'L_Foot',
//   LeftUpperArm: 'L_Shoulder',
//   LeftUpperLeg: 'L_Hip',
//   Neck: 'Neck',
//   RightIndexDistal: 'rindex2',
//   RightIndexIntermediate: 'rindex1',
//   RightIndexProximal: 'rindex0',
//   RightLittleDistal: 'rpinky2',
//   RightLittleIntermediate: 'rpinky1',
//   RightLittleProximal: 'rpinky0',
//   RightMiddleDistal: 'rmiddle2',
//   RightMiddleIntermediate: 'rmiddle1',
//   RightMiddleProximal: 'rmiddle0',
//   RightRingDistal: 'rring2',
//   RightRingIntermediate: 'rring1',
//   RightRingProximal: 'rring0',
//   RightThumbDistal: 'rthumb2',
//   RightThumbIntermediate: 'rthumb1',
//   RightThumbProximal: 'rthumb0',
//   RightFoot: 'R_Ankle',
//   RightHand: 'R_Wrist',
//   RightLowerArm: 'R_Elbow',
//   RightLowerLeg: 'R_Knee',
//   RightShoulder: 'R_Collar',
//   RightToes: 'R_Foot',
//   RightUpperArm: 'R_Shoulder',
//   RightUpperLeg: 'R_Hip',
//   Spine: 'Spine1',
//   UpperChest: 'Spine3',
// }
// const avartarToHumanoid = {}
// for (const [key, value] of Object.entries(humanoidToAvatar)) {
//   avartarToHumanoid[value] = key
// }
//
// const humanoidToMixamo = {
//   Chest: 'Spine1',
//   Head: 'Head',
//   Hips: 'Hips',
//   LeftIndexDistal: 'LeftHandIndex3',
//   LeftIndexIntermediate: 'LeftHandIndex2',
//   LeftIndexProximal: 'LeftHandIndex1',
//   LeftLittleDistal: 'LeftHandPinky3',
//   LeftLittleIntermediate: 'LeftHandPinky2',
//   LeftLittleProximal: 'LeftHandPinky1',
//   LeftMiddleDistal: 'LeftHandMiddle3',
//   LeftMiddleIntermediate: 'LeftHandMiddle2',
//   LeftMiddleProximal: 'LeftHandMiddle1',
//   LeftRingDistal: 'LeftHandRing3',
//   LeftRingIntermediate: 'LeftHandRing2',
//   LeftRingProximal: 'LeftHandRing1',
//   LeftThumbDistal: 'LeftHandThumb3',
//   LeftThumbIntermediate: 'LeftHandThumb2',
//   LeftThumbProximal: 'LeftHandThumb1',
//   LeftEye: 'LeftEye',
//   LeftFoot: 'LeftFoot',
//   LeftHand: 'LeftHand',
//   LeftLowerArm: 'LeftForeArm',
//   LeftLowerLeg: 'LeftLeg',
//   LeftShoulder: 'LeftShoulder',
//   LeftToes: 'LeftToeBase',
//   LeftUpperArm: 'LeftArm',
//   LeftUpperLeg: 'LeftUpLeg',
//   Neck: 'Neck',
//   RightIndexDistal: 'RightHandIndex3',
//   RightIndexIntermediate: 'RightHandIndex2',
//   RightIndexProximal: 'RightHandIndex1',
//   RightLittleDistal: 'RightHandPinky3',
//   RightLittleIntermediate: 'RightHandPinky2',
//   RightLittleProximal: 'RightHandPinky1',
//   RightMiddleDistal: 'RightHandMiddle3',
//   RightMiddleIntermediate: 'RightHandMiddle2',
//   RightMiddleProximal: 'RightHandMiddle1',
//   RightRingDistal: 'RightHandRing3',
//   RightRingIntermediate: 'RightHandRing2',
//   RightRingProximal: 'RightHandRing1',
//   RightThumbDistal: 'RightHandThumb3',
//   RightThumbIntermediate: 'RightHandThumb2',
//   RightThumbProximal: 'RightHandThumb1',
//   RightEye: 'RightEye',
//   RightFoot: 'RightFoot',
//   RightHand: 'RightHand',
//   RightLowerArm: 'RightForeArm',
//   RightLowerLeg: 'RightLeg',
//   RightShoulder: 'RightShoulder',
//   RightToes: 'RightToeBase',
//   RightUpperArm: 'RightArm',
//   RightUpperLeg: 'RightUpLeg',
//   Spine: 'Spine',
//   UpperChest: 'Spine2',
// }
// const mixamoToHumanoid = {}
// for (const [key, value] of Object.entries(humanoidToMixamo)) {
//   mixamoToHumanoid[value] = key
// }

export default {
  name: 'Avatar',
  methods: {
    renderModel() {
      // const urlRoot = 'http://localhost:8000/avatars/b88511465e2e4a60a333cc96f771358a/avatar/'
      const urlRoot = 'http://localhost:8000/avatars/urushihara_fix/avatar/'
      const modelUrl = urlRoot + 'model.fbx'
      // const modelUrl = 'http://localhost:8000/animations/mixamo/exo_gray.fbx'
      const hairOpacity = 5.0
      const hemiLightVal = 1.4
      let mixer
      let modelReady = false
      // const animationActions = []
      let activeAction
      // let lastAction
      // const gui = new GUI()
      // const RightShoulderFolder = gui.addFolder('右肩')
      // RightShoulderFolder.open()
      // const RightElbowFolder = gui.addFolder('右肘')
      // RightElbowFolder.open()

      // 画面そのままのアスペクト比だと少し細長くなってしまうので調整
      const camera = new THREE.PerspectiveCamera( 55, 0.92 * window.innerWidth / window.innerHeight, 4, 1000 );
      camera.position.set( 0, 156, 130 );
      // camera.lookAt(new THREE.Vector3(0, 0, 0));
      // camera.position.set( 0, 1.5, 3.2 );
      // camera.position.set(0, 1.4, 1.0)

      const scene = new THREE.Scene();
      scene.background = new THREE.Color( 0xdcdcdc );
      // scene.fog = new THREE.Fog( 0xdcdcdc, 200, 1000 );

      const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, hemiLightVal);
      hemiLight.position.set( 0, 200, 0 );
      scene.add( hemiLight );

      // const dirLight = new THREE.DirectionalLight( 0xffffff );
      // dirLight.position.set( 0, 200, 100 );
      // dirLight.castShadow = true;
      // dirLight.shadow.camera.top = 180;
      // dirLight.shadow.camera.bottom = - 100;
      // dirLight.shadow.camera.left = - 120;
      // dirLight.shadow.camera.right = 120;
      // scene.add( dirLight );

      // ground
      // const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 2000, 2000 ), new THREE.MeshPhongMaterial( { color: 0x999999, depthWrite: false } ) );
      // mesh.rotation.x = - Math.PI / 2;
      // mesh.receiveShadow = true;
      // scene.add( mesh );
      //
      // const grid = new THREE.GridHelper( 100, 25, 0x000000, 0x000000 );
      // grid.material.opacity = 0.2;
      // grid.material.transparent = true;
      // scene.add( grid );

      const container = document.getElementById("renderCanvas");
      container.innerHTML = '';
      const renderer = new THREE.WebGLRenderer( { antialias: true } );
      const width = Math.floor( window.innerWidth * 0.7 );
      const height = Math.floor( window.innerHeight * 0.7 );
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(width, height);
      renderer.shadowMap.enabled = true;
      container.appendChild( renderer.domElement );

      const controls = new OrbitControls(camera, renderer.domElement)
  // 滑らかにカメラコントローラーを制御する
      controls.enableDamping = true;
      controls.dampingFactor = 0.2;
      controls.target.set(0, 140, 15)

      // model
      const fbxLoader = new FBXLoader()
      const texLoader = new THREE.TextureLoader()
      // let nameToBones = {}
      // let skeletonHelper
        // 'motion/tpose_female.fbx',
      fbxLoader.load(
        modelUrl,
        // 'http://localhost:8000/animations/motion/walk4.fbx',
        (object) => {
          // console.log(object)
          object.traverse( function ( child ) {
            // if ( child.isBone ) {
            //   // nameToBones[child.name] = child
            //   console.log( child )
            // }

            if ( child.isMesh ) {
              texLoader.load(urlRoot + child.material.name, (texture) => {
                texture.wrapS = THREE.RepeatWrapping;
                texture.wrapT = THREE.RepeatWrapping;
                texture.repeat.set(1, 1);
                child.material.map = texture;
                child.material.needsUpdate = true;
                if (child.material.name === 'generated.png') {
                  // 髪透過
                  child.material.opacity = hairOpacity;
                  child.material.transparent = true;
                } else {
                  child.material.transparent = false;
                }
              });
            
              child.castShadow = true;
              child.receiveShadow = true;
            }
          } );
          // object.position.set(0, -100, 0);
          // object.scale.set(1, 1, 1)
          object.scale.set(100, 100, 100)
          mixer = new THREE.AnimationMixer(object)
          // const animationAction = mixer.clipAction(
          //     object.animations[0]
          // )
          // animationActions.push(animationAction)
          // posesFolder.add(animations, 'default')
          // activeAction = animationActions[0]
          // modelReady = true
          // animationAction.reset()
          // animationAction.play()

          // RightShoulderFolder.add(nameToBones['R_Shoulder'].rotation, 'z', 0, 5)
          // RightElbowFolder.add(nameToBones['R_Collar'].rotation, 'z', 0, 5)
          scene.add(object)
          //ボーンを可視化
          // skeletonHelper = new THREE.SkeletonHelper( object )
          // skeletonHelper.material.linewidth = 4
          // scene.add( skeletonHelper )
          fbxLoader.load(
              // '/motion/stand.fbx',
              'http://localhost:8000/animations/motion/walk_20220317_noskin_uni.fbx',
              (objectIdle) => {
                console.log(objectIdle.animations)
                // objectIdle.traverse( function ( child ) {
                  // if ( child.isBone ) {
                  //   console.log(child)
                  // }
                // })
                console.log('loaded idle')
                // console.log(objectIdle)
                console.log(objectIdle.animations[0])
                let clip = objectIdle.animations[0]
                // let rootQuaternion
                clip.tracks = clip.tracks.map(track => {
                  console.log(track.name);
                  if (track.name === 'RootNode1.position') {
                    track.values = track.values.map(value => { return value / 5 });
                  } else if (track.name === 'RootNode1.quaternion') {
                    // rootQuaternion = [ ...track.values ]
                    // console.log(rootQuaternion)
                    track.values = track.values.map(() => { return 0.0 });
                  // } else {
                    // track.values = track.values.map((value, index) => { return value - rootQuaternion[index] });
                  }
                  return track;
                })
                console.log(clip)
                const animationAction = mixer.clipAction(clip)
                // animationActions.push(animationAction)
                // posesFolder.add(animations, 'default')
                modelReady = true
                // activeAction = animationActions[0]
                activeAction = animationAction
                activeAction.reset()
                // activeAction.fadeIn(50)
                // activeAction.fadeIn(2)
                activeAction.play()
              },
              (xhr) => {
                  console.log('idle ' + (xhr.loaded / xhr.total) * 100 + '% loaded')
              },
              (error) => {
                  console.log(error)
              }
          )
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
        },
        (error) => {
          console.log(error)
        }
      )

            //   scene.add( object );
      // } );

      window.addEventListener('resize', onWindowResize, false)
      function onWindowResize() {
          // サイズを取得
          const width = Math.floor( window.innerWidth * 0.7 );
          const height = Math.floor( window.innerHeight * 0.7 );
          // レンダラーのサイズを調整する
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(width, height);

          // カメラのアスペクト比を正す
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
          render()
      }

      //  const animations = {
      //      default: function () {
      //          setAction(animationActions[0])
      //      },
      //      // "直立": function () {
      //        // nameToBones['R_Shoulder'].quaternion.set(0.5, 0.5, 0, 0)
      //        // nameToBones['R_Elbow'].quaternion.set(0.5, 0.5, 0.5, 0.5)
      //          // setAction(animationActions[0])
      //      // },
      //      // "お辞儀": function () {
      //      //     setAction(animationActions[1])
      //      // }
      //  }

      //  const setAction = (toAction) => {
      //      if (toAction != activeAction) {
      //          lastAction = activeAction
      //          activeAction = toAction
      //          if (lastAction) {
      //            // lastAction.stop()
      //            lastAction.fadeOut(1)
      //          }
      //          activeAction.reset()
      //          activeAction.fadeIn(1)
      //          activeAction.play()
      //          // console.log(activeAction)
      //      }
      //  }

      // function updateCamera() {
      //   camera.updateProjectionMatrix();
      // }

      // const posesFolder = gui.addFolder('ポーズ')
      // posesFolder.open()
      // posesFolder.add(animations, '直立')

      // const zoomFolder = gui.addFolder('ズーム')
      // // cameraFolder.add(camera.position, 'z', 0, 10)
      // zoomFolder.add(camera, 'fov', 5, 110).onChange(updateCamera);
      // zoomFolder.open()
      // const positionFolder = gui.addFolder('位置')
      // positionFolder.add(controls.target, 'y', 0, 500)
      // positionFolder.add(controls.target, 'z', -50, 50)
      // positionFolder.open()

      const clock = new THREE.Clock()
      function animate() {
          requestAnimationFrame(animate)

          controls.update()

          if (modelReady) mixer.update(clock.getDelta())

          render()
      }

      function render() {
        renderer.render(scene, camera)
      }

      animate()
    },
  },
  mounted() {
    this.renderModel();
  },
}
</script>
<style scoped>
  #canvas-parent {
    margin:0;
    padding: 0;
  }
  #renderCanvas {
    display: flex;
    justify-content: center; /* center horizontally */
    align-items: center; /* center vertically */
    touch-action: none;
  }
  #pane-container {
    position: absolute;
    top: 75px;
    right: 8px;
    width: 256px;
  }
</style>
