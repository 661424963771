<template>
  <!-- <div id="nav" class="tab&#45;area&#45;base"> -->
  <!--   <ul class="tab&#45;menu&#45;base"> -->
  <!--     <li><router&#45;link to="/">Home</router&#45;link></li> -->
  <!--     <li><router&#45;link to="processing">Processing</router&#45;link></li> -->
  <!--     <li><router&#45;link to="result">Result</router&#45;link></li> -->
  <!--     <li><router&#45;link to="avatar">Avatar</router&#45;link></li> -->
  <!--     <li><router&#45;link to="glb">Glb</router&#45;link></li> -->
  <!--     <li><router&#45;link to="animation">Animation</router&#45;link></li> -->
  <!--     <li><router&#45;link to="error">Error</router&#45;link></li> -->
  <!--   </ul> -->
  <!-- </div> -->
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style>

</style>
