<template>
  <div class="dot bg-extend">
    <div class="bg-shade bg-extend">
      <div class="kv">
        <div class="uk-container">
          <div class="logo"><img src="@/assets/img/logo.svg" alt="MEta Clone"/></div>
        </div>
        <div style="text-align: center;margin-top:200px;">
            <p class="process-title">このユーザーは利用期間外なので本アプリを利用できません。</p>
            <p style="margin-top:15px;">5秒後に自動でログイン画面に戻ります。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  name: 'Create User',
  data () {
    return {
    }
  },
  methods:{},
  mounted () {
    setTimeout(async ()=>{
        await Auth.signOut();
    }, 5000)
  }
}

</script>
<style>
</style>
