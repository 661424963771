<template>
    <modal v-if="isShowDownloadCount" :maxHeight="maxHeight + 'px'" :canceltext="'閉じる'" :propWidth="'95% !important'" @modal-close="close">
        <template v-slot:body>
          <div v-if="false" style="text-align:center;font-size:1.3em;font-weight:bold;">{{dateFormat(month_str)}}</div>
          <div v-if="false" class="btn-area-wrapper">
            <button type="button" class="btn ghost" style="margin-right:15px;margin-bottom: 7px;" @click="showDownloadCountAll('previous')">前の月</button>
            <button type="button" class="btn ghost" style="margin-right:15px;margin-bottom: 7px;" @click="showDownloadCountAll('current')">今月</button>
            <button type="button" class="btn ghost" style="margin-right:15px;margin-bottom: 7px;" @click="showDownloadCountAll('next')">次の月</button>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>メールアドレス</th>
                <th>契約形態</th>
                <th>ダウンロード回数</th>
                <th>上限数</th>
                <th>残りのダウンロード回数</th>
                <th>期間</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(infos, sub) in items">
                <template v-for="(info, kind) in infos">
                  <tr>
                    <td>{{info.email}}</td>
                    <td>
                      <template v-if="info.kind == 'subscription'">サブスク</template>
                      <template v-else-if="info.kind == 'all_span'">個別契約</template>
                      <template v-else-if="info.kind == 'admin'"> 管理者 </template>
                    </td>
                    <td>{{(info.download_count || 0) + (info.meta_download_count || 0)}}</td>
                    <td v-if="info.role == 'admin'">上限なし</td>
                    <td v-else>{{info.limit}}</td>
                    <td v-if="info.role == 'admin'">上限なし</td>
                    <td v-else>{{info.limit - ((info.download_count || 0) + (info.meta_download_count || 0)) >= 0 ? info.limit - ((info.download_count || 0) + (info.meta_download_count || 0)) : 0}}</td>

                    <td v-if="info.role == 'admin'"> - </td>
                    <td v-else-if="kind == 'all_span'">
                      {{ dateFormat4(new Date(info.plan_start), 0, 0) }}
                      〜
                      {{ dateFormat4(new Date(info.plan_end), 0, 0) }}
                    </td>
                    <td v-else>
                      {{ dateFormat4(new Date(info.start), 0, 0) }}
                        〜
                      {{ dateFormat4(new Date(info.end), 0, 0) }}
                    </td>
                    <td>
                        {{ dateFormat4(new Date(info.plan_start), 0, 0) }}
                        〜
                      {{ dateFormat4(new Date(info.plan_end), 0, 0) }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </template>
    </modal>
</template>
<script>
import Modal from '@/components/parts/Modal.vue'
export default {
    components:{
        Modal
    },
    data () {
        return {
            month_str: "",
            isShowDownloadCount: false,
            items: {},
            maxHeight: 0,
        }
    },
    methods:{
        sorted(info){
            const tmp = []
            for(const kind in info){
              const value = info[kind]
              value.tmp_kind = kind
              tmp.push(value)
            }
            const ret = tmp.sort((A,B)=>{
                const a = Object.keys(A)[0]
                const b = Object.keys(B)[0]
                if(a < b) return 1
                if(a > b) return -1
                return 0
            }).reduce((pre, cur)=>{
                const tmp_kind = cur.tmp_kind
                delete cur.tmp_kind
                pre[tmp_kind] = cur
                return pre
            }, {})
            return ret
        },
        dateFormat(month_str){
            const split = month_str.split("-")
            return `${split[0]}年${split[1]}月`
        },
        dateFormat2(info, diff, diff_month, kind){
            const date = new Date(info.plan_start)
            const now = new Date()
            if(info.month_str){
                const curMonth = new Date(info.month_str + "-01")
                date.setMonth(curMonth.getMonth())
            }else{
                if(kind == "pre"){
                    date.setMonth(now.getMonth())
                }else if(kind == "post"){
                    date.setMonth(now.getMonth() + 1)
                }
            }
            date.setDate(date.getDate() + diff)
            const year = now.getFullYear()
            const month = ('0' + (date.getMonth()+1)).slice(-2)
            const day = ('0' + date.getDate()).slice(-2)
            return `${year}/${month}/${day}`
        },
        dateFormat3(info, diff, diff_month, kind){
            const date = new Date(info.plan_start)
            date.setMonth(date.getMonth() + 1 + diff_month)
            const now = new Date()
            if(info.month_str){
                const curMonth = new Date(info.month_str + "-01")
                date.setMonth(curMonth.getMonth()+1)
            }else{
                if(kind == "pre"){
                    date.setMonth(now.getMonth()+1)
                }else if(kind == "post"){
                    date.setMonth(now.getMonth() + 2)
                }
            }
            date.setDate(date.getDate() + diff)
            const year = now.getFullYear()
            const month = ('0' + (date.getMonth()+1)).slice(-2)
            const day = ('0' + date.getDate()).slice(-2)
            return `${year}/${month}/${day}`
        },
        dateFormat4(date, diff, diff_month){
            date.setMonth(date.getMonth() + diff_month)
            date.setDate(date.getDate() + diff)
            const year = date.getFullYear()
            const month = ('0' + (date.getMonth()+1)).slice(-2)
            const day = ('0' + date.getDate()).slice(-2)
            return `${year}/${month}/${day}`
        },
        async getAllUserInfo(){
            try{
                const axios = this.axios
                const date = new Date()
                var yyyy = date.getFullYear();
                var mm = ("0"+(date.getMonth()+1)).slice(-2);
                this.month_str = `${yyyy}-${mm}`
                const response = Object(await axios.get('/api/get_all_avatar_download_count?month_str='+this.month_str))
                if(response.data && response.data.items){
                    this.delete_trash_data(response)
                    this.items = this.sorted(this.items)
                    console.log(this.items)
                    this.maxHeight = window.innerHeight
                    this.isShowDownloadCount = true
                }
            }catch(error){
                console.error(error)
            }
        },
        async showDownloadCountAll(period="current"){
            let date = new Date(this.month_str)
            if(period == "previous"){
                date.setMonth(date.getMonth()-1)
            }else if(period == "next"){
                date.setMonth(date.getMonth()+1)
                if(date.getTime() > new Date().getTime()){
                    return
                }
            }else if(period == "current"){
                date = new Date()
            }
            var yyyy = date.getFullYear();
            var mm = ("0"+(date.getMonth()+1)).slice(-2);
            this.month_str = `${yyyy}-${mm}`
            const response = await this.axios.get('/api/get_all_avatar_download_count?month_str='+this.month_str)
            this.delete_trash_data(response)
        },
        delete_trash_data(response){
            //ゴミデータを削除
            this.items = Object.keys(response.data.items).filter(sub=>{
                return response.data.items[sub]
            }).reduce((pre, sub)=>{
                pre[sub] = response.data.items[sub]
                return pre
            }, {})
        },
        open(){
            this.getAllUserInfo()
        },
        close(){
            this.isShowDownloadCount = false
        }
    },
    mounted () {
    }
}
</script>
<style>

</style>
