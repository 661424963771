<template>
  <div class="white-dot-bg" style="margin:30px auto;padding-bottom:30px;">
    <div class="input-wrapper">
      <div class="input-title">プロジェクト名</div>
      <input type="text" class="form-control" placeholder="e.g. metaclone-project" v-model="projectName" :readonly="projectID"/>
    </div>
    <div class="input-wrapper">
      <div class="input-title">スタイル</div>
      <p>本プロジェクトで利用するスタイルを選択してください。複数選択可能です。</p>
      <div class="input-item uk-flex uk-flex-bottom">
        <template v-for="(gender) in genderOptions.meta">
          <div v-if="gender.value !== 'disabled'" class="input-item-child img">
            <p>{{ gender.text }}</p>
            <img :src="gender.src">
            <input class="uk-checkbox" type="checkbox" :value=gender.value v-model="genders" @change="checkAtLeastOne(gender.value, genders)"/>
          </div>
        </template>
      </div>
    </div>
    <div class="input-wrapper">
      <div class="input-title">ファイル形式</div>
      <p>本プロジェクトで利用するファイル形式を選択してください。複数選択可能です。</p>
      <div class="input-item uk-flex uk-flex-wrap uk-flex-wrap-around">
        <template v-for="(format) in formatOptions">
          <div v-if="format.value !== 'disabled'" class="input-item-child">
            <div class="rectangle" v-html="format.text"></div>
            <input class="uk-checkbox" type="checkbox" :value=format.value v-model="formats" @change="checkAtLeastOne(format.value, formats)"/>
          </div>
        </template>
      </div>
    </div>
    <div class="input-wrapper">
      <div class="input-title">LoD（ポリゴンの詳細度）</div>
      <p>本プロジェクトで利用する LoD を選択してください。複数選択可能です。<br>
         LoD0 がポリゴン数が最も多く、LoD7 がポリゴン数が少なく軽量になります。LoD0 - LoD5 では口内を生成しますが、LoD6、7 では口内は削除されます。<br>
         Cluster や VRChat、V-air、Door 等のメタバースで利用する場合は口内がありつつ軽量な LoD5 を推奨しています。
      </p>
      <div class="input-item uk-flex uk-flex-wrap uk-flex-wrap-around">
        <template v-for="(lod) in lodOptions">
          <div class="input-item-child">
            <div class="rectangle">{{ lod.text }}</div>
            <input class="uk-checkbox" type="checkbox" :value=lod.value v-model="lods" @change="checkAtLeastOne(lod.value, lods)"/>
          </div>
        </template>
      </div>
    </div>
    <div class="input-wrapper">
      <div class="input-title">表情</div>
      <p>本プロジェクトで利用する表情を選択してください。複数選択可能です。<br>
         Cluster や VRChat では「写真の表情を反映」にすると顔が崩れることがあるため「表情なし」を推奨しています。V-air や Door では両方を選択しても問題ありません。
      </p>
      <div class="input-item uk-flex uk-flex-wrap uk-flex-wrap-around">
        <template v-for="(removeSmile) in removeSmileOptions">
          <div v-if="removeSmile.value !== 'disabled'" class="input-item-child">
            <div class="rectangle" v-html="removeSmile.text"></div>
            <input class="uk-checkbox" type="checkbox" :value=removeSmile.value v-model="removeSmiles" @change="checkAtLeastOne(removeSmile.value, removeSmiles)"/>
          </div>
        </template>
      </div>
    </div>
    <div class="input-wrapper">
      <div class="input-title">服装の種類の選択</div>
      <p>本プロジェクトで利用する服装の種類を選択してください。複数選択可能です。</p>
      <template v-for="(gender) in outfitTypeOptions.sort.split(':')">
        <div class="input-item-parent">
          <p class="name">{{ outfitTypeOptions.detail[gender].name }}</p>
          <div class="input-item uk-flex uk-flex-bottom">
            <template v-for="(outfit) in outfitTypeOptions.detail[gender].items">
              <div class="input-item-child img type">
                <p>{{ outfit.text }}</p>
                <img :src="outfit.src">
                <input class="uk-checkbox" type="checkbox" :value=outfit.value v-model="outfits[gender]" :disabled="outfitTypeDisabled(gender)" @change="checkAtLeastOne(outfit.value, outfits[gender])"/>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="input-wrapper">
      <div class="input-title">オプションの表示有無</div>
      <p>オプション項目をアバター生成画面（トップページ）に表示させるかどうかを選択してください。<br>
         Door や V-air 用の特殊設定をアバター生成ごとに設定できるようになります。
      </p>
      <div class="input-item uk-flex uk-flex-wrap uk-flex-wrap-around">
        <template v-for="(optionViewed) in additionalOptions.viewed">
          <div class="input-item-child">
            <div class="rectangle" v-html="optionViewed.text"></div>
            <input class="uk-radio" type="radio" :value=optionViewed.value v-model="option.viewed"/>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!option.viewed" class="input-wrapper">
      <div class="input-title">オプション項目</div>
      <p>アバターをアップロードするプラットフォームに対応した設定を選択してください。<br>
         プラットフォームごとの設定は <a class="to-spreadsheet" href="/プラットフォームごとのプロジェクトの設定方法.pdf" target="_blank">コチラ</a> からご確認ください。
      </p>
      <div class="input-item uk-flex uk-flex-wrap uk-flex-wrap-around">
        <template v-for="(optionItem) in additionalOptions.items">
          <div v-if="optionItem.value !== 'disabled'" class="input-item-child">
            <div class="rectangle" v-html="optionItem.text"></div>
            <input class="uk-checkbox" type="checkbox" :value=optionItem.value v-model="option.items" :disabled="optionDisabled(optionItem.value)"/>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div style="margin-bottom:30px;">
    <div v-if="projectID" class="btn-wrapper">
      <router-link to="/project/list" class="btn no" style="width:215px;text-align:center;">一覧へ戻る</router-link>
      <button v-if="!['admin', 'customer'].includes(projectName)" class="btn yes" style="margin-left: 15px;width: 215px;text-align: center;" @click="isShowConfirm = true"><img src="@/assets/img/icon_create.svg">削除する</button>
      <button class="btn yes" style="margin-left:15px;width:215px;text-align:center;" @click="editProject"><img src="@/assets/img/icon_create.svg">修正する</button>
    </div>
    <div v-else class="btn-wrapper">
      <router-link to="/" class="btn no" style="width:215px;text-align:center;">トップへ戻る</router-link>
      <button class="btn yes" style="margin-left:15px;width:215px;text-align:center;" @click="createProject"><img src="@/assets/img/icon_create.svg">作成する</button>
    </div>
  </div>
  <modal v-if="isShowConfirm" :whiteBackgroundColor="'rgba(255,255,255,0.75)'" :maxHeight="maxHeight+'px'" :opacity="'1'" :donetext="'はい'" :canceltext="'いいえ'" :propWidth="'600px !important'" @modal-done="deleteProject" @modal-close="isShowConfirm = false">
    <template v-slot:body>
      <div style="color:white;text-align:center;">
        <div style="color:rgb(61,61,61);">
          <div class="large-font">本当に削除しますか？</div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/parts/Modal.vue'
import {
  genderOptions, outfitTypeOptions, formatOptions, lodSimpleOptions, removeSmileOptions,
  projectSettings, additionalOptions,
} from '@/assets/js/constants'

export default {
  components: {
    Modal,
  },
  props:{
    projectID: String,
  },
  emits: ['create-project', 'edit-project', 'delete-project'],
  data() {
    return {
      projectName: '',
      genders: projectSettings.genders,
      outfits: this.deepCopy(projectSettings.outfits),
      formats: projectSettings.formats,
      lods: projectSettings.lods,
      removeSmiles: projectSettings.removeSmiles,
      option: this.deepCopy(projectSettings.option),
      genderOptions: genderOptions,
      outfitTypeOptions: outfitTypeOptions,
      formatOptions: formatOptions,
      lodOptions: lodSimpleOptions,
      removeSmileOptions: removeSmileOptions,
      additionalOptions: additionalOptions,
      isShowConfirm: false,
      maxHeight: window.innerHeight,
    }
  },
  computed: {
    outfitTypeDisabled() {
      return (gender) => {
        if (!this.genders.includes(gender)) return true
        return false
      }
    },
    optionDisabled() {
      return (option) => {
        if (['reduce_blendshape', 'reduce_blendshape_joy'].includes(option) && this.option.items.includes('remove_blendshape')) return true
        if (option == 'reduce_blendshape' && this.option.items.includes('reduce_blendshape_joy')) return true
        if (option == 'reduce_blendshape_joy' && this.option.items.includes('reduce_blendshape')) return true
        if (!this.formats.includes('fbx_vrm') && ['rename_bone', 'reduce_blendshape', 'reduce_blendshape_joy'].includes(option)) return true
        if (option == 'outfit_size_128' && this.option.items.includes('outfit_size_512')) return true
        if (option == 'outfit_size_512' && this.option.items.includes('outfit_size_128')) return true
        if (option == 'high_quality' && !(this.option.items.includes('older')) && !(this.option.items.includes('younger'))) return true
        return false
      }
    },
  },
  methods: {
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    createProject() {
      const params = {
        project_name: this.projectName,
        genders: this.genders,
        outfits: this.outfits,
        formats: this.formats,
        lods: this.lods,
        remove_smiles: this.removeSmiles,
        option: this.option,
      }
      this.$emit('create-project', params)
    },
    editProject() {
      const params = {
        project_name: this.projectName,
        genders: this.genders,
        outfits: this.outfits,
        formats: this.formats,
        lods: this.lods,
        remove_smiles: this.removeSmiles,
        option: this.option,
      }
      this.$emit('edit-project', params)
    },
    deleteProject() {
      const params = {
        project_name: this.projectName,
      }
      this.$emit('delete-project', params)
      this.isShowConfirm = false
    },
    checkAtLeastOne(target, targetList) {
      if (!targetList.length) targetList[0] = target
    },
  },
  async mounted () {
    if (!this.projectID) return
    const resPjt = await this.axios.get(`/api/get_project_info?project_id=${this.projectID}`)
    const projects = resPjt.data.item
    this.projectName = projects.name
    this.genders = projects.gender_allow_list
    this.outfits = projects.outfit_allow_list
    this.formats = projects.format_allow_list
    this.lods = projects.lod_allow_list.map(s => s.toString())
    this.removeSmiles = projects.remove_smile_allow_list
    this.option = projects.option
    if (this.option.viewed) this.option.items = []
  },
  watch: {
    genders(genderList) {
      for (const gender of genderOptions.meta) {
        if (gender.value === 'disabled') continue
        if (!this.outfits[gender.value].length) this.outfits[gender.value] = this.deepCopy(projectSettings.outfits[gender.value])
        if (!genderList.includes(gender.value)) this.outfits[gender.value] = []
      }
    },
    option: {
      handler(option) {
        if (option.items.includes('remove_blendshape')) {
          for (const optionItem of ['reduce_blendshape', 'reduce_blendshape_joy']) {
            const index = option.items.findIndex((v) => v === optionItem)
            if (index !== -1) option.items.splice(index, 1)
          }
        }
        if (option.viewed) {
          for (const optionItem of additionalOptions.items) {
            if (optionItem.value === 'disabled') continue
            const index = option.items.findIndex((v) => v === optionItem.value)
            option.items.splice(index, 1)
          }
        }
        if (option.items.includes('high_quality')) {
          if (!option.items.includes('older') && !option.items.includes('younger')) {
            const index = option.items.findIndex((v) => v === 'high_quality')
            if (index !== -1) option.items.splice(index, 1)
          }
        }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
input[readonly] {
  cursor: default;
}
.input-wrapper {
  position: relative;
  padding: 20px;
}
.input-title {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 5px;
}
.input-item {
  padding: 10px;
}
.input-item-parent {
  border: 1px solid white;
  border-radius: 5px;
  margin: 10px;
}
.input-item-parent .name {
  margin: 10px 0 0 10px;
}
.input-item .input-item-child { 
  margin: 0 20px;
  text-align: center;
}
.input-item .input-item-child.img { 
  margin: 0 5px;
}
.input-item .input-item-child.img.type { 
  max-width: 120px;
  margin: 0 20px;
}
.input-item img {
  margin: 5px 0 10px;
}
.input-item .rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 5px 0 10px;
}
.form-control {
  background-color: #333333;
  border-radius: 15px;
  min-height: 65px;
  color: #fff;
  border: none;
  font-weight: bold;
  padding: 0px 40px 0px 20px;
  width: 60%;
  font-size: 22px;
  box-sizing: border-box;
}
.uk-checkbox, .uk-radio {
  inline-size: 1.5em;
  block-size: 1.5em;
  border-color: white;
}
.uk-checkbox:checked, .uk-radio:checked {
  background-color: #333333;
}
.btn-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
a.to-spreadsheet {
  text-decoration: underline;
}
@media (max-width: 767px){
  .input-wrapper {
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
}
</style>
